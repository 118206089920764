import {
    StatusInfoInner,
    StyledModalWrapper,
    Text,
    Title,
    TextWrapper,
    TitleWrapper
} from 'components/modals/StatusInfoModal/styles';
import { Button } from 'components/ui/Button';
import { MarginWrapper } from 'components/wrappers/MarginWrapper/styles';
import { useStore } from 'effector-react';
import React from 'react';
import { modalEvents, modalStores } from 'stores/collaborationModals';
import { Row } from 'components/wrappers/flexWrapper';

const { statusModal } = modalStores;
const { closeStatusModal } = modalEvents;

export const StatusInfoModal = () => {
    const { visible, title, text, icon: Icon, buttonText, onClick, onCloseClick, cancelText } = useStore(statusModal);

    const handleCloseClick = () => {
        closeStatusModal();
        onCloseClick?.();
    };

    const handleButtonClick = () => {
        closeStatusModal();
        onClick?.();
    };

    return (
        <StyledModalWrapper visible={visible} onClose={handleCloseClick}>
            <StatusInfoInner>
                {Icon && <MarginWrapper marginBottom="8px">{<Icon />}</MarginWrapper>}

                {title && (
                    <TitleWrapper>
                        <Title>{title}</Title>
                    </TitleWrapper>
                )}

                {text && (
                    <TextWrapper>
                        <Text>{text}</Text>
                    </TextWrapper>
                )}
                <Row>
                    <Button buttonType="blue" onClick={handleCloseClick}>
                        {cancelText}
                    </Button>
                    {buttonText && (
                        <MarginWrapper marginLeft="24px">
                            <Button buttonType="red" onClick={handleButtonClick}>
                                {buttonText}
                            </Button>
                        </MarginWrapper>
                    )}
                </Row>
            </StatusInfoInner>
        </StyledModalWrapper>
    );
};
