import defaultAvatar from 'assets/img/default-avatar.png';
import { flexStart } from 'constants/styles';
import styled from 'styled-components';
import { BorderRadius, ImgProperties } from 'types';

interface Props extends BorderRadius, ImgProperties {}

export const AvatarWrapper = styled.div<Props>`
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: ${({ borderRadius }) => borderRadius || '50%'};
    background-image: ${({ src }) => (src ? `url("${src}")` : `url("${defaultAvatar}")`)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
`;

export const VerifiedStatus = styled.div`
    position: absolute;
    top: 0;
    right: -4px;
    ${flexStart};
`;
