import { Button } from 'components/ui/Button';
import { SearchSelectInput } from 'components/form/SearchSelectInput';
import { Span } from 'components/typography/Span';
import { Section } from 'components/wrappers/flexWrapper';
import { defaultUsersValues, filterValue, rolesFilterList } from 'constants/defaults/users';
import { infoTitle } from 'constants/messages';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import { SearchSelectWrapper } from 'pages/Organization/OrganizationCollaborations/styles';
import React, { FC, ReactNode, useState } from 'react';
import { Select } from 'components/ui/Select';
import { usersEvents, usersStores } from 'stores/users';
import { TotalRecords } from 'types';
import { ChildrenWrapper, FilterTitle, ResetButtonWrapper, SelectWrapper } from './styles';

const { setParams } = usersEvents;
const { params } = usersStores;

interface Props extends TotalRecords {
    children: ReactNode;
}

export const UsersFilterLayout: FC<Props> = ({ children, totalRecords }) => {
    const { rolesAny } = useStore(params);
    const [searchText, setSearchText] = useState('');

    const onResetFilters = () => {
        setParams(defaultUsersValues);
        setSearchText('');
    };

    const onUsernameSearch = (name: string) => {
        if (!name) {
            setParams(defaultUsersValues);
        } else {
            setParams({
                ...defaultUsersValues,
                userName: name
            });
        }
    };

    const onEmailSearch = (email: string) => {
        if (!email) {
            setParams(defaultUsersValues);
        } else {
            setParams({
                ...defaultUsersValues,
                email
            });
        }
    };

    const onIdSearch = (id: string) => {
        if (!id) {
            setParams(defaultUsersValues);
        } else {
            setParams({
                ...defaultUsersValues,
                userIds: [id]
            });
        }
    };

    const selectFilter = (filter: any) => {
        if (filter.value === 'All') {
            setParams(defaultUsersValues);
        } else {
            setParams({
                ...defaultUsersValues,
                rolesAny: [filter.value]
            });
        }

        setSearchText('');
    };

    const searchItems = [
        {
            option: {
                value: 'userId',
                label: 'User ID'
            },
            onSearch: onIdSearch
        },
        {
            option: {
                value: 'userName / remoteId',
                label: 'Username / Remote ID'
            },
            onSearch: onUsernameSearch
        },
        {
            option: {
                value: 'email',
                label: 'Email'
            },
            onSearch: onEmailSearch
        }
    ];

    return (
        <>
            <SearchSelectWrapper>
                <SelectWrapper>
                    <FilterTitle>Filter by role</FilterTitle>
                    <Select
                        name="sorts"
                        options={rolesFilterList}
                        value={filterValue(rolesAny && rolesAny[0])}
                        onChange={selectFilter}
                    />
                </SelectWrapper>
                <SearchSelectInput
                    defaultSearchText={searchText}
                    items={searchItems}
                    onSearch={(searchText: string) => {
                        setSearchText(searchText);
                    }}
                />

                <ResetButtonWrapper>
                    <Button onClick={onResetFilters}>Reset</Button>
                </ResetButtonWrapper>
            </SearchSelectWrapper>
            <Section marginTop="30px">
                <Span color={grey} fontSize="13px">
                    {infoTitle} {totalRecords}
                </Span>
            </Section>
            <ChildrenWrapper>{children}</ChildrenWrapper>
        </>
    );
};
