import { createEffect, createStore } from 'effector';
import { API } from 'services';

// Effects

const getUserExternalNetworks = createEffect({
    handler: async (userId: string) => {
        try {
            const { isSuccess, data } = await API.userExternalNetwork.getUserExternalNetworks(userId);

            if (isSuccess) {
                return data?.items || [];
            }

            return [];
        } catch (error) {
            return [];
        }
    }
});

const $userExternalNetworks = createStore<BUCM.UserExternalNetworkResponse[] | null>(null).on(
    getUserExternalNetworks.doneData,
    (_, newState) => newState
);

$userExternalNetworks.watch(state => console.log(state));

export const userExternalNetworksEffects = {
    getUserExternalNetworks
};

export const userExternalNetworksStores = {
    $userExternalNetworks
};
