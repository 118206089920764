import { ExternalNetworkKey } from 'constants/defaults/collaborations';

//interface RewardsResultItem extends Pick<BUCM.ExternalNetworkResponse, 'title'>, BUCM.TiltedPublishingNetworkRequiremen {}

/*
 Gets the concatenated array of networks
 @param externalNetworks
 @param collaborationNetworks
 @returns
 */
// export const getRewardsForSingleCollaboration = (
//     externalNetworks: BUCM.ExternalNetworkResponse[],
//     collaborationNetworks?: BUCM.TiltedPublishingNetworkRequirement[] | null
// ) => {
//     if (!collaborationNetworks || collaborationNetworks.length === 0 || externalNetworks.length === 0) {
//         return [];
//     }

//     const result: RewardsResultItem[] = [];

//     externalNetworks.forEach(externalNetwork => {
//         const network = collaborationNetworks.find(el => el.networkId === externalNetwork.id);

//         result.push({
//             ...network,
//             title: (externalNetwork?.title || '').toLowerCase()
//         });
//     });

//     return result;
// };

/**
 * Gets external network id by name
 * @param externalNetworks
 * @param name
 * @returns
 */
export const getNetworkIdByName = (externalNetworks: BUCM.ExternalNetworkResponse[], name: ExternalNetworkKey) => {
    if (externalNetworks.length === 0) return '';

    const network = externalNetworks.find(item => item.title?.toLowerCase() === name);
    if (!network) return '';

    return network?.id || '';
};
