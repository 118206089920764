import { ParticipationEndedStatuses } from 'components/common/Statuses/ParticipationEndedStatuses';
import { PublishingStatuses } from 'components/common/Statuses/PublishingStatuses';
import { SubmissionEndedStatuses } from 'components/common/Statuses/SubmissionEndedStatuses';
import { SubmissionStatuses } from 'components/common/Statuses/SubmissionStatuses';
import React from 'react';
import { ParticipationEndedReason, ParticipationState } from './enrollments';

export enum MEDIA_ASSET_STATE {
    None = 0,
    Pending = 100,
    Valid = 200,
    Invalid = 500
}

export const PARTICIPATION_STATES = (state: BUCM.ParticipationState): string =>
    ({
        [ParticipationState.None]: '-', // 'None',
        [ParticipationState.EnrollmentRequested]: 'Enrollment Requested',
        [ParticipationState.Enrolled]: 'Enrolled',
        [ParticipationState.Submitted]: 'Submitted video',
        [ParticipationState.Published]: 'Shared to social media',
        [ParticipationState.Ended]: 'Ended'
    }[state]);

export const PARTICIPATION_ENDED_REASONS = (state: BUCM.ParticipationEndedReason): string =>
    ({
        [ParticipationEndedReason.None]: '-',
        [ParticipationEndedReason.SubmittedAndPublished]: 'Submitted And Published',
        [ParticipationEndedReason.SubmittedOnly]: 'Submitted Only',
        [ParticipationEndedReason.SubmissionFailed]: 'Submission Failed',
        [ParticipationEndedReason.DeadlineMissed]: 'Deadline Missed',
        [ParticipationEndedReason.SubmissionReported]: 'Submission Reported',
        [ParticipationEndedReason.Leave]: 'Left',
        [ParticipationEndedReason.KickedOut]: 'Removed',
        [ParticipationEndedReason.ParticipationRequestRejected]: 'Request Rejected'
    }[state]);

export const SUBMISSION_STATES = (state: BUCM.SubmissionState): JSX.Element =>
    ({
        0: <SubmissionStatuses status={0} />, // 'None',
        10: <SubmissionStatuses status={10} />, // 'Pending',
        20: <SubmissionStatuses status={20} />, // 'Curating',
        1000: <SubmissionStatuses status={1000} /> //'Ended'
    }[state]);

export const SUBMISSION_ENDDED_REASONS = (state: BUCM.SubmissionEndedReason): JSX.Element =>
    ({
        0: <SubmissionEndedStatuses status={0} />, // 'None',
        200: <SubmissionEndedStatuses status={200} />, // 'Success',
        500: <SubmissionEndedStatuses status={500} />, // 'Curation Failed',
        501: <SubmissionEndedStatuses status={501} />, // 'Reported',
        503: <SubmissionEndedStatuses status={503} /> // 'Deadline Missed',
    }[state]);

export const PUBLISHING_STATES = (state: BUCM.PublishingState): JSX.Element =>
    ({
        0: <PublishingStatuses status={0} />, // 'None',
        10: <PublishingStatuses status={10} />, // 'Awaiting submission',
        20: <PublishingStatuses status={20} />, // 'Pending',
        30: <PublishingStatuses status={30} />, // 'Verifying',
        1000: <PublishingStatuses status={1000} /> // 'Ended'
    }[state]);

export const PUBLISHING_ENDDED_REASONS = (
    state: BUCM.PublishingEndedReason,
    publishingNetwork?: BUCM.ExternalNetworkResponse
): JSX.Element =>
    ({
        0: <ParticipationEndedStatuses status={0} />, // 'None',
        200: <ParticipationEndedStatuses publishingNetwork={publishingNetwork} status={200} />, // 'Published',
        500: <ParticipationEndedStatuses status={500} />, // 'Not Published',
        501: <ParticipationEndedStatuses status={501} />, // 'Collaboration Stopped',
        503: <ParticipationEndedStatuses status={503} />, // 'Published content not Verified',
        504: <ParticipationEndedStatuses status={504} /> // 'Published content not Verified',
    }[state]);
