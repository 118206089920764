import { blue, white } from 'constants/styles';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledTag = styled.span`
    border-radius: 12px;
    background-color: ${blue};
    color: ${white};
    padding: 3px 10px;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;

    &:not(:last-child) {
        margin-right: 12px;
    }
`;

export const StyledLink = styled(Link)`
    color: ${white};

    &:hover {
        color: rgba(255, 255, 255, 0.8);
        text-decoration: underline;
    }
`;
