import { Row } from 'components/wrappers/flexWrapper';
import { grey2, white } from 'constants/styles';
import styled from 'styled-components';

export const UserInfo = styled(Row)`
    align-items: center;
`;

export const Span = styled.span`
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    margin-left: 8px;
    color: ${white};
`;

export const StyledImage = styled.img`
    border-radius: 50%;
    object-fit: cover;
`;

export const Delimiter = styled.div`
    width: 146px;
    border-bottom: 1px solid ${grey2};
    margin-top: 12px;
    margin-bottom: 14px;
`;

export const Networks = styled.div`
    display: grid;
    grid-gap: 12px;
`;

export const Followers = styled.span`
    font-size: 10px;
    line-height: 15px;
    margin-left: 7px;
`;
