import { Navbar } from 'components/grid/Navbar';
import { Sidebar } from 'components/grid/Sidebar';
import { Content, MainLayoutWrapper, MainPanel } from 'components/layouts/MainLayout/styles';
import { info } from 'components/modals/Message';
import { newRoutesArray } from 'constants/routes';
import { xl } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { settingsEvents, settingsStores } from 'stores/settings';
import { clearScrollPosition } from 'utils/usefulFunctions';

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
    const isDesktop = useMediaQuery({ query: `(max-width: ${xl})` });
    const { isSidebarMini } = useStore(settingsStores.sidebarSettings);
    const [isSidebarTranslated, setIsSidebarTranslated] = useState<boolean>(false);
    const mainPanelRef = useRef<null | HTMLDivElement>(null);
    const location = useLocation();

    useEffect(() => {
        if (!isDesktop) {
            setIsSidebarTranslated(false);
        }
    }, [isDesktop]);

    useEffect(() => {
        if (!mainPanelRef.current) return;
        clearScrollPosition(mainPanelRef.current);
    }, [location]);

    const handleMiniClick = () => {
        info('Sidebar mini ' + (!isSidebarMini ? 'deactivated' : 'activated'));
        settingsEvents.setSidebarSettings({ isSidebarMini: !isSidebarMini });
    };
    const toggleSidebar = () => {
        setIsSidebarTranslated((prev: boolean) => !prev);
    };
    const closeSidebar = () => {
        setIsSidebarTranslated(false);
    };

    return (
        <MainLayoutWrapper>
            <Sidebar
                closeSidebar={closeSidebar}
                isSidebarMini={isSidebarMini}
                isSidebarTranslated={isSidebarTranslated}
                routes={newRoutesArray}
            />
            <MainPanel ref={mainPanelRef} isSidebarMini={isSidebarMini} isSidebarTranslated={isSidebarTranslated}>
                <Navbar
                    handleMiniToggle={handleMiniClick}
                    isSidebarOpened={isSidebarTranslated}
                    toggleSidebar={toggleSidebar}
                />

                <Content isSidebarMini={isSidebarMini} isSidebarTranslated={isSidebarTranslated}>
                    {children}
                </Content>
            </MainPanel>
        </MainLayoutWrapper>
    );
};
