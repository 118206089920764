import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    overflow: auto;
    ${flexCenter};
`;
