import { PropertyBlock } from 'components/common/PropertyBlock';
import { FullComponentLoader } from 'components/common/FullComponentLoader';
import { GridWrapper } from 'components/wrappers/GridWrapper';
import { collaborationStatuses, CollaborationStatusesUi } from 'constants/defaults';
import { useStore } from 'effector-react';
import { Divider, Header, Wrapper } from 'pages/Dashboard/CollaborationsCard/styles';
import { CountTitle } from 'pages/Dashboard/CountTitle';
import { TotalCount } from 'pages/Dashboard/TotalCount';
import React, { useEffect, useState } from 'react';
import { dashboardEffects, dashboardStores } from 'stores/dashboard';

const { getCollaborationsCount } = dashboardEffects;
const { collaborationsCount, loadingCollaborations } = dashboardStores;

interface StatusType {
    collaborationStatus: number | number[];
    title: string;
    totalCount: number;
}

export const CollaborationsCard = () => {
    const count = useStore(collaborationsCount);
    const loading = useStore(loadingCollaborations);
    const [statusItems, setStatusItems] = useState<StatusType[]>([
        {
            collaborationStatus: CollaborationStatusesUi.Draft,
            title: collaborationStatuses[CollaborationStatusesUi.Draft],
            totalCount: 0
        },
        {
            collaborationStatus: CollaborationStatusesUi.StartingSoon,
            title: collaborationStatuses[CollaborationStatusesUi.StartingSoon],
            totalCount: 0
        },
        {
            collaborationStatus: [CollaborationStatusesUi.Active, CollaborationStatusesUi.ActiveClosed],
            title: collaborationStatuses[CollaborationStatusesUi.Active],
            totalCount: 0
        },
        {
            collaborationStatus: CollaborationStatusesUi.Finalizing,
            title: collaborationStatuses[CollaborationStatusesUi.Finalizing],
            totalCount: 0
        },
        {
            collaborationStatus: CollaborationStatusesUi.Ending,
            title: collaborationStatuses[CollaborationStatusesUi.Ending],
            totalCount: 0
        },
        {
            collaborationStatus: CollaborationStatusesUi.Ended,
            title: collaborationStatuses[CollaborationStatusesUi.Ended],
            totalCount: 0
        },
        {
            collaborationStatus: CollaborationStatusesUi.Deleted,
            title: collaborationStatuses[CollaborationStatusesUi.Deleted],
            totalCount: 0
        }
    ]);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        getCollaborationsCount();
    }, []);

    useEffect(() => {
        if (count) {
            setStatusItems((previousState: StatusType[]) =>
                previousState.map(({ collaborationStatus, title }) => {
                    let newTotalCount = 0;
                    if (Array.isArray(collaborationStatus)) {
                        newTotalCount = collaborationStatus.reduce((acc: number, cur: number) => {
                            const newCount: number = count.find(({ status }) => status === cur)?.totalCount || 0;
                            return acc + newCount;
                        }, 0);
                    } else {
                        newTotalCount = count.find(({ status }) => status === collaborationStatus)?.totalCount || 0;
                    }

                    return { collaborationStatus, title: title.toUpperCase(), totalCount: newTotalCount };
                })
            );
            setTotalCount(count.reduce((total, { totalCount }) => (totalCount ? total + totalCount : total), 0));
        }
    }, [count]);

    return (
        <Wrapper>
            <Header>
                <CountTitle icon="tim-icons icon-puzzle-10" title="COLLABORATIONS" />
                <TotalCount count={totalCount} />
            </Header>
            <Divider />
            <GridWrapper columns={3} marginBottom="16px">
                {statusItems.map(({ title, totalCount }) => (
                    <PropertyBlock key={title} subtitle={totalCount?.toString() || '0'} title={title} />
                ))}
            </GridWrapper>
            {loading && <FullComponentLoader />}
        </Wrapper>
    );
};
