import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const StatusesWrapperBlock = styled.div`
    ${flexCenter};
`;

export const PopoverText = styled.p`
    text-align: left;
`;
