import { FlexGrow, Section } from 'components/wrappers/flexWrapper';
import { black, ellipsisMixin, grey, grey2, propertyBlockStyleMixin, white } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const CardWrapper = styled(Section)`
    background-color: ${grey2};
    padding: 32px;
    width: 100%;

    @media ${device.mobile} {
        padding: 16px;
    }
`;

export const StyledSection = styled(Section)`
    flex-wrap: wrap;
    gap: 16px;
    margin-bottom: 16px;
`;

export const ContentWrapperRow = styled(Section)`
    justify-content: center;
    gap: 24px;
`;

export const StyledFlexGrow = styled(FlexGrow)`
    @media ${device.tablet} {
        width: 100%;
    }
`;

export const ImgWrapper = styled.div`
    position: relative;
    height: 364px;
    width: 356px;
    border-radius: 6px;
    background-color: ${black};

    @media ${device.mobile} {
        width: 100%;
    }
`;

export const NetworkBlock = styled(Section)`
    ${propertyBlockStyleMixin};
    margin-bottom: 16px;

    @media ${device.mobile} {
        overflow-x: auto;
    }
`;

export const NetworkTitle = styled.span`
    margin-right: 16px;
    font-size: 12px;
    line-height: 18px;
    color: ${white};
    opacity: 0.8;
    white-space: noWrap;
`;

export const RewardBlockWrapper = styled.div`
    width: 356px;
    flex-shrink: 0;

    @media ${device.tablet} {
        width: 100%;
    }
`;

export const DescriptionText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${white};
    white-space: initial;

    p {
        margin-bottom: 8px;
    }

    ul {
        list-style: disc;
        margin-bottom: 8px;

        li {
            list-style-position: inside;
            margin-bottom: 4px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
`;

export const DescriptionWrapper = styled.div`
    width: 100%;
    max-height: 200px;
    padding: 15px;
    margin-top: 10px;
    border-radius: 8px;
    background-color: ${black};
    overflow-y: auto;
    flex: 1;

    ::-webkit-scrollbar {
        width: 2px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${grey};
        border-radius: 10px;
    }
`;

export const DescriptionParentWrapper = styled(FlexGrow)`
    height: 100%;

    @media ${device.laptop} {
        height: auto;
    }
`;

export const BudgetWrapper = styled.div`
    width: 100%;
    padding-bottom: 6px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 4px;
    border-radius: 8px;
    background-color: ${black};
`;

export const TitleWrapper = styled.div`
    ${ellipsisMixin};
`;
