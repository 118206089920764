import { ClickableWrapper } from 'components/wrappers/ClickableWrapper';
import { darkGrey3, grey4, white } from 'constants/styles';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

interface Props {
    image: string;
    rounded: boolean;
}

export const Wrapper = styled.div<Props>`
    background: url(${({ image }) => image});
    background-color: ${grey4};
    background-size: cover;
    background-repeat: no-repeat;
    ${({ rounded }) => rounded && 'border-radius: 16px'};
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;

export const Video = styled.video`
    width: 100%;
    height: 100%;
`;

export const FlagWrapper = styled(ClickableWrapper)`
    width: 32px;
    height: 32px;
    background-color: rgba(50, 50, 50, 0.4);
    border-radius: 50%;
    ${flexCenter};
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
`;

export const PlayWrapper = styled(FlagWrapper)`
    width: 58px;
    height: 58px;
    background-color: rgba(0, 0, 0, 0.5);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const FullWrapper = styled(ClickableWrapper)`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(50, 50, 50, 0.4);
    ${flexCenter};
    position: absolute;
    z-index: 1;
    bottom: 8px;
    right: 8px;
`;

export const NetworkWrapper = styled.div`
    position: absolute;
    z-index: 1;
    background-color: ${darkGrey3};
    top: 10px;
    left: 10px;
    border-radius: 4px;
    padding: 6px 8px;
    display: flex;
    align-items: center;
`;

export const NetworkTitle = styled.span`
    font-size: 10px;
    line-height: 15px;
    margin-left: 7px;
    color: ${white};
`;

export const Badge = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 10px;
    border-radius: 4px;
    background-color: ${grey4};
    padding: 6px 12px;
    font-size: 10px;
    line-height: 15px;
`;
