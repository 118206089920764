import { useCallback, useState } from 'react';

/**
 * Hook to get toggle
 */
export const useToggle = (): [boolean, () => void] => {
    const [visible, setVisible] = useState(false);

    const handleToggle = useCallback(() => setVisible(vis => !vis), []);

    return [visible, handleToggle];
};
