import { css } from 'styled-components';
import { black, grey, grey5 } from './colors';
import { borderWidth, padding } from './sizes';

export const marginBottomMixin = css`
    margin-bottom: ${padding};
`;

export const borderMixin = css`
    border: ${borderWidth} solid ${grey};
`;

export const flexCenter = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const flexStart = css`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
`;

export const ellipsisMixin = css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const ellipsisRowMixin = css`
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
`;

export const disableDefaultButtonStyleMixin = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

export const primaryHoverBoxShadowMixin = css`
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
`;

export const disableDefaultInputStyleMixin = css`
    outline: none;
    appearance: none;
    border: none;
`;

export const absoluteCenterAlignmentMixin = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const propertyBlockStyleMixin = css`
    align-items: center;
    flex-wrap: nowrap;
    height: 58px;
    padding: 8px 15px;
    border-radius: 8px;
    background-color: ${black};
`;

export const disableDefaultCheckboxStyleMixin = css`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const scrollbar = css`
    ::-webkit-scrollbar {
        width: 2px;
        height: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${grey5};
        border-radius: 8px;
    }
`;
