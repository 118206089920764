import { defaultValues } from 'constants/defaults/collaborations';
import { errorLoadingMessage } from 'constants/defaults/common';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { API } from 'services';
import { WarningIcon, SuccessIcon } from 'assets/icons';
import { isUndefined } from 'utils/isUndefined';
import { singleCollaborationEffects } from './singleCollaboration';
import { modalEvents } from 'stores/collaborationModals';
import { singleCollaborationStores } from 'stores/singleCollaboration';

type ErrorType = BUCM.MessageErrorResponseBase | string | null;

//* Events

const { openStatusModal } = modalEvents;
const getByDefaultParams = createEvent();
const setParams = createEvent<Paths.Collaborations.Get.QueryParameters>();
const updateParams = createEvent<Paths.Collaborations.Get.QueryParameters>();
const setError = createEvent<ErrorType>();
const resetError = createEvent();
const loadMore = createEvent();
const resetCollaborations = createEvent();

//* Effects

const getCollaborations = createEffect({
    handler: async (params: Paths.Collaborations.Get.QueryParameters) => {
        try {
            const { data, error, isSuccess } = await API.collaborations.getCollaborations(params);

            if (isSuccess) {
                return data || {};
            }

            setError(error?.description || errorLoadingMessage);

            return {};
        } catch {
            setError(errorLoadingMessage);
            return {};
        }
    }
});

const stopCollaboration = createEffect({
    handler: async (collaborationId: string) => {
        try {
            const data = singleCollaborationStores.singleCollaborationData.getState();
            const { isSuccess } = await API.collaborations.stopCollaboration(collaborationId);
            if (isSuccess) {
                openStatusModal({
                    title: 'success',
                    text: `You successfully stopped “${data?.name}” collaboration`,
                    icon: SuccessIcon
                });
                singleCollaborationEffects.getCollaborationById({ collaborationId });
            }
        } catch (error) {
            openStatusModal({
                title: 'error',
                text: 'Unfortunately something went wrong',
                icon: WarningIcon
            });
        }
    }
});

interface LoadMoreCollaborationsParams {
    state: BUCM.CollaborationsResponse;
    params: Paths.Collaborations.Get.QueryParameters;
}

const collaborationsLoadMore = createEffect({
    handler: async ({ state: { hasNext }, params: { pageIndex, ...restParams } }: LoadMoreCollaborationsParams) => {
        try {
            if (hasNext && !isUndefined(pageIndex)) {
                const { data, error, isSuccess } = await API.collaborations.getCollaborations({
                    pageIndex: pageIndex + 1,
                    ...restParams
                });

                if (isSuccess) {
                    setParams({ pageIndex: pageIndex + 1, ...restParams });
                    return data || {};
                }

                setError(error?.description || errorLoadingMessage);
            }

            return {};
        } catch {
            setError(errorLoadingMessage);
            return {};
        }
    }
});

const getCollaborationsCount = createEffect({
    handler: async () => {
        try {
            const { data } = await API.collaborations.getCollaborationsCount();

            return data?.collaborationStates;
        } catch {
            return null;
        }
    }
});

//* Stores

const collaborations = createStore<BUCM.CollaborationsResponse>({})
    .on(getCollaborations.doneData, (_, newState) => newState)
    .on(collaborationsLoadMore.doneData, ({ items }, newState) => ({
        ...newState,
        items: [...(items || []), ...(newState?.items || [])]
    }))
    .reset(resetCollaborations);

const errors = createStore<ErrorType>(null)
    .on(setError, (_, error) => error)
    .reset(resetError);

const params = createStore<Paths.Collaborations.Get.QueryParameters>(defaultValues)
    .on(updateParams, (state, newState) => ({
        ...state,
        ...newState
    }))
    .on(setParams, (_, newState) => newState)
    .reset(getByDefaultParams);

params.watch(getByDefaultParams, () => getCollaborations(defaultValues));
params.watch(updateParams, state => getCollaborations(state));

sample({
    source: [collaborations, params],
    clock: loadMore,
    fn: ([collaborations, params]) => ({
        state: collaborations,
        params
    }),
    target: collaborationsLoadMore
});

const collaborationsCount = createStore<BUCM.GetCollaborationStateCountDto[] | null>(null).on(
    getCollaborationsCount.doneData,
    (_, newState) => newState
);
//* Exports

const collaborationsEvents = {
    getByDefaultParams,
    setParams,
    updateParams,
    setError,
    resetError,
    loadMore,
    resetCollaborations
};

const collaborationsEffects = { getCollaborations, collaborationsLoadMore, getCollaborationsCount, stopCollaboration };
const collaborationsStores = { errors, params, collaborations, collaborationsCount };

export { collaborationsEffects, collaborationsStores, collaborationsEvents };
