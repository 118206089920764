import { CopyButton } from 'components/common/CopyButton';
import { Span } from 'components/typography/Span';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Row, Section } from 'components/wrappers/flexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { black, sm, white } from 'constants/styles';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Title } from 'types';
import { Copyable, Success } from 'types/interface';
import { getEllipsisAddress } from 'utils/usefulFunctions';
import { ScrollableWrapper } from './styles';

interface DescriptionPropertyProps extends Title, Copyable, Partial<Success> {
    icon?: JSX.Element;
}

export const DescriptionProperty = ({ icon, title, subtitle, copyable, successText }: DescriptionPropertyProps) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${sm})` });
    const isId = title?.toLowerCase().split(' ').includes('id');
    const id = isMobile && isId && getEllipsisAddress(subtitle as string, 6);

    return (
        <ScrollableWrapper isMobile={isMobile}>
            <ContentWrapper
                backgroundColor={black}
                minHeight="58px"
                minWidth="100%"
                padding="20px 15px"
                width="fit-content"
            >
                <Section alignCenter justifyBetween noWrap gap="13px" height="100%">
                    <Row alignCenter noWrap height="100%">
                        {title && (
                            <MarginWrapper marginRight="15px">
                                <Span noWrap color="rgba(255, 255, 255, 0.8)" fontSize="12px">
                                    {title}
                                </Span>
                            </MarginWrapper>
                        )}
                        <Row>
                            <Span noWrap color={white}>
                                {id || subtitle}
                            </Span>
                        </Row>
                    </Row>

                    {icon}

                    {copyable && <CopyButton subject={subtitle} successText={successText} />}
                </Section>
            </ContentWrapper>
        </ScrollableWrapper>
    );
};
