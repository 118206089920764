import { Span } from 'components/typography/Span';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Column, Row } from 'components/wrappers/flexWrapper';
import { black, xs } from 'constants/styles';
import React, { FC, ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Title } from 'types';
import { ScrollableContent } from './styles';

interface Props extends Pick<Title, 'title'> {
    children: ReactNode;
}

export const DescriptionContent: FC<Props> = ({ children, title }) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    return (
        <Column width={isMobile ? '100%' : 'auto'}>
            <Row marginBottom="10px">
                <Span>{title}</Span>
            </Row>
            <ScrollableContent>
                <ContentWrapper backgroundColor={black} padding="15px" width="fit-content">
                    <Row noWrap width={isMobile ? '100%' : 'auto'}>
                        {children}
                    </Row>
                </ContentWrapper>
            </ScrollableContent>
        </Column>
    );
};
