import { blue, flexCenter, white } from 'constants/styles';
import styled from 'styled-components';

export const Title = styled.h3`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    font-weight: 400;
`;

export const IconWrapper = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: ${blue};
    ${flexCenter};
    color: ${white};
    font-size: 28px;
`;
