import { SortState } from 'components/ui/SortButton/constants';

export enum SortValues {
    CreationDateAsc = 'utcCreated+asc',
    CreationDateDesc = 'utcCreated+desc',
    EscrowWomBalanceAsc = 'womInEscrowValue+asc',
    EscrowWomBalanceDesc = 'womInEscrowValue+desc'
}

export const sortByParams = {
    date: { [SortState.Asc]: SortValues.CreationDateAsc, [SortState.Desc]: SortValues.CreationDateDesc },
    balance: { [SortState.Asc]: SortValues.EscrowWomBalanceAsc, [SortState.Desc]: SortValues.EscrowWomBalanceDesc }
};
