import { Button } from 'components/ui/Button';
import { SearchInput } from 'components/form/SearchInput';
import { InfoTitle } from 'components/typography/InfoTitle';
import { infoTitle } from 'constants/messages';
import React, { FC, useState } from 'react';
import { organizationsEvents, organizationsStores } from 'stores/organizations';
import { TotalRecords } from 'types';
import { ButtonsWrapper, ButtonWrapper, FilterWrapper, SearchWrapper } from './styles';
import { useStore } from 'effector-react';
import { Section } from 'components/wrappers/flexWrapper';
import { xxs_1 } from 'constants/styles';
import { useMediaQuery } from 'react-responsive';
import { sortByParams } from './constants';
import { defaultLimit, defaultPage } from 'constants/defaults/common';
import { SortButton } from 'components/ui/SortButton';
import { SortState } from 'components/ui/SortButton/constants';

const { updateParams, getOrganizationsWithDefaultParams } = organizationsEvents;
const { params } = organizationsStores;

export const OrganizationsFilter: FC<TotalRecords> = ({ totalRecords }) => {
    const { pageIndex, limit, returnQueryCount } = useStore(params);
    const isMobile = useMediaQuery({ query: `(max-width: ${xxs_1})` });
    const { date, balance } = sortByParams;
    const [sortByDateState, setSortByDateState] = useState(SortState.Desc);
    const [sortByBalanceState, setSortByBalanceState] = useState(SortState.None);

    const searchByOrganizationId = (value: string) => {
        updateParams({
            organizationName: undefined,
            organizationId: value,
            publicId: undefined,
            pageIndex: defaultPage,
            limit: defaultLimit
        });
    };

    const getItems = (value: string) => {
        updateParams({ sort: value, pageIndex, limit, returnQueryCount });
    };

    const onSortByDateClick = () => {
        setSortByBalanceState(SortState.None);

        if (sortByDateState === SortState.Desc) {
            setSortByDateState(SortState.Asc);
            getItems(date[SortState.Asc]);
        } else {
            setSortByDateState(SortState.Desc);
            getItems(date[SortState.Desc]);
        }
    };

    const onSortByWomClick = () => {
        setSortByDateState(SortState.None);

        if (sortByBalanceState === SortState.Desc) {
            setSortByBalanceState(SortState.Asc);
            getItems(balance[SortState.Asc]);
        } else {
            setSortByBalanceState(SortState.Desc);
            getItems(balance[SortState.Desc]);
        }
    };

    const onResetClick = () => {
        setSortByDateState(SortState.Desc);
        setSortByBalanceState(SortState.None);
        getOrganizationsWithDefaultParams();
    };

    return (
        <>
            <FilterWrapper>
                {isMobile && (
                    <Section justifyEnd marginBottom="16px">
                        <Button onClick={onResetClick}>Reset</Button>
                    </Section>
                )}

                <SearchWrapper>
                    <SearchInput placeholder="Search by Organization ID" onSearch={searchByOrganizationId} />
                </SearchWrapper>

                <ButtonsWrapper>
                    <SortButton sortState={sortByDateState} onClick={onSortByDateClick}>
                        Date of creation
                    </SortButton>

                    <ButtonWrapper>
                        <SortButton sortState={sortByBalanceState} onClick={onSortByWomClick}>
                            WOM in escrow
                        </SortButton>
                    </ButtonWrapper>

                    {!isMobile && <Button onClick={onResetClick}>Reset</Button>}
                </ButtonsWrapper>
            </FilterWrapper>
            <InfoTitle title={infoTitle}>{totalRecords !== -1 ? totalRecords : 0}</InfoTitle>
        </>
    );
};
