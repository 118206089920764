import { grey2, white } from 'constants/styles/colors';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
.rc-tooltip {
    opacity: 1;
}
.custom-tooltip {

  .rc-tooltip-inner {
      padding: 8px 12px !important;
      border: none !important;
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      line-height: 18px;
      border-radius: 4px;
      color: ${grey2};
      background: ${white} !important;
   }

  &.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: 20px;
  }

  &.rc-tooltip-placement-bottom .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
      border-bottom-color: ${white} !important;
      top: -4px;
   }

  &.rc-tooltip-placement-top .rc-tooltip-arrow,
  &.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
  &.rc-tooltip-placement-topRight .rc-tooltip-arrow {
      border-top-color: ${white} !important;
      top: -4px;
   }

  &.rc-tooltip-placement-left .rc-tooltip-arrow,
  &.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
  &.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
      border-left-color: ${white} !important;
      top: -4px;
   }

  &.rc-tooltip-placement-right .rc-tooltip-arrow,
  &.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
  &.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
      border-right-color: ${white} !important;
      top: -4px;
   }
}
`;
