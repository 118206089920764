import React, { useState, ChangeEvent, useEffect } from 'react';
import { useStore } from 'effector-react';
import { CloseButton } from 'components/common/CloseButton';
import { WindowLoader } from 'components/common/WindowLoader';
import { Title } from 'components/typography/Title';
import { Checkbox } from 'components/ui/Checkbox';
import { Span } from 'components/typography/Span';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { Section } from 'components/wrappers/flexWrapper';
import { Button } from 'components/ui/Button';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { submissionsStores, submissionsEffects, submissionsEvents } from 'stores/collaborationSubmissions';
import { black, grey } from 'constants/styles/colors';
import { feedbackReportTypes } from 'constants/defaults/collaborations';
import { ContentWrapper, ModalWrapper } from './styles';
import { FeedbackActionType } from './types';
import { acceptAction, rejectAction } from './constants';

const { getSubmissionFeedback, sendAcceptFeedback } = submissionsEffects;
const { submissionFeedback } = submissionsStores;
const { closeFeedbackModal } = submissionsEvents;

export const AcceptReportModal = () => {
    const [selectedAction, setSelectedAction] = useState<FeedbackActionType | null>(null);
    const [agreement, setAgreement] = useState(false);

    const isLoading = useStore(getSubmissionFeedback.pending);
    const feedbackInfo = useStore(submissionFeedback);
    const isSending = useStore(sendAcceptFeedback.pending);

    const handleSelectAction = (action: FeedbackActionType) => {
        setSelectedAction(action);
    };

    const handleChangeAgreement = (e: ChangeEvent<HTMLInputElement>) => {
        setAgreement(e.target.checked);
    };

    const sendAccept = () => {
        if (feedbackInfo && feedbackInfo.mediaAssetId && feedbackInfo.id) {
            sendAcceptFeedback({
                submissionId: feedbackInfo.mediaAssetId,
                feedbackId: feedbackInfo.id,
                isAccepted: selectedAction === acceptAction
            });
        }
    };

    useEffect(() => {
        setSelectedAction(null);
        setAgreement(false);
    }, [feedbackInfo]);

    if (isLoading || isSending) {
        return <WindowLoader />;
    }

    if (!!!feedbackInfo) {
        return null;
    }

    return (
        <ModalWrapper>
            <ContentWrapper>
                <AbsoluteWrapper right="16px" top="16px" zIndex="1002">
                    <CloseButton color={grey} onClick={() => closeFeedbackModal()} />
                </AbsoluteWrapper>

                <Section maxWidth="80%">
                    <Title color={black} fontSize="20px">
                        Brand has reported this video.
                    </Title>
                </Section>

                {feedbackInfo.type && (
                    <Section marginTop="24px">
                        <Span color={black} fontSize="14px">
                            Reason: {feedbackReportTypes[feedbackInfo.type]}
                        </Span>
                    </Section>
                )}

                <Section marginTop="24px">
                    <MarginWrapper marginRight="24px">
                        <Button
                            disabled={selectedAction === acceptAction}
                            type="button"
                            onClick={() => handleSelectAction(acceptAction)}
                        >
                            Accept report
                        </Button>
                    </MarginWrapper>
                    <Button
                        buttonType="red"
                        disabled={selectedAction === rejectAction}
                        type="button"
                        onClick={() => handleSelectAction(rejectAction)}
                    >
                        Reject report
                    </Button>
                </Section>

                <Section marginTop="24px">
                    <Checkbox checked={agreement} onChange={handleChangeAgreement}>
                        Are you sure?
                    </Checkbox>
                </Section>

                <Section marginTop="24px">
                    <Button disabled={!selectedAction || !agreement} type="button" onClick={sendAccept}>
                        Apply
                    </Button>
                </Section>
            </ContentWrapper>
        </ModalWrapper>
    );
};
