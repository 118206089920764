import { Section } from 'components/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const UserDescriptionWrapper = styled(Section)`
    gap: 30px;
    flex-wrap: nowrap;

    @media ${device.mobile} {
        gap: 15px;
        flex-wrap: wrap;
    }
`;

export const Title = styled.h4`
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 12px;
`;
