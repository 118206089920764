import { defaultEnrollmentsParams, errorMessage } from 'constants/defaults';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { API } from 'services';
import { filterApproveVideo } from 'utils/filterApproveVideo';

// Events
const loadMore = createEvent<string>();
const setErrors = createEvent<BUCM.ErrorResponse | string | null>();
const updateEnrollmentsParams = createEvent<Paths.Collaborations$CollaborationIdParticipations.Get.QueryParameters>();
const setCollaborationsErrors = createEvent<BUCM.ErrorResponse | string | null>();
const resetCollaborationsErrors = createEvent();

// Effects
const getApproveVideoFx = createEffect({
    handler: async ({ collaborationId }: Paths.Collaborations$CollaborationId.Patch.PathParameters) => {
        try {
            const { isSuccess, data, error } = await API.collaborations.getEnrollments(
                collaborationId,
                defaultEnrollmentsParams
            );
            if (isSuccess) {
                return data;
            }

            setCollaborationsErrors(error || errorMessage);
            return {};
        } catch (error) {
            setCollaborationsErrors(errorMessage);
            return {};
        }
    }
});

interface LoadMoreEnrollmentsParams {
    collaborationId: string;
    params: BUCM.ParticipationsResponse;
}

const loadMoreApproveVideo = createEffect({
    handler: async ({ collaborationId, params }: LoadMoreEnrollmentsParams) => {
        try {
            const { isSuccess, data, error } = await API.collaborations.getEnrollments(collaborationId, {
                ...defaultEnrollmentsParams,
                pageIndex: params.pageIndex !== undefined ? params.pageIndex + 1 : defaultEnrollmentsParams.pageIndex
            });

            if (isSuccess) {
                resetCollaborationsErrors();
                return data;
            }

            setCollaborationsErrors(error || errorMessage);
            return {};
        } catch (error) {
            setCollaborationsErrors(errorMessage);
            return {};
        }
    }
});

//Stores
const $approveVideoLoading = createStore<boolean>(false).on(getApproveVideoFx.pending, (_, payload) => payload);

const enrollmentsParams = createStore<Paths.Collaborations$CollaborationIdParticipations.Get.QueryParameters>(
    defaultEnrollmentsParams
).on(updateEnrollmentsParams, (state, newState) => ({
    ...state,
    ...newState
}));

const $approveVideoLoadMoreLoading = createStore<boolean>(false).on(
    loadMoreApproveVideo.pending,
    (_, payload) => payload
);

const $approveVideo = createStore<BUCM.ParticipationsResponse>({})
    .on(getApproveVideoFx.doneData, (_, newState) => newState && filterApproveVideo(newState))
    .on(loadMoreApproveVideo.doneData, ({ items }, newState) => ({
        ...newState,
        items: [...(items || []), ...((newState && filterApproveVideo(newState).items) || [])]
    }));

const $errors = createStore<BUCM.ErrorResponse | string | null>(null).on(setErrors, (_, newState) => newState);

sample({
    source: [$approveVideo, enrollmentsParams],
    clock: loadMore,
    fn: ([enrollments, enrollmentsParams], collaborationId) => ({
        collaborationId,
        state: enrollments,
        params: enrollmentsParams
    }),
    target: loadMoreApproveVideo
});

// Exports

export const collaborationApproveVideoEvents = {
    loadMore
};

export const collaborationApproveVideoEffects = {
    getApproveVideoFx
};

export const collaborationApproveVideoStores = {
    $approveVideo,
    $approveVideoLoading,
    $errors,
    $approveVideoLoadMoreLoading
};
