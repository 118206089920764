import { VerificationIcon } from 'assets/icons';
import React, { FC } from 'react';
import { BorderRadius, ImgProperties } from 'types';
import { AvatarWrapper, VerifiedStatus } from './styles';

interface Props extends BorderRadius, ImgProperties {
    isVerified?: boolean;
}

export const Avatar: FC<Props> = ({ src, isVerified = false, borderRadius }) => (
    <AvatarWrapper borderRadius={borderRadius} src={src}>
        {isVerified && (
            <VerifiedStatus>
                <VerificationIcon />
            </VerifiedStatus>
        )}
    </AvatarWrapper>
);
