import { WomIcon } from 'assets/icons';
import { PropertyBlock } from 'components/common/PropertyBlock';
import { BlockTitle } from 'components/common/PropertyBlock/styles';
import { PropertyRowBlock } from 'components/common/PropertyRowBlock';
import { CustomImg } from 'components/ui/CustomImg';
import { Span } from 'components/typography/Span';
import { Title } from 'components/typography/Title';
import { Section, Row } from 'components/wrappers/flexWrapper';
import { GridWrapper } from 'components/wrappers/GridWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { collaborationStatuses } from 'constants/defaults';
import { blue, lg, xs, white } from 'constants/styles';
import { CollaborationStatus } from 'pages/Collaborations/CollaborationCard/styles';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ImgProperties, Title as TitleProps } from 'types';
import { getFormattedDate } from 'utils/formateDate';
import { parseCalendarDate } from 'utils/usefulFunctions';
import { CollaborationStatusesUi } from 'constants/defaults/collaborations';
import { RewardItem } from '../RewardItem';
import { modalEvents } from 'stores/collaborationModals';
import { collaborationsEffects } from 'stores/collaborations';
import { copyCollaborationIdMessage, copyOrganizationIdMessage, copyWebsiteURLMessage, dateFormat } from './constants';
import {
    BudgetWrapper,
    CardWrapper,
    ContentWrapperRow,
    DescriptionParentWrapper,
    DescriptionText,
    DescriptionWrapper,
    ImgWrapper,
    NetworkBlock,
    NetworkTitle,
    RewardBlockWrapper,
    StyledFlexGrow,
    StyledSection,
    TitleWrapper
} from './styles';
import { useStore } from 'effector-react';
import { Button } from 'components/ui/Button';
import { externalNetworksStores } from 'stores/externalNetworks';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { ApproveStatusCollaboration } from 'components/common/ApproveStatusCollaboration';

export interface Props extends BUCM.CollaborationResponse, TitleProps, ImgProperties {}

interface NetworksType extends BUCM.ExternalNetworkResponse {
    count?: number | string;
}

const { externalNetworks } = externalNetworksStores;

export const SingleCollaborationCard: FC<Props> = ({
    networks,
    coverImageUrl,
    budgetAccepted,
    participationLimits,
    name,
    state,
    id,
    organizationId,
    websiteUrl,
    utcStart,
    utcEnd,
    utcCreated,
    spokenLanguage,
    description,
    autoApproveMedia,
    autoApproveParticipants
}) => {
    const extNetworks = useStore(externalNetworks);

    const commonNetworks: NetworksType[] = extNetworks.map(itemExternal => {
        const currentNetwork = networks?.find(itemNetwork => itemNetwork.networkId === itemExternal.id);
        return currentNetwork
            ? {
                  ...itemExternal,
                  count: currentNetwork.rewardOnSubmitted
              }
            : itemExternal;
    });

    const isMobile = useMediaQuery({ query: `(max-width: ${lg})` });
    const isSmallMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const startDate = utcStart ? getFormattedDate(utcStart, dateFormat) : '-';
    const endDate = utcEnd ? getFormattedDate(utcEnd, dateFormat) : '-';
    const creationDate = utcCreated ? parseCalendarDate(new Date(utcCreated)) : '-';
    const collaborationStatus = state ? collaborationStatuses[state] : '-';
    const isActiveCollaboration =
        state === CollaborationStatusesUi.Active || state === CollaborationStatusesUi.ActiveClosed;

    const buttonTitle = isSmallMobile ? 'Stop' : 'Stop Collaboration';

    const handleStopCollaboration = () => {
        modalEvents.openStatusModal({
            text: 'Are you sure you want to stop the collaboration?',
            buttonText: 'Yes',
            cancelText: 'No',
            onClick: () => {
                if (id) collaborationsEffects.stopCollaboration(id);
            }
        });
    };

    return (
        <CardWrapper>
            <ContentWrapperRow marginBottom="24px">
                <ImgWrapper>
                    <CustomImg alt="play icon" src={coverImageUrl || ''} />
                    <AbsoluteWrapper>
                        <ApproveStatusCollaboration
                            autoApproveMedia={autoApproveMedia}
                            autoApproveParticipants={autoApproveParticipants}
                        />
                    </AbsoluteWrapper>
                </ImgWrapper>

                <StyledFlexGrow flexGrow="2" flexShrink="0">
                    <Row justifyBetween noWrap width="100%">
                        <Section marginBottom="12px" maxWidth={isSmallMobile ? '60%' : '100%'}>
                            {isSmallMobile ? (
                                <TitleWrapper>
                                    <Title color={white} fontSize="17px">
                                        {name}
                                    </Title>
                                </TitleWrapper>
                            ) : (
                                <Title color={white} fontSize="17px">
                                    {name}
                                </Title>
                            )}
                        </Section>
                        {isActiveCollaboration && (
                            <Button noWrap buttonType="pink" onClick={handleStopCollaboration}>
                                {buttonTitle}
                            </Button>
                        )}
                    </Row>
                    <MarginWrapper marginBottom="24px">
                        <CollaborationStatus status={state || 0}>{collaborationStatus}</CollaborationStatus>
                    </MarginWrapper>

                    <StyledSection>
                        <PropertyRowBlock
                            copyable
                            isShort
                            flexBasis="254px"
                            subtitle={id}
                            successText={copyCollaborationIdMessage}
                            title="Collaboration ID"
                        />

                        <PropertyRowBlock
                            copyable
                            isShort
                            flexBasis="254px"
                            subtitle={organizationId}
                            successText={copyOrganizationIdMessage}
                            title="Organization ID"
                        />
                    </StyledSection>
                    <StyledSection>
                        <PropertyRowBlock
                            copyable
                            flexBasis="254px"
                            subtitle={websiteUrl || ''}
                            successText={copyWebsiteURLMessage}
                            title="Website URL"
                        />

                        <PropertyRowBlock flexBasis="254px" subtitle={startDate} title="Start date" />
                        <PropertyRowBlock flexBasis="254px" subtitle={endDate} title="End date" />
                    </StyledSection>

                    <NetworkBlock>
                        <NetworkTitle>Network ID</NetworkTitle>

                        {networks?.map(({ networkId, networkTitle }) => (
                            <RewardItem
                                key={networkId}
                                network={networkTitle?.toLowerCase() || ''}
                                networkId={networkId || ''}
                                showMode="id"
                            />
                        ))}
                    </NetworkBlock>

                    <StyledSection>
                        <PropertyRowBlock
                            flexBasis="286px"
                            flexGrow="0"
                            subtitle={creationDate}
                            title="Date of Creation"
                        />
                        <PropertyRowBlock
                            flexBasis="154px"
                            flexGrow="0"
                            subtitle={spokenLanguage || ''}
                            title="Language"
                        />
                    </StyledSection>
                </StyledFlexGrow>
            </ContentWrapperRow>
            <ContentWrapperRow alignSelf="normal" noWrap={!isMobile}>
                <RewardBlockWrapper>
                    <MarginWrapper marginBottom="10px">
                        <Title color={white}>Reward for sharing</Title>
                    </MarginWrapper>

                    <GridWrapper columns={2} marginBottom="16px">
                        {commonNetworks?.map(({ id, count, title }) => (
                            <RewardItem key={id} network={title?.toLowerCase() || ''} wom={count || '-'} />
                        ))}
                    </GridWrapper>

                    <Section marginBottom="16px">
                        <BudgetWrapper>
                            <Section>
                                <BlockTitle>Budget Accepted</BlockTitle>
                            </Section>
                            <Section alignCenter gap="8px" marginTop="4px">
                                <Span color={blue}>{budgetAccepted || '-'}</Span>
                                {budgetAccepted && <WomIcon />}
                            </Section>
                        </BudgetWrapper>
                    </Section>

                    <Section>
                        <PropertyBlock
                            marginBottom="0"
                            subtitle={participationLimits?.submitted?.toString() || '-'}
                            subtitleColor={blue}
                            title="Participation Limits"
                            width="100%"
                        />
                    </Section>
                </RewardBlockWrapper>
                <DescriptionParentWrapper flexGrow="3">
                    <Title color={white}>Description</Title>

                    <DescriptionWrapper>
                        <DescriptionText dangerouslySetInnerHTML={{ __html: description || '-' }} />
                    </DescriptionWrapper>
                </DescriptionParentWrapper>
            </ContentWrapperRow>
        </CardWrapper>
    );
};
