import { Loader } from 'components/common/Loader';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/grid/Empty';
import { VideoModal } from 'components/modals/VideoModal';
import { useStore } from 'effector-react';
import { EnrollmentTab } from 'pages/Collaboration/EnrollmentTab';
import { SharedTab } from 'pages/Collaboration/SharedTab';
import { LoaderWrapper, Title } from 'pages/Collaboration/styles';
import { SubmissionsTab } from 'pages/Collaboration/SubmissionTab';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { collaborationsEffects, collaborationsStores } from 'stores/collaborationEnrollments';
import { submissionsEffects, submissionsStores } from 'stores/collaborationSubmissions';
import { sharedSubmissionsEffects, sharedSubmissionsStores } from 'stores/sharedSubmissions';
import {
    singleCollaborationEffects,
    singleCollaborationEvents,
    singleCollaborationStores
} from 'stores/singleCollaboration';
import { Id } from 'types/interfaces/data';
import { collaborationNotFoundMessage } from './constants';
import { SingleCollaborationCard } from './SingleCollaborationCard';
import { TabPanel, Tabs } from './Tabs';
import { TabsButton } from './Tabs/styles';
import { TabProps } from './Tabs/types';
import { AcceptReportModal } from 'components/modals/AcceptReportModal';
import { KickUserModal } from 'components/modals/KickUserModal';
import { RequestApprovalTab } from './RequestedApprovalTab';
import { DeniedApproval } from './DeniedApproval';
import { ParticipationEndedReason, ParticipationState } from 'constants/defaults/enrollments';

const { setActiveTab } = singleCollaborationEvents;
const { tab, singleCollaborationData } = singleCollaborationStores;
const { submissions } = submissionsStores;
const { enrollments } = collaborationsStores;
const { submissions: sharedSubmissions } = sharedSubmissionsStores;
const { getEnrollments } = collaborationsEffects;
const { getSubmissions } = submissionsEffects;
const { getCollaborationById } = singleCollaborationEffects;
const { clearSingleCollaborationData } = singleCollaborationEvents;
const { getSharedSubmissions } = sharedSubmissionsEffects;

export const Collaboration = () => {
    const { id } = useParams<Id>();
    const activeTab = useStore(tab);
    const loading = useStore(getCollaborationById.pending);
    const collaborationData = useStore(singleCollaborationData);
    const handleChange = (newValue: number) => () => {
        setActiveTab(newValue);
    };

    const autoApproveParticipants = collaborationData?.autoApproveParticipants;
    const autoApproveMedia = collaborationData?.autoApproveMedia;

    const enrollmentsItems = useStore(enrollments).items;
    const submissionsItems = useStore(submissions).items;
    const sharedSubmissionsItems = useStore(sharedSubmissions).items;

    const approveVideoItems =
        enrollmentsItems &&
        enrollmentsItems.length > 0 &&
        enrollmentsItems.filter(item => item.participationState === ParticipationState.EnrollmentRequested);

    const approveParticipationItems =
        enrollmentsItems &&
        enrollmentsItems.length > 0 &&
        enrollmentsItems.filter(
            item => item.participationEndedReason === ParticipationEndedReason.ParticipationRequestRejected
        );

    useEffect(() => {
        getCollaborationById({ collaborationId: id });

        return () => {
            setActiveTab(1);
            clearSingleCollaborationData();
        };
    }, [id]);

    useEffect(() => {
        if (collaborationData) {
            getEnrollments({ collaborationId: id });
            getSubmissions(id);
            getSharedSubmissions(id);
        }
    }, [id, collaborationData]);

    const tabsOptions: TabProps[] = useMemo(() => {
        const tabs: TabProps[] = [
            {
                id: 1,
                title: `Submissions${submissionsItems?.length ? ` (${submissionsItems.length})` : ''}`,
                content: <SubmissionsTab isApproveVideo={!autoApproveMedia && autoApproveParticipants} />
            },
            {
                id: 2,
                title: `Enrollments${!!enrollmentsItems?.length ? ` (${enrollmentsItems.length})` : ''}`,
                content: <EnrollmentTab />
            },
            {
                id: 3,
                title: `Shared${!!sharedSubmissionsItems?.length ? ` (${sharedSubmissionsItems.length})` : ''}`,
                content: <SharedTab />
            }
        ];

        if (enrollmentsItems && enrollmentsItems.length > 0 && autoApproveMedia && !autoApproveParticipants) {
            tabs.unshift({
                id: 0,
                title: `Requested approval${
                    approveVideoItems && !!approveVideoItems?.length ? ` (${approveVideoItems.length})` : ''
                }`,
                content: <RequestApprovalTab />
            });
        }

        if (enrollmentsItems && enrollmentsItems.length > 0 && autoApproveMedia && !autoApproveParticipants) {
            tabs.push({
                id: 4,
                title: `Denied approval${
                    approveParticipationItems && !!approveParticipationItems?.length
                        ? ` (${approveParticipationItems.length})`
                        : ''
                }`,
                content: <DeniedApproval />
            });
        }

        return tabs;
    }, [
        autoApproveMedia,
        autoApproveParticipants,
        submissionsItems,
        enrollmentsItems,
        sharedSubmissionsItems,
        approveVideoItems,
        approveParticipationItems
    ]);

    return (
        <MainLayout>
            <Breadcrumb />
            <Title>Collaboration info</Title>

            {loading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : (
                <>
                    <MarginWrapper marginBottom="24px">
                        {collaborationData ? (
                            <SingleCollaborationCard {...collaborationData} />
                        ) : (
                            <MarginWrapper marginTop="48px">
                                <Empty description={collaborationNotFoundMessage} />
                            </MarginWrapper>
                        )}
                    </MarginWrapper>
                </>
            )}

            <MarginWrapper marginBottom="64px" marginTop="24px">
                <Tabs>
                    {tabsOptions.map((tab: TabProps) => (
                        <TabsButton key={tab.id} isActive={activeTab === tab.id} onClick={handleChange(tab.id)}>
                            {tab.title}
                        </TabsButton>
                    ))}
                </Tabs>

                <MarginWrapper marginTop="16px">
                    {tabsOptions.map((tab: TabProps) => (
                        <TabPanel key={tab.id} tabKey={tab.id} value={activeTab}>
                            {tab.content}
                        </TabPanel>
                    ))}
                </MarginWrapper>
            </MarginWrapper>
            <VideoModal />
            <AcceptReportModal />
            <KickUserModal />
        </MainLayout>
    );
};
