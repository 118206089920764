import axios from './axios';

export const getExternalNetworks = () =>
    axios<BUCM.ExternalNetworksResponseApiResponse>({
        url: '/external-networks',
        method: 'GET'
    });

export const toggleExternalNetwork = (data: BUCM.ToggleExternalNetworkFeatureRequest) =>
    axios<BUCM.ApiResponse>({
        url: '/external-networks/toggle-external-network-feature',
        method: 'POST',
        data
    });
