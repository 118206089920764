import { success } from 'components/modals/Message';
import { createEffect, createStore, restore } from 'effector';
import { API } from 'services';

export interface NetworkWithStatus {
    id: string;
    title: string;
    enabled: boolean;
}

// Effects

const getExternalNetworks = createEffect({
    handler: async () => {
        try {
            const { isSuccess, data } = await API.externalNetworks.getExternalNetworks();

            if (isSuccess) {
                return data?.items || [];
            }

            return [];
        } catch (error) {
            return [];
        }
    }
});

const toggleExternalNetwork = createEffect({
    handler: async ({ networkName, isChecked }: BUCM.ToggleExternalNetworkFeatureRequest) => {
        try {
            const { isSuccess } = await API.externalNetworks.toggleExternalNetwork({ networkName, isChecked });

            if (isSuccess) {
                success(`${networkName} successfully ${isChecked ? 'enabled' : 'disabled'}`);
                return { networkName, isChecked };
            }

            return {};
        } catch (error) {
            return {};
        }
    }
});

// Stores

const defaultExternalNetworksWithStatus: NetworkWithStatus[] = [
    { id: '0', title: 'BULLZ', enabled: false },
    { id: '1', title: 'Twitter', enabled: false },
    { id: '2', title: 'Instagram', enabled: false },
    { id: '3', title: 'YouTube', enabled: false },
    { id: '4', title: 'TikTok', enabled: false },
    { id: '5', title: 'Twitch', enabled: false }
];

const externalNetworks = restore(getExternalNetworks, []);

const externalNetworksWithStatus = createStore<NetworkWithStatus[]>(defaultExternalNetworksWithStatus)
    .on(
        toggleExternalNetwork.doneData,
        (prevState, { networkName, isChecked }: BUCM.ToggleExternalNetworkFeatureRequest) => {
            const newState: NetworkWithStatus[] = prevState.map(network => {
                if (network.title === networkName) {
                    return { ...network, enabled: isChecked || false };
                }
                return network;
            });

            return newState;
        }
    )
    .on(getExternalNetworks.doneData, (prevState, networks: BUCM.ExternalNetworkResponse[]) =>
        prevState.map(({ id, title }) => {
            if (networks?.find(item => item.title === title)) {
                return { id, title, enabled: true };
            }
            return { id, title, enabled: false };
        })
    );

// Exports

const externalNetworksEffects = {
    getExternalNetworks,
    toggleExternalNetwork
};

const externalNetworksStores = {
    externalNetworks,
    externalNetworksWithStatus
};

export { externalNetworksStores, externalNetworksEffects };
