import { grey2 } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 17px 24px;
    background-color: ${grey2};
    border-radius: 8px;
    overflow: hidden;
    position: relative;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @media ${device.desktop} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media ${device.small} {
        grid-template-columns: 1fr;
    }
`;

export const Title = styled.h2`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    font-weight: 400;
`;
