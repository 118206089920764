import axios from './axios';

export const getCollaborations = (data: Paths.Collaborations.Get.QueryParameters) =>
    axios<BUCM.CollaborationsResponseApiResponse>({
        url: '/collaborations',
        method: 'GET',
        params: data
    });

export const getCollaborationById = ({ collaborationId }: Paths.Collaborations$CollaborationId.Patch.PathParameters) =>
    axios<BUCM.CollaborationResponseApiResponse>({
        url: `/collaborations/${collaborationId}`,
        method: 'GET'
    });

export const getEnrollments = (
    collaborationId: string,
    data: Paths.Collaborations$CollaborationIdParticipations.Get.QueryParameters
) =>
    axios<BUCM.ParticipationsResponseApiResponse>({
        url: `collaborations/${collaborationId}/participations`,
        method: 'GET',
        params: data
    });

export const getCollaborationsCount = () =>
    axios<BUCM.QueryAdminCollaborationStateCountResponseApiResponse>({
        url: `admin/collaboration/count-by-states`,
        method: 'GET'
    });

export const stopCollaboration = (collaborationId: string) =>
    axios<BUCM.ApiResponse>({
        url: `/collaborations/${collaborationId}/request-stop`
    });

export const kickUserFromCollaboration = ({
    participantId,
    collaborationId,
    data
}: {
    participantId: string;
    collaborationId: string;
    data: BUCM.KickOutParticipantRequest;
}) =>
    axios<BUCM.ApiResponse>({
        url: `admin/participants/${participantId}/collaborations/${collaborationId}`,
        data,
        method: 'DELETE'
    });
