import { capitalizeFirstLetter, convert } from 'utils/usefulFunctions';

export const formatDate = 'dd-MM-y (HH:mm:s)';

export const copyUsernameMessage = 'You successfully copied Username!';
export const copyUserIdMessage = 'You successfully copied User id!';
export const copyRemoteIdMessage = 'You successfully copied Remote user id!';

export const getRole = (role: string) =>
    role === 'organizationmember' ? 'Organization Member' : convert(capitalizeFirstLetter(role));
