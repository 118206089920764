import { Active } from 'types/interfaces/global';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import { device } from 'constants/styles/media';
import { white } from 'constants/styles';

export const ToggleButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    padding: 4px 12px;
    display: none;

    @media ${device.laptop} {
        display: block;
    }
`;

const defaultBarMixin = css`
    display: block;
    position: relative;
    height: 1px;
    border-radius: 1px;
    background: ${white};
    transition: width 0.2s linear;
    animation-fill-mode: forwards;
`;

export const ToggleBar1 = styled.span<Active>`
    @keyframes topbar-x {
        0% {
            top: 0px;
            transform: rotate(0deg);
        }
        45% {
            top: 6px;
            transform: rotate(145deg);
        }
        75% {
            transform: rotate(130deg);
        }
        100% {
            transform: rotate(135deg);
        }
    }
    @keyframes topbar-back {
        0% {
            top: 6px;
            transform: rotate(135deg);
        }
        45% {
            transform: rotate(-10deg);
        }
        75% {
            transform: rotate(5deg);
        }
        100% {
            top: 0px;
            transform: rotate(0);
        }
    }
    ${defaultBarMixin};
    width: 22px;
    top: 0;
    animation: topbar-back 0.5s 0s;
    animation-fill-mode: forwards;

    ${({ active }) =>
        active &&
        css`
            width: 24px;
            top: 6px;
            animation: topbar-x 0.5s 0s;
            animation-fill-mode: forwards;
        `}
`;
export const ToggleBar2 = styled.span<Active>`
    ${defaultBarMixin};
    width: 17px;
    transition: width 0.2s linear;
    margin-top: 7px;

    ${({ active }) =>
        active &&
        css`
            opacity: 0;
        `}
`;
export const ToggleBar3 = styled.span<Active>`
    @keyframes bottombar-x {
        0% {
            bottom: 0px;
            transform: rotate(0deg);
        }
        45% {
            bottom: 6px;
            transform: rotate(-145deg);
        }
        75% {
            transform: rotate(-130deg);
        }
        100% {
            transform: rotate(-135deg);
        }
    }
    @keyframes bottombar-back {
        0% {
            bottom: 6px;
            transform: rotate(-135deg);
        }
        45% {
            transform: rotate(10deg);
        }
        75% {
            transform: rotate(-5deg);
        }
        100% {
            bottom: 0px;
            transform: rotate(0);
        }
    }
    ${defaultBarMixin};
    width: 22px;
    margin-top: 7px;
    bottom: 0;
    animation: bottombar-back 0.5s 0s;
    animation-fill-mode: forwards;

    ${({ active }) =>
        active &&
        css`
            width: 24px;
            bottom: 9px;
            animation: bottombar-x 0.5s 0s;
            animation-fill-mode: forwards;
        `}
`;
