import { FullComponentLoader } from 'components/common/FullComponentLoader';
import { useStore } from 'effector-react';
import { CountTitle } from 'pages/Dashboard/CountTitle';
import { Wrapper } from 'pages/Dashboard/OrganizationsCard/styles';
import { TotalCount } from 'pages/Dashboard/TotalCount';
import React, { useEffect } from 'react';
import { dashboardEffects, dashboardStores } from 'stores/dashboard';

const { getOrganizationsCount } = dashboardEffects;
const { organizationsCount, loadingOrganizations } = dashboardStores;

export const OrganizationsCard = () => {
    const count = useStore(organizationsCount);
    const loading = useStore(loadingOrganizations);

    useEffect(() => {
        getOrganizationsCount();
    }, []);

    return (
        <Wrapper>
            <CountTitle icon="tim-icons icon-molecule-40" title="ORGANIZATIONS" />
            <TotalCount count={count} />
            {loading && <FullComponentLoader />}
        </Wrapper>
    );
};
