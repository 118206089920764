import { CloseIcon } from 'assets/icons';
import { StyledButton } from 'components/common/CloseButton/styles';
import React, { FC } from 'react';
import { BackgroundColor, Color } from 'types';
import { ReactClick } from 'types/interfaces/react';

export interface Props extends ReactClick<HTMLButtonElement>, BackgroundColor, Color {}

export const CloseButton: FC<Props> = ({ onClick, backgroundColor, color }) => (
    <StyledButton backgroundColor={backgroundColor} color={color} onClick={onClick}>
        <CloseIcon />
    </StyledButton>
);
