import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    width: 20px;
    height: 20px;

    &:disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }

    &:hover {
        opacity: 0.7;
    }

    &:active {
        opacity: 0.5;
    }
`;
