import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const SelectWrapper = styled.div`
    width: 100%;
    max-width: 280px;

    @media ${device.mobile} {
        max-width: 100%;
    }
`;

export const ResetButtonWrapper = styled.div`
    @media ${device.mobile} {
        order: -1;
        margin-left: auto;
    }
`;

export const ChildrenWrapper = styled.div`
    min-height: calc(100% - 270px);
`;

export const FilterTitle = styled.h5`
    margin-bottom: 15px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
`;
