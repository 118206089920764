import { defaultOrganizationsValues, errorLoadingMessage } from 'constants/defaults';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { API } from 'services';
import { isUndefined } from 'utils/isUndefined';

type ErrorType = BUCM.MessageErrorResponseBase | string | null;

// Events
const getOrganizationsWithDefaultParams = createEvent();
const updateParams = createEvent<BUCM.QueryOrganizationRequest>();
const setParams = createEvent<BUCM.QueryOrganizationRequest>();
const setError = createEvent<ErrorType>();
const resetError = createEvent();
const loadMore = createEvent();

// Effects
const getOrganizations = createEffect({
    handler: async (params: BUCM.QueryOrganizationRequest) => {
        try {
            const { response, isOk, errorResponse } = await API.organizations.getOrganizations(params);

            if (isOk) {
                return response || {};
            }

            setError(errorResponse?.message || errorLoadingMessage);

            return {};
        } catch {
            setError(errorLoadingMessage);
            return {};
        }
    }
});

interface LoadMoreOrganizationsParams {
    params: BUCM.QueryOrganizationRequest;
    hasNext: boolean;
}

const organizationsLoadMore = createEffect({
    handler: async ({ params: { pageIndex, ...restParams }, hasNext }: LoadMoreOrganizationsParams) => {
        try {
            if (hasNext) {
                const { response, isOk, errorResponse } = await API.organizations.getOrganizations({
                    pageIndex: pageIndex + 1,
                    ...restParams
                });

                if (isOk) {
                    setParams({ pageIndex: pageIndex + 1, ...restParams });
                    return response || {};
                }

                setError(errorResponse?.message || errorLoadingMessage);
            }

            return {};
        } catch {
            setError(errorLoadingMessage);
            return {};
        }
    }
});

// Stores
const organizations = createStore<BUCM.OrganizationsResponse>({} as BUCM.OrganizationsResponse)
    .on(getOrganizations.doneData, (_, newState) => newState)
    .on(organizationsLoadMore.doneData, ({ items }, newState) => ({
        ...newState,
        items: [...(items || []), ...(newState?.items || [])]
    }));

const hasNext = createStore<boolean>(false).on(
    [getOrganizations.doneData, organizationsLoadMore.doneData],
    (_, { currentPageIndex, totalPages }) =>
        !isUndefined(currentPageIndex) && !isUndefined(totalPages) && currentPageIndex + 1 < totalPages
);

const errors = createStore<ErrorType>(null)
    .on(setError, (_, error) => error)
    .reset(resetError);

const params = createStore<BUCM.QueryOrganizationRequest>(defaultOrganizationsValues)
    .on(updateParams, (state, newState) => ({
        ...state,
        ...newState
    }))
    .on(setParams, (_, newState) => newState)
    .reset(getOrganizationsWithDefaultParams);

params.watch(getOrganizationsWithDefaultParams, () => getOrganizations(defaultOrganizationsValues));
params.watch(updateParams, state => getOrganizations(state));

sample({
    source: [params, hasNext],
    clock: loadMore,
    fn: ([params, hasNext]) => ({
        params,
        hasNext
    }),
    target: organizationsLoadMore
});

const organizationsEvents = {
    updateParams,
    getOrganizationsWithDefaultParams,
    loadMore
};

const organizationsStores = { organizations, params, errors, hasNext };
const organizationsEffects = { getOrganizations, organizationsLoadMore };

export { organizationsStores, organizationsEvents, organizationsEffects };
