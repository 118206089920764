import { SearchIcon } from 'assets/icons';
import { Input, InputWrapper } from 'components/form/SearchInput/styles';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { ClickableWrapper } from 'components/wrappers/ClickableWrapper';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Row } from 'components/wrappers/flexWrapper';
import { primaryColor } from 'constants/styles';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Sizes } from 'types';
import { Disabled, Placeholder } from 'types/interface';

interface SearchInputProps extends Sizes, Disabled, Placeholder {
    onSearch: (value: string) => void;
    allowClear?: boolean;
    defaultValue?: string;
    withoutIcon?: boolean;
}

export const SearchInput = ({
    placeholder = 'input search text',
    disabled = false,
    onSearch,
    withoutIcon,
    defaultValue,
    ...rest
}: SearchInputProps) => {
    const [searchValue, setSearchValue] = useState(defaultValue || '');
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') onSearch(searchValue);
    };

    const onSubmit = () => {
        onSearch(searchValue);
    };

    useEffect(() => {
        setSearchValue(defaultValue || '');
    }, [defaultValue]);

    return (
        <InputWrapper {...rest}>
            <Input
                disabled={disabled}
                placeholder={placeholder}
                type="search"
                value={searchValue}
                onChange={onChange}
                onKeyDown={onKeyDown}
            />
            {!withoutIcon && (
                <AbsoluteWrapper right="0" top="0" zIndex="0">
                    <ClickableWrapper onClick={onSubmit}>
                        <ContentWrapper backgroundColor={primaryColor} borderRadius="0 8px 8px 0" width="fit-content">
                            <Row alignCenter justifyCenter height="39px" width="38px">
                                <SearchIcon />
                            </Row>
                        </ContentWrapper>
                    </ClickableWrapper>
                </AbsoluteWrapper>
            )}
        </InputWrapper>
    );
};
