import { Avatar } from 'components/common/Avatar';
import { Span } from 'components/typography/Span';
import { DescriptionProperty } from 'components/common/DescriptionProperty';
import { DescriptionContent } from 'components/common/DescriptionContent';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Column, Section } from 'components/wrappers/flexWrapper';
import { Tag } from 'components/ui/Tag';
import { grey2 } from 'constants/styles/colors';
import { UserDescriptionWrapper } from 'pages/User/styles';
import { getRole } from 'pages/Users/UserCard/constants';
import React, { FC } from 'react';
import { getFormattedDate } from 'utils/formateDate';
import {
    formatDate,
    successfullyCopiedEmail,
    successfullyCopiedOrganizationId,
    successfullyCopiedOrganizationName,
    successfullyCopiedUserId,
    successfullyCopiedUserName
} from './constants';
import { getAge } from 'utils/getAge';
import { GenderState } from 'constants/defaults/users';

export const UserDescription: FC<BUCM.UserResponse> = ({
    userId,
    username,
    organizationId,
    organizationTitle,
    organizationOrigin,
    utcCreated,
    email,
    gender,
    roles,
    dateOfBirth,
    profileImageUrl
    //* do not show verification mark for users w/o remote id
    //isAccountVerified
}) => {
    const age = dateOfBirth && getAge(new Date(dateOfBirth)).toString();

    return (
        <ContentWrapper backgroundColor={grey2} marginBottom="30px" padding="15px 20px" width="100%">
            <UserDescriptionWrapper width="100%">
                <Column>
                    <Avatar src={profileImageUrl || ''} />
                </Column>

                <Column width="100%">
                    <Section gap="16px">
                        <DescriptionProperty
                            copyable={!!username}
                            subtitle={username ? `@${username}` : '-'}
                            successText={successfullyCopiedUserName}
                            title="Username"
                        />

                        <DescriptionProperty subtitle={GenderState[gender ? gender : 0]} title="Gender" />

                        <DescriptionProperty subtitle={age || '-'} title="Age" />

                        <DescriptionProperty
                            copyable={!!userId}
                            subtitle={userId || '-'}
                            successText={successfullyCopiedUserId}
                            title="ID"
                        />
                        <DescriptionProperty
                            subtitle={utcCreated ? getFormattedDate(utcCreated, formatDate) : '-'}
                            successText={successfullyCopiedOrganizationId}
                            title="Created Account"
                        />

                        <DescriptionProperty
                            copyable={!!email}
                            subtitle={email || '-'}
                            successText={successfullyCopiedEmail}
                            title="Email"
                        />

                        <DescriptionProperty
                            copyable={!!organizationId}
                            subtitle={organizationId || '-'}
                            successText={successfullyCopiedOrganizationId}
                            title="Organization ID"
                        />
                        <DescriptionProperty
                            copyable={!!organizationTitle}
                            subtitle={organizationTitle || '-'}
                            successText={successfullyCopiedOrganizationName}
                            title="Organization name"
                        />
                    </Section>

                    <Section gap="16px" marginTop="24px">
                        {roles && roles.length > 0 && (
                            <DescriptionContent title="ROLES">
                                {roles.map(role => (
                                    <Tag key={role}>{getRole(role)}</Tag>
                                ))}
                            </DescriptionContent>
                        )}

                        <DescriptionContent title="ORIGIN">
                            {organizationOrigin ? <Tag>{organizationOrigin}</Tag> : <Span lineHeight="27px">-</Span>}
                        </DescriptionContent>
                    </Section>
                </Column>
            </UserDescriptionWrapper>
        </ContentWrapper>
    );
};
