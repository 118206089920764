import { bullzIcon, instagramIcon, tiktokIcon, twitchIcon, twitterIcon, WomIcon, youtubeIcon } from 'assets/icons';
import { CopyButton } from 'components/common/CopyButton';
import { CustomImg } from 'components/ui/CustomImg';
import { Row } from 'components/wrappers/flexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { ExternalNetworkKey } from 'constants/defaults/collaborations';
import React, { FC } from 'react';
import { formatId } from 'utils/formatId';
import { copyNetworkIdMessage } from './constants';
import { CountWrapper, NetworkInfo, NetworkSubtitle, Wrapper } from './styles';

interface Props {
    wom?: number | string;
    network: string;
    networkId?: string;
    showMode?: 'id' | 'wom';
}

interface IconProps {
    network: string;
}

const Icon: FC<IconProps> = ({ network }) => {
    switch (network) {
        case ExternalNetworkKey.Bullz:
            return <CustomImg alt="Bullz" height="24px" src={bullzIcon} width="24px" />;
        case ExternalNetworkKey.Instagram:
            return <CustomImg alt="Instagram" height="24px" src={instagramIcon} width="24px" />;
        case ExternalNetworkKey.Twitter:
            return <CustomImg alt="Twitter" height="24px" src={twitterIcon} width="24px" />;
        case ExternalNetworkKey.Tiktok:
            return <CustomImg alt="Tiktok" height="24px" src={tiktokIcon} width="24px" />;
        case ExternalNetworkKey.YouTube:
            return <CustomImg alt="YouTube" height="24px" src={youtubeIcon} width="fit-content" />;
        case ExternalNetworkKey.Twitch:
            return <CustomImg alt="Twitch" height="24px" src={twitchIcon} width="24px" />;
        default:
            return null;
    }
};

export const RewardItem: FC<Props> = ({ wom, network, networkId, showMode = 'wom' }) => {
    if (showMode === 'id') {
        return (
            <NetworkInfo>
                <MarginWrapper marginRight="16px">
                    <Icon network={network} />
                </MarginWrapper>
                <MarginWrapper marginRight="16px">
                    <NetworkSubtitle>{formatId(networkId)}</NetworkSubtitle>
                </MarginWrapper>
                <CopyButton subject={networkId} successText={copyNetworkIdMessage} />
            </NetworkInfo>
        );
    }

    return (
        <Wrapper>
            <Row alignCenter noWrap>
                <Icon network={network} />
                <MarginWrapper marginRight="6px">
                    <CountWrapper>{wom}</CountWrapper>
                </MarginWrapper>
                <WomIcon />
            </Row>
        </Wrapper>
    );
};
