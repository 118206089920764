// link paths
export const initialLink = '/';
export const authLink = '/login';
export const dashboardLink = '/dashboard';
export const organizationsLink = '/organizations';
export const organizationLink = '/organizations/:id';
export const collaborationsLink = '/collaborations';
export const collaborationLink = '/collaborations/:id';

export const usersLink = '/users';
export const userLink = usersLink + '/:id';

// link names
export const authLinkName = 'Log out';
export const dashboardLinkName = 'Dashboard';
export const organizationsLinkName = 'Organizations';
export const collaborationsLinkName = 'Collaborations';
export const organizationLinkName = 'Single organizations';
export const collaborationLinkName = 'Single collaborations';
export const usersLinkName = 'Users';
export const userLinkName = 'Single Users';

export type RouteStateType =
    | 'organizationsNested'
    | 'collaborationsNested'
    | 'videosNested'
    | 'usersNested'
    | 'performanceNested'
    | 'walletNested'
    | 'withdrawalCheckNested';

export type SidebarStatesType = {
    [key in RouteStateType]?: boolean;
};

export interface RouteInterface {
    nest?: boolean;
    path: string;
    name: string;
    title: string;
    icon?: string;
    state?: RouteStateType;
    view?: RouteInterface;
}

export type RoutesType = RouteInterface[];

export const newRoutesArray: RoutesType = [
    {
        path: dashboardLink,
        name: dashboardLinkName,
        title: dashboardLinkName,
        icon: 'tim-icons icon-chart-pie-36',
        view: {
            path: dashboardLink,
            name: dashboardLinkName,
            title: dashboardLinkName,
            icon: 'tim-icons icon-notes'
        }
    },
    {
        path: organizationsLink,
        name: organizationsLinkName,
        title: organizationsLinkName,
        icon: 'tim-icons icon-molecule-40',
        nest: true,
        state: 'organizationsNested',
        view: {
            path: organizationLink,
            name: organizationLinkName,
            title: organizationLinkName,
            icon: 'tim-icons icon-notes'
        }
    },
    {
        path: usersLink,
        name: usersLinkName,
        title: usersLinkName,
        icon: 'tim-icons icon-single-02',
        nest: true,
        state: 'usersNested',
        view: {
            path: userLink,
            name: userLinkName,
            title: userLinkName,
            icon: 'tim-icons icon-notes'
        }
    },
    {
        path: collaborationsLink,
        name: collaborationsLinkName,
        title: collaborationsLinkName,
        icon: 'tim-icons icon-puzzle-10',
        state: 'collaborationsNested',
        nest: true,
        view: {
            path: collaborationLink,
            name: collaborationLinkName,
            title: collaborationLinkName,
            icon: 'tim-icons icon-notes'
        }
    }
];
