import { CreatorApprovalIcon, VideoApprovalIcon } from 'assets/icons';
import { Row } from 'components/wrappers/flexWrapper/styles';
import { StatusIconWrapper } from 'pages/Collaborations/CollaborationCard/styles';
import React, { FC } from 'react';

import { StatusTooltip } from '../StatusTooltip';

interface Props {
    autoApproveParticipants?: boolean;
    autoApproveMedia?: boolean;
}

export const ApproveStatusCollaboration: FC<Props> = ({ autoApproveParticipants, autoApproveMedia }) => (
    <Row>
        {autoApproveMedia && !autoApproveParticipants && (
            <StatusTooltip overlay="Brand set verified creator's approval" placement="bottomLeft">
                <StatusIconWrapper>
                    <CreatorApprovalIcon height="38px" width="38px" />
                </StatusIconWrapper>
            </StatusTooltip>
        )}

        {!autoApproveMedia && autoApproveParticipants && (
            <StatusTooltip overlay="Brand set all creator's content approval" placement="bottomLeft">
                <StatusIconWrapper>
                    <VideoApprovalIcon height="38px" width="38px" />
                </StatusIconWrapper>
            </StatusTooltip>
        )}
    </Row>
);
