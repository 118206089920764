import { Switch } from 'components/form/Switch';
import { LeftSide, Wrapper } from 'pages/Dashboard/SocialSwitcher/styles';
import React, { FC } from 'react';
import { NoopType } from 'types';

interface Props {
    icon: any;
    id: string;
    onChange: NoopType;
    hasSwitcher?: boolean;
    disabled?: boolean;
    checked: boolean;
    title: string;
}

export const SocialSwitcher: FC<Props> = ({
    icon: Icon,
    onChange,
    id,
    hasSwitcher = true,
    disabled = false,
    checked = false,
    title
}) => (
    <Wrapper>
        <LeftSide>{Icon ? <Icon /> : <span>{title}</span>}</LeftSide>
        {hasSwitcher && <Switch checked={checked} disabled={disabled} id={id} onChange={onChange} />}
    </Wrapper>
);
