import { Span } from 'components/typography/Span';
import { flexStart, grey4, primaryColor } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const TagsWrapper = styled.div`
    ${flexStart};
    max-width: 385px;
    height: 40px;
    overflow-y: hidden;
    overflow-x: auto;

    margin-bottom: 10px;

    ::-webkit-scrollbar {
        display: block;
        width: 24px;
        height: 8px;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${grey4};
        border-radius: 10px;
    }

    scrollbar-color: ${grey4} transparent;
    scrollbar-width: auto;

    @media ${device.mobile} {
        max-width: 320px;
    }
`;

export const Username = styled(Span)`
    font-size: 17px;
`;

export const UserEmail = styled(Span)`
    font-size: 14px;
    color: ${primaryColor};
`;
