import { Img, ImgWrapper } from 'components/ui/CustomImg/styles';
import { ImgWrapperProps } from 'components/ui/CustomImg/types';
import React from 'react';
import { ImgProperties } from 'types';

interface Props extends ImgProperties, ImgWrapperProps {}

export const CustomImg = ({ src, alt, ...rest }: Props) => (
    <ImgWrapper {...rest}>
        <Img alt={alt} src={src} />
    </ImgWrapper>
);
