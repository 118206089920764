export const primaryColor = '#1D8CF8';
export const secondaryColor = '#C4C4C4';

export const black = '#111111';
export const lightBlack = '#060c07';
export const white = '#ffffff';
export const grey = '#A0A0A0';
export const grey1 = '#9a9a9a';
export const grey2 = '#262626';
export const grey3 = '#D2D2D5';
export const grey4 = '#4f4f4f';
export const grey5 = '#3C3C3C';
export const grey6 = '#C4C4C4';
export const grey7 = '#E5E5E5';
export const grey8 = '#8A949D';
export const darkGrey3 = '#1c1c1c';
export const red = '#FC4237';
export const pink = '#FFDCDA';
export const green = '#33FF9D';

export const errorColor = 'rgb(211, 47, 47)';
export const blue = '#1D8CF8';
export const lightBlue = '#77BCFF';
export const blue2 = '#344675';
export const tableHeaderColor = 'rgba(160, 160, 160, 0.8)';
export const tableBorderColor = 'rgba(227, 227, 227, 0.1)';
export const linkHoverColor = 'rgba(71, 71, 71, 0.4)';
export const sortButtonHoverColor = 'rgba(196, 196, 196, 0.3)';

export const transparent = 'transparent';
