import React, { FC, ReactNode } from 'react';
import { TabsButtons, TabsExtra, TabsPanelWrapper, TabsWrapper } from './styles';

interface Props {
    extra?: ReactNode;
    children: ReactNode;
}

export const Tabs: FC<Props> = ({ children, extra }) => (
    <TabsWrapper>
        <TabsButtons>{children}</TabsButtons>
        {extra && <TabsExtra>{extra}</TabsExtra>}
    </TabsWrapper>
);

interface TabsPanelProps {
    tabKey: number;
    value: number;
    children: ReactNode;
}

export const TabPanel: FC<TabsPanelProps> = ({ children, value, tabKey }) => (
    <TabsPanelWrapper role="tabpanel">{value === tabKey && children}</TabsPanelWrapper>
);
