import { Loader } from 'components/common/Loader';
import { InternalCopyLink } from 'components/common/InternalCopyLink';
import { Table } from 'components/ui/Table';
import { Span } from 'components/typography/Span';
import { Section } from 'components/wrappers/flexWrapper';
import { Empty } from 'components/grid/Empty';
import { collaborationStatuses } from 'constants/defaults/collaborations';
import { errorMessage } from 'constants/defaults/organizations';
import { collaborationsLink } from 'constants/routes';
import { HiddenScrollBlock, primaryColor } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { useMemo } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router';
import { organizationEvents, organizationStores } from 'stores/singleOrganization';
import { Id } from 'types/interfaces/data';
import { getEllipsisAddress, parseCalendarDate } from 'utils/usefulFunctions';
import { columns, emptyMessage } from './constants';
import { ImageWrapper, StatusSpan } from './styles';

const { collaborations, collaborationsLoading, collaborationsLoadMoreLoading, collaborationsErrors } =
    organizationStores;
const { loadMore } = organizationEvents;

export const CollaborationsTable = () => {
    const { id } = useParams<Id>();
    const { items, hasNext } = useStore(collaborations);
    const loading = useStore(collaborationsLoading);
    const loadingMore = useStore(collaborationsLoadMoreLoading);
    const responseCollaborationsErrors = useStore(collaborationsErrors);

    const [loadRef] = useInfiniteScroll({
        loading: loadingMore,
        hasNextPage: !!hasNext,
        onLoadMore: () => loadMore(id),
        disabled: responseCollaborationsErrors !== null,
        rootMargin: '0px'
    });

    const tableData = useMemo(() => {
        if (!items || items.length === 0) return [];

        return items.map(item => ({
            image: <ImageWrapper src={item.coverImageUrl ? item.coverImageUrl : ''} />,
            name: <Span fontSize="14px">{item.name || '-'}</Span>,
            status: item.state ? (
                <StatusSpan status={item.state}>{collaborationStatuses[item.state]}</StatusSpan>
            ) : (
                <Span fontSize="14px">-</Span>
            ),
            collaborationId: item.id ? (
                <Section alignCenter justifyCenter>
                    <InternalCopyLink
                        href={`${collaborationsLink}/${item.id}`}
                        subject={item.id}
                        successText="You successfully copied collaboration id"
                    >
                        <Span fontSize="14px">{getEllipsisAddress(item.id, 11, 'left')}</Span>
                    </InternalCopyLink>
                </Section>
            ) : (
                '-'
            ),
            utcStart: item.utcStart ? <Span fontSize="14px">{parseCalendarDate(new Date(item.utcStart))}</Span> : '-',
            utcEnd: (
                <Span fontSize="14px">
                    {item.utcEnd ? parseCalendarDate(new Date(item.utcEnd)) : 'Submissions limit'}
                </Span>
            ),
            budgetAccepted: (
                <Span color={primaryColor} fontSize="14px">
                    {item.budgetAccepted || '-'}
                </Span>
            ),
            id: item.id
        }));
    }, [items]);

    const emptyDescription = responseCollaborationsErrors !== null ? errorMessage : emptyMessage;

    if (loading) {
        return (
            <Section justifyCenter height="280px">
                <Loader />
            </Section>
        );
    }

    if (tableData.length === 0 || responseCollaborationsErrors !== null) {
        return <Empty description={emptyDescription} />;
    }

    return (
        <>
            <Table columns={columns} data={tableData} emptyLayoutMessage={emptyMessage} rowKey={record => record.id} />
            <HiddenScrollBlock ref={loadRef} />
            {loadingMore && <Loader />}
        </>
    );
};
