import { TableHeadInterface } from 'types';

export const emptyMessage = 'There are no denied approval yet';

export const columns: TableHeadInterface[] = [
    {
        title: 'AVATAR',
        dataIndex: 'avatar',
        key: 'avatar'
    },
    {
        title: 'USER',
        dataIndex: 'username',
        key: 'username'
    },
    {
        title: 'SOCIAL NETWORKS',
        dataIndex: 'socialNetworks',
        key: 'socialNetworks'
    },
    {
        title: 'USER PARTICIPATION STATUS',
        dataIndex: 'participationState',
        key: 'participationState'
    }
];
