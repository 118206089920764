import { errorLoadingMessage } from 'constants/defaults/common';
import { defaultUserValues } from 'constants/defaults/users';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

type ErrorType = BUCM.MessageErrorResponseBase | string | null;

// Events

const resetSingleUser = createEvent();
const setError = createEvent<ErrorType>();
const resetError = createEvent();

// Effects

const getUsersById = createEffect({
    handler: async (userId: string) => {
        try {
            const { response, isOk, errorResponse } = await API.users.getUsers({
                ...defaultUserValues,
                userIds: [userId]
            });

            if (isOk && response?.items) {
                resetError();
                return response.items[0];
            }

            setError(errorResponse || errorLoadingMessage);
            return null;
        } catch {
            setError(errorLoadingMessage);
            return null;
        }
    }
});

// Stores

const userInfo = createStore<BUCM.UserResponse | null>(null)
    .on(getUsersById.doneData, (_, newState) => newState)
    .reset(resetSingleUser);

const errors = createStore<ErrorType>(null)
    .on(setError, (_, newState) => newState)
    .reset([resetError, resetSingleUser]);

// Exports

const singleUserEvents = {
    resetSingleUser
};

const singleUserEffects = {
    getUsersById
};

const singleUserStores = {
    userInfo,
    errors
};

export { singleUserEvents, singleUserEffects, singleUserStores };
