import { error } from 'components/modals/Message';
import { storageName } from 'constants/global';
import { errorDataMessage, errorNotEntryAllowed } from 'constants/notifications';
import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { loadingEffects } from 'stores/loading';
import { Auth } from 'types';
import { giveAccess, objectIsEmpty } from 'utils/usefulFunctions';

const logout = createEvent();
const setAuth = createEvent<Auth>();
const setUser = createEvent<BUCM.UserJwtTokenResponse>();

const loadToken = createEffect({
    handler: async (values: BUCM.OrganizationUserAuthChallengeRequest) => {
        try {
            loadingEffects.updateLoading();
            const { response } = await API.user.authenticateUser(values);
            loadingEffects.updateLoading();

            return response;
        } catch {
            loadingEffects.updateLoading();
            return {};
        }
    }
});

const user = createStore<BUCM.UserJwtTokenResponse>(JSON.parse(localStorage.getItem(storageName) || '{}'))
    .on([loadToken.doneData, setUser], (_, newState) => {
        newState && giveAccess(newState) !== false && localStorage.setItem(storageName, JSON.stringify(newState));
        return newState;
    })
    .on(logout, () => {
        localStorage.removeItem(storageName);
        return {};
    });

const prepareAuthState = (store: BUCM.UserJwtTokenResponse): Auth => {
    const stateIsEmpty = objectIsEmpty(store);
    const hasAccess = !stateIsEmpty && giveAccess(store);
    const authDenyReason = stateIsEmpty ? errorDataMessage : hasAccess ? '' : errorNotEntryAllowed;

    return {
        hasAccess,
        authDenyReason
    };
};

user.watch(state => {
    setAuth(prepareAuthState(state));
});

const userStore = user.getState();
const auth = createStore<Auth>(prepareAuthState(userStore)).on(setAuth, (_, auth) => auth);

auth.watch(setAuth, ({ authDenyReason }) => authDenyReason && error(authDenyReason));

const userEvents = { logout, setUser };
const userEffects = { loadToken };
const userStores = { user, auth };

export { userEffects, userStores, userEvents };
