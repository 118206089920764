import { createEvent, createStore } from 'effector';

const updateInitialLoading = createEvent();
const setInitialLoading = createEvent<boolean>();

const initialLoading = createStore<boolean>(false)
    .on(updateInitialLoading, state => !state)
    .on(setInitialLoading, (_, state) => state);

const updateLoading = createEvent();
const setLoading = createEvent<boolean>();

const loading = createStore<boolean>(false)
    .on(updateLoading, state => !state)
    .on(setLoading, (_, state) => state);

const loadingEffects = { updateLoading, updateInitialLoading, setLoading, setInitialLoading };
const loadingStores = { loading, initialLoading };

export { loadingStores, loadingEffects };
