import bullzLogo from 'assets/img/bullz_logo_square.png';
import { Loader } from 'components/common/Loader';
import { Span } from 'components/typography/Span';
import { Table } from 'components/ui/Table';
import { Section } from 'components/wrappers/flexWrapper';
import { errorMessage } from 'constants/defaults/organizations';
import { HiddenScrollBlock } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router';
import { Id } from 'types/interfaces/data';
import { columns, emptyMessage } from './constants';
import { Empty } from 'components/grid/Empty';
import { ParticipationEndedReason } from 'constants/defaults/enrollments';
import {
    collaborationApproveVideoEffects,
    collaborationApproveVideoEvents,
    collaborationApproveVideoStores
} from 'stores/collaborationApproveVideo';
import { Avatar } from 'components/common/Avatar';
import { externalNetworksStores } from 'stores/externalNetworks';
import { NetworkIconWrapper } from '../styles';
import { findNetwork } from '../constants';
import { SocialIcon } from 'components/common/SocialIcon';

const { getApproveVideoFx } = collaborationApproveVideoEffects;
const { $approveVideo, $approveVideoLoading, $errors, $approveVideoLoadMoreLoading } = collaborationApproveVideoStores;
const { loadMore } = collaborationApproveVideoEvents;

export const DeniedApproval = () => {
    const { id } = useParams<Id>();
    const { items, hasNext } = useStore($approveVideo);
    const responseCollaborationsErrors = useStore($errors);
    const loading = useStore($approveVideoLoading);
    const loadingMore = useStore($approveVideoLoadMoreLoading);
    const externalNetworks = useStore(externalNetworksStores.externalNetworks);

    const approveVideoItems =
        items &&
        items.length > 0 &&
        items.filter(item => item.participationEndedReason === ParticipationEndedReason.ParticipationRequestRejected);

    const [loadRef] = useInfiniteScroll({
        loading: loadingMore,
        hasNextPage: !!hasNext,
        onLoadMore: () => loadMore(id),
        disabled: responseCollaborationsErrors !== null,
        rootMargin: '0px'
    });

    useEffect(() => {
        if (id) {
            getApproveVideoFx({ collaborationId: id });
        }
    }, [id]);

    const tableData = useMemo(() => {
        if (!approveVideoItems || approveVideoItems.length === 0) return [];

        return approveVideoItems.map(({ creatorProfile, id }) => ({
            avatar: <Avatar src={creatorProfile?.profileImageUrl || bullzLogo} />,
            username: <Span fontSize="14px">{creatorProfile?.userName ? `@${creatorProfile.userName}` : '-'}</Span>,
            socialNetworks: (
                <Section justifyCenter noWrap>
                    {creatorProfile?.socialReferences
                        ? creatorProfile.socialReferences.map(item => (
                              <NetworkIconWrapper key={item.networkId}>
                                  <SocialIcon network={findNetwork(externalNetworks, item.networkId)} />
                              </NetworkIconWrapper>
                          ))
                        : '-'}
                </Section>
            ),
            participationState: <Span fontSize="14px">Denied</Span>,
            id
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [approveVideoItems]);

    const emptyDescription = responseCollaborationsErrors !== null ? errorMessage : emptyMessage;

    if (loading) {
        return (
            <Section justifyCenter>
                <Loader />
            </Section>
        );
    }

    if (tableData.length === 0 || responseCollaborationsErrors !== null) {
        return <Empty description={emptyDescription} />;
    }

    return (
        <>
            <Table columns={columns} data={tableData} emptyLayoutMessage={emptyMessage} rowKey={record => record.id} />
            <HiddenScrollBlock ref={loadRef} />
            {loadingMore && <Loader />}
        </>
    );
};
