import { toast } from 'react-toastify';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import { errorMessage } from 'constants/defaults';
import { defaultLoadingInfo, defaultSubmissionsParams, ExternalNetworkKey } from 'constants/defaults/collaborations';
import { API } from 'services';
import { externalNetworksStores } from 'stores/externalNetworks';
import { getNetworkIdByName } from 'utils/externalNetworks';
import { errorFeedbackAccepted, errorFeedbackFetching } from 'constants/notifications';

const { externalNetworks } = externalNetworksStores;

// Events

const loadMore = createEvent<string>();
const setErrors = createEvent<BUCM.ErrorResponse | string | null>();
const setReport = createEvent<string>();

const openFeedbackModal = createEvent<string>();
const closeFeedbackModal = createEvent();

// Effects

interface SubmissionsParams {
    collaborationId: string;
    externalNetworks: BUCM.ExternalNetworkResponse[];
}

const createEffectSubmissions = createEffect(async ({ externalNetworks, collaborationId }: SubmissionsParams) => {
    try {
        const networkId = getNetworkIdByName(externalNetworks, ExternalNetworkKey.Bullz);
        if (!networkId) return;

        const { isSuccess, data } = await API.mediaAssets.getSubmissions(collaborationId, {
            ...defaultSubmissionsParams,
            NetworkId: networkId
        });

        if (isSuccess) {
            setErrors(null);
            return data;
        }

        setErrors(errorMessage);
        return;
    } catch (error) {
        setErrors(errorMessage);
        console.error(error);
    }
});

const getSubmissions = attach({
    source: externalNetworks,
    mapParams: (collaborationId: string, externalNetworks) => ({ collaborationId, externalNetworks }),
    effect: createEffectSubmissions
});

interface LoadMoreSubmissionsParams {
    collaborationId: string;
    state: BUCM.MediaAssetsResponse;
}

const createLoadMoreSubmissions = createEffect(
    async (
        externalNetworksStore: BUCM.ExternalNetworkResponse[],
        { collaborationId, state }: LoadMoreSubmissionsParams
    ) => {
        try {
            const networkId = getNetworkIdByName(externalNetworksStore, ExternalNetworkKey.Bullz);
            if (!networkId) return;

            const { isSuccess, data } = await API.mediaAssets.getSubmissions(collaborationId, {
                ...defaultSubmissionsParams,
                NetworkId: networkId,
                pageIndex: state.pageIndex !== undefined ? state.pageIndex + 1 : defaultSubmissionsParams.pageIndex
            });

            if (isSuccess) {
                setErrors(null);
                return data;
            }

            setErrors(errorMessage);
            return;
        } catch (error) {
            setErrors(errorMessage);
            console.error(error);
        }
    }
);

const loadMoreSubmissions = attach({
    source: externalNetworks,
    effect: createLoadMoreSubmissions
});

const getSubmissionFeedback = createEffect(async (submissionId: string) => {
    try {
        const { isSuccess, data } = await API.mediaAssets.getFeedback(submissionId);

        if (isSuccess && data?.items && data.items?.length > 0) {
            return data.items[0];
        }

        toast.error(errorFeedbackFetching);
        return null;
    } catch (error) {
        toast.error(errorFeedbackFetching);
        return null;
    }
});

interface sendAcceptFeedbackParams {
    submissionId: string;
    feedbackId: string;
    isAccepted: boolean;
}

const sendAcceptFeedback = createEffect(async ({ submissionId, feedbackId, isAccepted }: sendAcceptFeedbackParams) => {
    try {
        closeFeedbackModal();

        const { isSuccess } = await API.mediaAssets.acceptFeedback(submissionId, feedbackId, { isAccepted });

        if (isSuccess) {
            toast.success(`Report ${isAccepted ? 'accepted' : 'rejected'}`);
        } else {
            toast.error(errorFeedbackAccepted);
        }
    } catch (error) {
        toast.error(errorFeedbackAccepted);
    }
});

// Stores

const submissions = createStore<BUCM.MediaAssetsResponse>({})
    .on(getSubmissions.doneData, (_, newState) => newState)
    .on(loadMoreSubmissions.doneData, ({ items }, newState) => ({
        ...newState,
        items: [...(items || []), ...(newState?.items || [])]
    }))
    .on(setReport, (state, submissionId) => ({
        ...state,
        items: state.items?.map(item => {
            if (item.id === submissionId) {
                item.hasFeedback = true;
            }
            return item;
        })
    }));

sample({
    source: submissions,
    clock: loadMore,
    fn: (submissions, collaborationId) => ({ collaborationId, state: submissions }),
    target: loadMoreSubmissions
});

const submissionsLoading = createStore(defaultLoadingInfo)
    .on(getSubmissions.done, state => ({
        ...state,
        isLoaded: true
    }))
    .on([getSubmissions.pending, loadMoreSubmissions.pending], (state, result) => ({
        ...state,
        isLoading: result
    }));

const errors = createStore<BUCM.ErrorResponse | string | null>(null).on(setErrors, (_, newState) => newState);

const submissionFeedback = createStore<BUCM.MediaAssetFeedbackResponse | null>(null)
    .on(getSubmissionFeedback.doneData, (_, payload) => payload)
    .reset(closeFeedbackModal);

sample({
    clock: openFeedbackModal,
    target: getSubmissionFeedback
});

// Exports

const submissionsEvents = {
    loadMore,
    setReport,
    openFeedbackModal,
    closeFeedbackModal
};

const submissionsEffects = {
    getSubmissions,
    getSubmissionFeedback,
    sendAcceptFeedback
};

const submissionsStores = {
    submissions,
    submissionsLoading,
    errors,
    submissionFeedback
};

export { submissionsStores, submissionsEffects, submissionsEvents };
