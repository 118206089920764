import { black, ellipsisMixin, secondaryColor, white } from 'constants/styles';
import styled from 'styled-components';
import { BackgroundColor, Color, MarginBottom, MinSizes, Sizes } from 'types';

export interface PropertyBlockProps extends Sizes, MinSizes, BackgroundColor, MarginBottom {}

export const BlockWrapper = styled.div<PropertyBlockProps>`
    ${({ width }) => width && `width: ${width}`};
    min-height: ${({ minHeight }) => minHeight || '56px'};
    ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};
    padding: 4px 15px 6px;
    border-radius: 8px;
    background-color: ${({ backgroundColor }) => backgroundColor || black};
`;
export const BlockTitle = styled.span`
    font-size: 12px;
    line-height: 18px;
    color: ${secondaryColor};
    ${ellipsisMixin};
`;

interface BlockSubTitleProps extends Color {
    isIdType?: boolean;
}

export const BlockSubTitle = styled.span<BlockSubTitleProps>`
    font-size: 14px;
    line-height: 21px;
    color: ${({ color }) => color || white};
    min-width: 94px;
    ${ellipsisMixin};
    ${({ isIdType }) => isIdType && `direction: rtl`};
    white-space: nowrap;
`;
