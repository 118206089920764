import { Row } from 'components/wrappers/flexWrapper';
import { grey2 } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    padding: 30px 24px;
    background-color: ${grey2};
    border-radius: 8px;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @media ${device.tablet} {
        padding: 15px 12px;
    }
`;

export const Header = styled(Row)`
    justify-content: space-between;
    align-items: flex-end;
`;

export const Divider = styled.div`
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 16px;
    margin-bottom: 30px;
`;
