import { grey, lightBlack, scrollbar, tableBorderColor, transitionTime } from 'constants/styles';
import { device } from 'constants/styles/media';
import Table from 'rc-table';
import styled, { css } from 'styled-components';

export const tableTextStyles = css`
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
`;

interface Props {
    rowPointer?: boolean;
    isEmpty?: boolean;
}

export const StyledTable = styled(Table)<Props>`
    width: 100%;
    transition: background-color ${transitionTime};

    .rc-table-container,
    .rc-table-content {
        width: 100%;
        overflow-x: auto;

        table {
            width: 100%;
            border-collapse: collapse;
        }

        .rc-table-thead {
            height: 40px;
            ${tableTextStyles};
            text-transform: uppercase;
            color: ${grey};
            border-bottom: 1px solid ${tableBorderColor};

            @media screen and (max-width: ${device.mobile}px) {
                ${({ isEmpty }) => isEmpty && 'display:none'};
            }

            .rc-table-cell {
                text-align: center;
                padding-left: 24px;
                padding-right: 24px;
                height: 40px;
                white-space: pre;
            }
        }

        .rc-table-content {
            overflow-x: auto;
        }

        .rc-table-row {
            width: 100%;
            height: 74px;
            border-bottom: 1px solid ${tableBorderColor};
            ${({ rowPointer }) => rowPointer && 'cursor: pointer'};

            .rc-table-cell {
                ${tableTextStyles};
                text-align: center;
                padding-left: 24px;
                padding-right: 24px;
                height: inherit;
                white-space: pre;
            }

            &:hover {
                background-color: ${lightBlack};
            }
        }
        ${scrollbar};
    }
`;

export const EmptyWrapper = styled.div`
    margin-top: 80px;
    margin-bottom: 80px;

    @media ${device.laptop} {
        margin-top: 60px;
        margin-bottom: 60px;
    }
`;
