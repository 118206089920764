import { Span } from 'components/typography/Span';
import { CollaborationEnrollmentStatusesUi } from 'constants/defaults/collaborations';
import { grey2, pink, red, white } from 'constants/styles';
import styled, { css } from 'styled-components';

interface ImageWrapperProps {
    borderRadius?: string;
    src?: string;
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
    width: 42px;
    height: 42px;
    border-radius: ${({ borderRadius }) => borderRadius || '6px'};
    overflow: hidden;
    background: ${({ src }) => (src ? `url("${src}")` : grey2)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
`;

export const getStatusSpanStyle = (status: BUCM.ParticipationState) => {
    switch (status) {
        case CollaborationEnrollmentStatusesUi.Ended:
            return css`
                color: ${red};
            `;
        default:
            return css`
                color: ${white};
            `;
    }
};

interface StatusSpanProps {
    status: BUCM.ParticipationState;
}

export const StatusSpan = styled(Span)<StatusSpanProps>`
    font-size: 14px;
    text-transform: uppercase;
    ${({ status }) => status && getStatusSpanStyle(status)};
`;

export const TrashWrapper = styled.span`
    color: ${red};
    cursor: pointer;

    &:hover {
        color: ${pink};
    }
`;
