import { errorLoadingMessage } from 'constants/defaults/common';
import { defaultUsersValues } from 'constants/defaults/users';
import { createEffect, createEvent, createStore, forward, sample } from 'effector';
import { API } from 'services';
import { isUndefined } from 'utils/isUndefined';

type ErrorType = BUCM.MessageErrorResponseBase | string | null;

// Events

const getByDefaultParams = createEvent();
const setParams = createEvent<BUCM.UserQueryRequest>();
const updateParams = createEvent<BUCM.UserQueryRequest>();
const setHasNextIsTrue = createEvent();
const resetHasNext = createEvent();
const setError = createEvent<ErrorType>();
const resetError = createEvent();
const loadMore = createEvent();
const resetUsers = createEvent();

// Effects

const getUsers = createEffect({
    handler: async (params: BUCM.UserQueryRequest) => {
        try {
            const { response, isOk, errorResponse } = await API.users.getUsers(params);

            if (isOk) {
                return response;
            }

            setError(errorResponse || errorLoadingMessage);
            return {};
        } catch {
            setError(errorLoadingMessage);
            return {};
        }
    }
});

interface LoadMoreUsersParams {
    state: BUCM.QueryAdminUsersResponse;
    params: BUCM.UserQueryRequest;
}

const usersLoadMore = createEffect({
    handler: async ({ state, params }: LoadMoreUsersParams) => {
        try {
            const { response, isOk, errorResponse } = await API.users.getUsers({
                ...params,
                pageIndex: !isUndefined(state.currentPageIndex) ? state.currentPageIndex + 1 : 0
            });

            if (isOk) {
                return response;
            }

            setError(errorResponse || errorLoadingMessage);
            return {};
        } catch {
            setError(errorLoadingMessage);
            return {};
        }
    }
});

// Stores

const users = createStore<BUCM.QueryAdminUsersResponse>({})
    .on(getUsers.doneData, (_, newState) => newState)
    .on(usersLoadMore.doneData, ({ items }, newState) => ({
        ...newState,
        items: [...(items || []), ...(newState?.items || [])]
    }))
    .reset(resetUsers);

const errors = createStore<ErrorType>(null)
    .on(setError, (_, error) => error)
    .reset(resetError);

const params = createStore<BUCM.UserQueryRequest>(defaultUsersValues)
    .on(updateParams, (state, newState) => ({
        ...state,
        ...newState
    }))
    .on(setParams, (_, newState) => newState)
    .reset(getByDefaultParams);

params.watch(getByDefaultParams, state => getUsers(state));
forward({
    from: params,
    to: getUsers
});

const hasNext = createStore(false)
    .on(setHasNextIsTrue, () => true)
    .reset(resetHasNext);

users.watch(({ totalPages, currentPageIndex }) => {
    if (!isUndefined(totalPages) && !isUndefined(currentPageIndex) && totalPages > currentPageIndex + 1) {
        setHasNextIsTrue();
    } else {
        resetHasNext();
    }
});

sample({
    source: [users, params],
    clock: loadMore,
    fn: ([users, params]) => ({
        state: users,
        params
    }),
    target: usersLoadMore
});

// Exports

const usersEvents = {
    getByDefaultParams,
    updateParams,
    loadMore,
    resetUsers,
    setParams
};

const usersEffects = {
    getUsers,
    usersLoadMore
};

const usersStores = {
    users,
    params,
    hasNext,
    errors
};

export { usersEvents, usersEffects, usersStores };
