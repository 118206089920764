import { Row } from 'components/wrappers/flexWrapper';
import { ellipsisMixin, propertyBlockStyleMixin, scrollbar } from 'constants/styles';
import { white } from 'constants/styles/colors';
import styled from 'styled-components';
import { FlexBasis, FlexGrow, MarginRight } from 'types';

interface Props extends FlexGrow {
    isMobile?: boolean;
}

export const ScrollableContent = styled.div<Props>`
    overflow-x: scroll;
    width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
    flex-grow: ${({ flexGrow }) => flexGrow || '2'};
    ${scrollbar};
`;

export const BlockRowWrapper = styled(Row)<FlexBasis>`
    min-width: fit-content;
    ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis};`};
    ${propertyBlockStyleMixin};
`;

export const BlockRowTitle = styled.span`
    font-size: 12px;
    line-height: 18px;
    color: ${white};
    white-space: noWrap;
    margin-right: 16px;
    opacity: 0.8;
`;

export const BlockRowSubtitle = styled.div<MarginRight>`
    font-size: 14px;
    line-height: 21px;
    color: ${white};
    margin-right: ${({ marginRight }) => marginRight || '0'};
    ${ellipsisMixin};
`;
