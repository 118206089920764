import { errorColor, white } from 'constants/styles';
import styled from 'styled-components';

interface Props {
    expired?: boolean;
}

export const Span = styled.span<Props>`
    color: ${({ expired }) => (expired ? errorColor : white)};
`;
