import { white } from 'constants/styles';
import styled from 'styled-components';

export const HamburgerWrapper = styled.span`
    margin-left: 25px;
    color: ${white};
    display: none;

    .tim-icons.icon-bullet-list-67 {
        font-size: 15px;
    }

    @media (min-width: 1200px) {
        display: block;
    }
`;
