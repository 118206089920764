import { Count, Title, Wrapper } from 'pages/Dashboard/TotalCount/styles';
import React, { FC } from 'react';

interface Props {
    count: number;
}

export const TotalCount: FC<Props> = ({ count }) => (
    <Wrapper>
        <Title>total</Title>
        <Count>{count}</Count>
    </Wrapper>
);
