import { CloseButton } from 'components/common/CloseButton';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper/styles';
import { grey7, grey } from 'constants/styles/colors';
import { useNonScrolledBackground } from 'hooks/useNonScrolledBackground';
import React, { FC, ReactNode } from 'react';
import { Sizes, Visibility } from 'types/interfaces';
import { NoopType } from 'types/types';
import { ModalBackground, ModalContentWrapper } from './styles';
import { useMediaQuery } from 'react-responsive';
import { xs } from 'constants/styles';

interface Props extends Visibility, Sizes {
    onClose?: NoopType;
    children: ReactNode;
}

export const ModalWrapper: FC<Props> = ({ children, visible, onClose, width, height }) => {
    useNonScrolledBackground(visible);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    if (!visible) return null;

    return (
        <>
            <ModalBackground onClick={onClose} />
            <ModalContentWrapper height={height} width={width}>
                <AbsoluteWrapper right={isMobile ? '6px' : '16px'} top={isMobile ? '6px' : '16px'} zIndex="100">
                    <CloseButton backgroundColor={grey7} color={grey} onClick={onClose} />
                </AbsoluteWrapper>

                {children}
            </ModalContentWrapper>
        </>
    );
};
