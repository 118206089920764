import { CheckMarkIcon, CrossedEyeIcon, EyeIcon } from 'assets/icons';
import { AbsoluteWrapper } from 'components/wrappers/AbsoluteWrapper';
import { Row, Section } from 'components/wrappers/flexWrapper';
import { debounceTime } from 'constants/global';
import { useField } from 'formik';
import useDebouncedCallback from 'hooks/useDebouncedCallback';
import { useToggle } from 'hooks/useToggle';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DefaultValueString, Disabled, Label, Placeholder, Type, UntouchedWarning } from 'types/interfaces/form';
import { MarginBottom } from 'types/interfaces/styles';
import { requiredFieldMessage } from './constants';
import { IconWrapper, InputPrefix, InputWrapper, Message, StyledInput, StyledLabel } from './styles';

export interface OffAutoComplete {
    offAutoComplete?: boolean;
}

export interface FormInput extends Type, Disabled, Label, Placeholder, DefaultValueString, UntouchedWarning {
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

interface Props extends MarginBottom, OffAutoComplete, FormInput {
    messageHeight?: string;
    defaultValue?: string;
    debounce?: boolean;
    prefix?: string;
    name: string;
}

export const Input = ({
    defaultValue,
    placeholder,
    messageHeight,
    label,
    disabled,
    type = 'text',
    name,
    onChange,
    untouchedWarning,
    marginBottom,
    offAutoComplete,
    debounce = false,
    prefix
}: Props) => {
    const [field, { error, touched }, { setValue }] = useField(name);

    const [inputType, setInputType] = useState(type);
    const [innerValue, setInnerValue] = useState('');
    const [isShowPassword, setShowPassword] = useToggle();

    const onInputChange = onChange || field.onChange;
    const autoComplete = type === 'password' || offAutoComplete ? 'off' : undefined;
    const isPasswordInput = type === 'password';
    const isPreFilled = !!defaultValue && (name === 'inviteCode' || name === 'confirmationToken');

    const errorMessage = !field.value ? requiredFieldMessage : error ? error : undefined;
    const showError = touched && !!errorMessage;
    const isValid = touched && !errorMessage;

    useEffect(() => {
        setInnerValue(field.value || '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => setInputType(type), [type]);

    useEffect(() => {
        if (defaultValue) {
            setInnerValue(defaultValue);
            setValue(defaultValue);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const debouncedHandleOnChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
        onInputChange(event);
    }, debounceTime);

    const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            event.persist();

            setInnerValue(event.target.value);
            debounce ? debouncedHandleOnChange(event) : onInputChange(event);
        },
        [debounce, debouncedHandleOnChange, onInputChange]
    );

    const onChangePasswordView = () => {
        setInputType(isShowPassword ? 'password' : 'text');
        setShowPassword();
    };

    return (
        <InputWrapper marginBottom={marginBottom}>
            <StyledLabel>{label}</StyledLabel>

            <AbsoluteWrapper right="15px" top="44px">
                <Row alignCenter justifyEnd>
                    {isPasswordInput && (
                        <IconWrapper onClick={onChangePasswordView}>
                            {isShowPassword ? <EyeIcon /> : <CrossedEyeIcon />}
                        </IconWrapper>
                    )}
                    {isValid && (
                        <Row alignCenter marginLeft="10px">
                            <CheckMarkIcon />
                        </Row>
                    )}
                </Row>
            </AbsoluteWrapper>

            <Section noWrap>
                {prefix && <InputPrefix error={showError}>{prefix}</InputPrefix>}
                <StyledInput
                    disabled={isPreFilled || disabled}
                    error={showError}
                    isPasswordInput={isPasswordInput}
                    {...field}
                    autoComplete={autoComplete}
                    placeholder={placeholder}
                    type={inputType}
                    value={innerValue}
                    onChange={handleOnChange}
                />
            </Section>

            <Section marginTop="6px" minHeight={messageHeight || '18px'}>
                {(untouchedWarning || showError) && (
                    <Message error={showError}>{showError ? errorMessage : untouchedWarning}</Message>
                )}
            </Section>
        </InputWrapper>
    );
};
