import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const MainLayoutWrapper = styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    top: 0;
    height: 100vh;
`;

interface Props {
    isSidebarTranslated: boolean;
    isSidebarMini: boolean;
}

export const Content = styled.div<Props>`
    padding: 80px 30px 30px 280px;
    ${({ isSidebarMini }) => !isSidebarMini && 'padding-left: 130px'};
    min-height: calc(100vh - 70px);

    @media ${device.laptop} {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media (max-width: 600px) {
        padding-top: 122px;
    }

    @media ${device.tablet} {
        padding-left: 15px;
        padding-right: 15px;
    }
`;

export const MainPanel = styled.div<Props>`
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    ${({ isSidebarTranslated }) => isSidebarTranslated && 'transform: translate3d(260px,0,0)'};
`;
