import { sortButtonHoverColor, grey2, transitionTime, white } from 'constants/styles';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 18px;
    white-space: noWrap;
    padding: 10px;
    color: ${white};
    background-color: ${grey2};
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    transition: background-color ${transitionTime};

    @media (max-width: 320px) {
        padding: 10px 15px;
    }

    &:hover,
    &:focus-visible {
        background-color: ${sortButtonHoverColor};
    }
`;
