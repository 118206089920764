import styled from 'styled-components';
import { flexCenter, white } from 'constants/styles';
import { Column } from 'components/wrappers/flexWrapper';
import { device } from 'constants/styles/media';

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${flexCenter};
    background-color: rgba(38, 38, 38, 0.7);
    z-index: 1001;
    min-height: 100vh;
    overflow-y: scroll;
`;

export const ContentWrapper = styled(Column)`
    width: 100%;
    max-width: 400px;
    padding: 24px 60px;
    flex-wrap: nowrap;
    background-color: ${white};
    border-radius: 8px;
    position: relative;

    @media ${device.mobile} {
        padding-left: 12px;
        padding-right: 12px;
    }
`;
