import { defaultCollaborationsParams, errorMessage } from 'constants/defaults/organizations';
import { createEffect, createEvent, createStore, forward, sample } from 'effector';
import { API } from 'services';

// Events

const loadMore = createEvent<string>();
const resetOrganization = createEvent();
const setCollaborationsErrors = createEvent<BUCM.ErrorResponse | string | null>();
const resetCollaborationsErrors = createEvent();
const updateCollaborationsParams = createEvent<Paths.Collaborations.Get.QueryParameters>();
const setCollaborationsParams = createEvent<Paths.Collaborations.Get.QueryParameters>();

// Effects

const getOrganization = createEffect({
    handler: async ({ organizationId }: BUCM.GetOrganizationRequest) => {
        try {
            const { isOk, response } = await API.organizations.getOrganizationById({ organizationId });

            if (isOk) {
                return response;
            }

            return null;
        } catch {
            return null;
        }
    }
});

const getCollaborationsByOrganizationId = createEffect({
    handler: async (params: Paths.Collaborations.Get.QueryParameters) => {
        try {
            const { isSuccess, data, error } = await API.collaborations.getCollaborations({
                ...defaultCollaborationsParams,
                ...params
            });

            if (isSuccess) {
                resetCollaborationsErrors();
                return data;
            }

            setCollaborationsErrors(error || errorMessage);
            return {};
        } catch {
            setCollaborationsErrors(errorMessage);
            return {};
        }
    }
});

interface LoadMoreCollaborationsParams {
    organizationId: string;
    state: BUCM.CollaborationsResponse;
    params: Paths.Collaborations.Get.QueryParameters;
}

const loadMoreCollaborations = createEffect({
    handler: async ({ organizationId, state, params }: LoadMoreCollaborationsParams) => {
        try {
            const { isSuccess, data, error } = await API.collaborations.getCollaborations({
                ...defaultCollaborationsParams,
                ...params,
                pageIndex: state.pageIndex !== undefined ? state.pageIndex + 1 : defaultCollaborationsParams.pageIndex,
                organizationId
            });

            if (isSuccess) {
                resetCollaborationsErrors();
                return data;
            }

            setCollaborationsErrors(error || errorMessage);
            return {};
        } catch {
            setCollaborationsErrors(errorMessage);
            return {};
        }
    }
});

// Stores

const organization = createStore<BUCM.OrganizationResponse | null>(null)
    .on(getOrganization.doneData, (_, newState) => newState)
    .reset(resetOrganization);

const collaborations = createStore<BUCM.CollaborationsResponse>({})
    .on(getCollaborationsByOrganizationId.doneData, (_, newState) => newState)
    .on(loadMoreCollaborations.doneData, ({ items, itemsCount }, newState) => ({
        ...newState,
        items: [...(items || []), ...(newState?.items || [])],
        itemsCount: (itemsCount || 0) + (newState?.itemsCount || 0)
    }))
    .reset(resetOrganization);

const collaborationsParams = createStore<Paths.Collaborations.Get.QueryParameters>(defaultCollaborationsParams)
    .on(updateCollaborationsParams, (state, newState) => ({
        ...state,
        ...newState
    }))
    .on(setCollaborationsParams, (_, newState) => ({
        ...defaultCollaborationsParams,
        ...newState
    }))
    .reset(resetOrganization);

forward({
    from: [collaborationsParams],
    to: [getCollaborationsByOrganizationId]
});

sample({
    source: [collaborations, collaborationsParams],
    clock: loadMore,
    fn: ([collaborations, collaborationsParams], organizationId) => ({
        organizationId,
        state: collaborations,
        params: collaborationsParams
    }),
    target: loadMoreCollaborations
});

const collaborationsLoading = createStore<boolean>(false).on(
    getCollaborationsByOrganizationId.pending,
    (_, payload) => payload
);

const collaborationsLoadMoreLoading = createStore<boolean>(false).on(
    loadMoreCollaborations.pending,
    (_, payload) => payload
);

const collaborationsErrors = createStore<BUCM.ErrorResponse | string | null>(null)
    .on(setCollaborationsErrors, (_, newState) => newState)
    .reset([resetOrganization, resetCollaborationsErrors]);

// Exports

const organizationEvents = {
    resetOrganization,
    loadMore,
    updateCollaborationsParams,
    setCollaborationsParams
};

const organizationEffects = {
    getOrganization,
    getCollaborationsByOrganizationId
};

const organizationStores = {
    organization,
    collaborations,
    collaborationsLoading,
    collaborationsLoadMoreLoading,
    collaborationsErrors,
    collaborationsParams
};

export { organizationEffects, organizationStores, organizationEvents };
