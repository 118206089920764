import { CustomImg } from 'components/ui/CustomImg';
import { ExternalNetworkKey } from 'constants/defaults/collaborations';
import React, { FC } from 'react';

import { bullzIcon, instagramIcon, tiktokIcon, twitchIcon, twitterIcon, youtubeIcon } from 'assets/icons';

export interface IconProps {
    network: string | null;
}

export const SocialIcon: FC<IconProps> = ({ network }) => {
    switch (network?.toLowerCase().trim()) {
        case ExternalNetworkKey.Bullz:
            return <CustomImg alt="Bullz" height="20px" src={bullzIcon} width="20px" />;
        case ExternalNetworkKey.Instagram:
            return <CustomImg alt="Instagram" height="20px" src={instagramIcon} width="20px" />;
        case ExternalNetworkKey.Twitter:
            return <CustomImg alt="Twitter" height="20px" src={twitterIcon} width="20px" />;
        case ExternalNetworkKey.Tiktok:
            return <CustomImg alt="Tiktok" height="20px" src={tiktokIcon} width="20px" />;
        case ExternalNetworkKey.YouTube:
            return <CustomImg alt="YouTube" height="20px" src={youtubeIcon} width="fit-content" />;
        case ExternalNetworkKey.Twitch:
            return <CustomImg alt="Twitch" height="20px" src={twitchIcon} width="20px" />;
        default:
            return null;
    }
};
