import { RoutesType } from 'constants/routes';
import { matchPath } from 'react-router';

//TODO: add tests
export const findElementInChildrenList = (targetElement: Element, searchElement: EventTarget | null) => {
    let isInParentBlock = false;
    const checkChildrenRef = (el: Element) => {
        if (el.childElementCount === 0) return;
        else
            el.childNodes.forEach((el: any) => {
                if (searchElement === el) isInParentBlock = true;
                checkChildrenRef(el);
            });
    };

    checkChildrenRef(targetElement);
    return isInParentBlock;
};

// TRIGGER COPY TO CLIPBOARD
export const triggerCopy: (text: string) => void = text => navigator.clipboard.writeText(text);

// give access a user if user has role "administrator"
export const giveAccess = (user: BUCM.UserJwtTokenResponse) =>
    user?.user?.roles?.reduce((_, role) => role.toLowerCase() === 'administrator', false) || false;

export const addZero = (date: number, n = 2) => date.toString().padStart(n, '0');

// PARSE CALENDAR DATE
export const parseCalendarDate: (date: Date) => string = date =>
    date.toString() === 'Invalid Date'
        ? 'invalid date'
        : addZero(date.getDate()) +
          '.' +
          addZero(date.getMonth() + 1) +
          '.' +
          date.getFullYear() +
          ' (' +
          addZero(date.getHours()) +
          ':' +
          addZero(date.getMinutes()) +
          ':' +
          addZero(date.getSeconds()) +
          ')';

// IMITATING ASYNC REQUEST
export const wait = (ms: number) => new Promise(res => setTimeout(res, ms));

export const objectIsEmpty = (obj: object) => !Object.values(obj).length;

export const getEllipsisAddress = (str: string, quantity = 12, position: 'left' | 'right' = 'left') => {
    if (str.length < quantity) return str;

    if (position === 'right') {
        return str.substring(0, quantity) + '...';
    }

    return '...' + str.substring(str.length - quantity, str.length);
};

//TODO: Adding test
export const isScrollLessThanValue = (element: HTMLDivElement, value: number): boolean => {
    if (!document.scrollingElement) return false;
    return (
        document.documentElement.scrollTop > value ||
        document.scrollingElement.scrollTop > value ||
        element.scrollTop > value
    );
};

//TODO: Adding test
export const clearScrollPosition = (element: HTMLDivElement) => {
    document.documentElement.scrollTop = 0;

    if (!document.scrollingElement) return;
    document.scrollingElement.scrollTop = 0;

    element.scrollTop = 0;
};

//TODO: Adding test
export const getActiveRouteName = (routes: RoutesType): string | undefined => {
    let activeRoute = '';
    for (let i = 0; i < routes.length; i++) {
        const { path, title, nest, view } = routes[i];

        const isCurrentActive = matchPath(window.location.pathname, {
            path: path,
            exact: true,
            strict: false
        })?.isExact;

        const isNestActive = view
            ? matchPath(window.location.pathname, {
                  path: view.path,
                  exact: true,
                  strict: false
              })?.isExact
            : false;

        if (nest && view && isNestActive) {
            let nestActiveRoute = getActiveRouteName([view]);
            if (nestActiveRoute !== activeRoute) {
                return nestActiveRoute;
            }
        } else {
            if (path && isCurrentActive) {
                return title;
            }
        }
    }
    return activeRoute;
};

export const convert = (text: string) => text.match(/[A-Z][a-z]+|[0-9]+/g)?.join(' ');

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export function calculateBlockWidth(blocksQuantity: number, margin: number) {
    const marginWidthSum = (blocksQuantity - 1) * margin;
    return `calc((100% - ${marginWidthSum}px)/${blocksQuantity})`;
}

export const hasNotValidToken = (tokenExpired: number) => tokenExpired * 1000 < new Date().getTime();
