import { Row } from 'components/wrappers/flexWrapper';
import { blue, disableDefaultButtonStyleMixin, ellipsisMixin, flexCenter, grey2, white } from 'constants/styles';
import styled from 'styled-components';
import { Visibility } from 'types/interface';

export const DropdownWrapper = styled.div<Visibility>`
    position: absolute;
    right: -3px;
    top: 30px;
    display: ${({ visible }) => (visible ? 'flex' : 'none')};
    justify-content: center;
    flex-direction: column;
    max-width: 150px;
    padding: 10px;
    border-radius: 4px;
    background-color: ${white};

    @media (max-width: 992px) {
        top: 35px;
    }

    :before {
        display: inline-block;
        position: absolute;
        left: auto;
        right: 17px;
        top: -5px;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: '';

        color: ${white};
        border-bottom: 0.4em solid;
        border-right: 0.4em solid transparent;
        border-left: 0.4em solid transparent;
    }
`;

export const Label = styled.span`
    font-size: 10px;
    line-height: 15px;
    color: ${grey2};
    margin-bottom: 6px;
`;

export const VersionInfo = styled.span`
    font-size: 8px;
    line-height: 12px;
    color: #2b3553;
    white-space: nowrap;
    ${ellipsisMixin};
`;

export const StyledHorizontalLine = styled.hr`
    width: 100%;
    margin-top: 7px;
    margin-bottom: 10px;
    border: 1px solid #f3f3f3;
`;

export const UpdatesCounter = styled.div`
    font-size: 7px;
    line-height: 8px;
    height: 12px;
    width: 13px;
    color: ${white};
    border-radius: 50%;
    background-color: ${blue};
    ${flexCenter};
`;
export const Arrow = styled.div`
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid white;
    transition: border-color 0.3s;
`;

export const DropdownToggle = styled.button`
    ${disableDefaultButtonStyleMixin};
    padding-right: 15px;
    padding-left: 15px;
    color: ${white};
    transition: color 0.3s;

    &:hover {
        ${Arrow} {
            border-top-color: ${blue};
        }

        color: ${blue};
    }

    @media (max-width: 992px) {
        padding-right: 18px;
        padding-bottom: 4px;
    }
`;

export const UserInfo = styled.span`
    display: inline-block;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: ${white};
    text-transform: uppercase;
`;

export const LeftSideNavBarWrapper = styled(Row)`
    align-items: center;
    gap: 45px;

    @media (max-width: 600px) {
        flex-direction: column-reverse;
        gap: 10px;
        align-items: flex-start;
    }
`;

export const StyledButton = styled.button`
    ${disableDefaultButtonStyleMixin};
    padding: 7px 8px;
    font-size: 10px;
    line-height: 15px;
    color: rgb(154, 154, 154);
    text-align: left;
`;

export const NavbarWrapper = styled.nav`
    padding: 18px 30px;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 990;

    @media (max-width: 600px) {
        padding: 10px 15px;
    }
`;
