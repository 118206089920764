import React, { FC } from 'react';
import { NoopType } from 'types';
import { IsSidebarOpened } from 'types/interfaces/global';
import { ToggleBar1, ToggleBar2, ToggleBar3, ToggleButton } from './styles';

interface Props extends IsSidebarOpened {
    toggleSidebar: NoopType;
}

export const MobileHamburger: FC<Props> = ({ isSidebarOpened, toggleSidebar }) => (
    <ToggleButton onClick={toggleSidebar}>
        <ToggleBar1 active={isSidebarOpened} />
        <ToggleBar2 active={isSidebarOpened} />
        <ToggleBar3 active={isSidebarOpened} />
    </ToggleButton>
);
