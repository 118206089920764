import { SubmissionGrid } from 'components/common/SubmissionGrid/styles';
import { Empty } from 'components/grid/Empty';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { errorMessage } from 'constants/defaults';
import { HiddenScrollBlock } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router';
import { externalNetworksEffects, externalNetworksStores } from 'stores/externalNetworks';
import { sharedSubmissionsEffects, sharedSubmissionsEvents, sharedSubmissionsStores } from 'stores/sharedSubmissions';
import { Id } from 'types/interfaces/data';
import { SubmissionCard } from '../SubmissionCard';
import { SubmissionsLoading } from '../SubmissionsLoading';
import { emptyMessage } from './constants';

const { getSharedSubmissions } = sharedSubmissionsEffects;
const { submissions, submissionsLoading, errors } = sharedSubmissionsStores;
const { loadMore } = sharedSubmissionsEvents;
const { externalNetworks } = externalNetworksStores;
const { getExternalNetworks } = externalNetworksEffects;

export const SharedTab = () => {
    const { id } = useParams<Id>();
    const { items, hasNext } = useStore(submissions);
    const { isLoading, isLoaded } = useStore(submissionsLoading);
    const responseErrors = useStore(errors);
    const extNetworks = useStore(externalNetworks);

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: !!hasNext,
        onLoadMore: () => loadMore(id),
        disabled: responseErrors !== null || extNetworks.length === 0,
        rootMargin: '0px'
    });

    useEffect(() => {
        if (id) {
            getExternalNetworks();
        }
    }, [id]);

    useEffect(() => {
        if (id && extNetworks.length > 0) {
            getSharedSubmissions(id);
        }
    }, [id, extNetworks]);

    if (!isLoaded && isLoading) {
        return (
            <SubmissionGrid>
                <SubmissionsLoading />
            </SubmissionGrid>
        );
    }

    if (!hasNext && items?.length === 0) {
        return <Empty description={emptyMessage} />;
    }

    if (responseErrors !== null) {
        return <Empty description={errorMessage} />;
    }

    return (
        <MarginWrapper>
            {items && items.length > 0 && (
                <SubmissionGrid>
                    {items.map(video => (
                        <SubmissionCard key={video.id} isPlayable={false} video={video} />
                    ))}

                    {isLoading && <SubmissionsLoading />}
                </SubmissionGrid>
            )}

            <HiddenScrollBlock ref={loadRef} />
        </MarginWrapper>
    );
};
