import { validateLogInForm } from './validation';

export const title = 'Authorization';
export const formName = 'basic';

export const nameMessage = 'Please input your name!';
export const passwordLabel = 'Password';
export const passwordName = 'password';
export const passwordMessage = 'Please input your password!';

export const buttonText = 'Sign in';

export const logInInitialProps = {
    validateOnChange: true,
    initialValues: {
        email: '',
        password: ''
    },
    validate: validateLogInForm
};
