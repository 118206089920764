import RcTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { TooltipProps } from 'rc-tooltip/lib/Tooltip';
import React, { FC } from 'react';
import { GlobalStyle } from './styles';

export const StatusTooltip: FC<TooltipProps> = ({ children, overlay, ...tooltipProps }) => (
    <>
        <RcTooltip destroyTooltipOnHide overlay={overlay} overlayClassName="custom-tooltip" {...tooltipProps}>
            {children}
        </RcTooltip>
        <GlobalStyle />
    </>
);
