import { Row, Section } from 'components/wrappers/flexWrapper';
import { black, flexCenter, grey2, secondaryColor, white } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const Title = styled.h4`
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    color: ${white};
    margin-bottom: 15px;
`;

export const CollaborationsStatesWrapper = styled(Section)`
    border-radius: 8px;
    background-color: ${grey2};
    margin-bottom: 30px;
    padding: 14px;
    width: 100%;

    @media ${device.small} {
        justify-content: space-between;
        margin-bottom: 22px;
    }
`;

export const BlockWrapper = styled(Row)`
    align-items: center;
    flex-wrap: nowrap;
    width: fit-content;
    max-width: 148px;
    height: 58px;
    margin-bottom: 16px;
    margin-right: 16px;
    padding: 20px 15px;
    border-radius: 8px;
    background-color: ${black};

    @media ${device.small} {
        margin: 8px 2px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding: 6px 12px;
        width: 124px;
    }
`;
export const BlockTitle = styled.span`
    font-size: 12px;
    line-height: 18px;
    color: ${secondaryColor};
    white-space: nowrap;
`;

export const BlockSubTitle = styled.span`
    font-size: 14px;
    line-height: 21px;
    color: ${white};
`;

export const LoaderWrapper = styled.div`
    ${flexCenter};
    height: 200px;
`;
