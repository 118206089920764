import { Column } from 'components/wrappers/flexWrapper';
import { IconWrapper, Title } from 'pages/Dashboard/CountTitle/styles';
import React, { FC } from 'react';

interface Props {
    title: string;
    icon: string;
}

export const CountTitle: FC<Props> = ({ title, icon }) => (
    <Column gap="15px">
        <IconWrapper>
            <i className={icon}></i>
        </IconWrapper>
        <Title>{title}</Title>
    </Column>
);
