import { Tag } from 'components/ui/Tag';
import { usersLink } from 'constants/routes';
import { Span, TagsWrapper, Title } from 'pages/Organizations/ScrollableTags/styles';
import React, { FC } from 'react';

interface Props {
    data?: string[] | null;
    title: string;
}

export const ScrollableTags: FC<Props> = ({ data, title }) => (
    <>
        <Title>{title}</Title>
        <TagsWrapper>
            {data?.length ? (
                data?.map(tag => (
                    <Tag key={tag} link={`${usersLink}/${tag}`}>
                        {tag}
                    </Tag>
                ))
            ) : (
                <Span>No content</Span>
            )}
        </TagsWrapper>
    </>
);
