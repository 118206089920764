import { flexCenter, black } from 'constants/styles';
import styled from 'styled-components';

export const AuthLayout = styled.div`
    width: 100%;
    height: 100%;
    ${flexCenter};
    flex-direction: column;
    background-color: ${black};
`;
