import { FormWrapper } from './styles';
import { noop } from 'constants/functions';
import React, { FC, PropsWithChildren } from 'react';
export interface OffAutoComplete {
    offAutoComplete?: boolean;
}

interface FormProps extends OffAutoComplete, PropsWithChildren {
    onSubmit?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    onChange?: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
}

export const Form: FC<FormProps> = ({ children, onSubmit = noop, offAutoComplete, onChange }) => (
    <FormWrapper autoComplete={offAutoComplete ? 'off' : undefined} onChange={onChange} onSubmit={onSubmit}>
        {children}
    </FormWrapper>
);
