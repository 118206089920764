import { disableDefaultButtonStyleMixin, white, blue, lightBlue, red, pink, grey8 } from 'constants/styles';
import styled, { css } from 'styled-components';
import { Disabled, NoWrap, TextProperties } from 'types';

const buttonBase = () => css`
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 18px;
    padding: 11px 24px;
    color: ${white};
    border-radius: 6px;
    transition: color 0.3s, background-color 0.3s;
`;

export const blueButton = css`
    background-color: ${blue};

    &:hover:not(:disabled),
    &:focus-visible:not(:disabled) {
        background-color: ${lightBlue};
    }
`;

export const redButton = css`
    background-color: ${red};

    &:hover:not(:disabled),
    &:focus-visible:not(:disabled) {
        color: ${red};
        background-color: ${pink};
    }
`;

export const pinkButton = css`
    color: ${red};
    background-color: ${pink};

    &:hover:not(:disabled),
    &:focus-visible:not(:disabled) {
        color: ${pink};
        background-color: ${red};
    }
`;

export const getTypeStyle = (colorStyle: string) => {
    switch (colorStyle) {
        case 'blue':
            return blueButton;
        case 'red':
            return redButton;
        case 'pink':
            return pinkButton;
        default:
            return blueButton;
    }
};

export interface ButtonProps extends Disabled, NoWrap, Pick<TextProperties, 'uppercase'> {
    buttonType?: 'blue' | 'red' | 'pink';
    wide?: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
    ${disableDefaultButtonStyleMixin};
    ${buttonBase()};
    ${({ buttonType }) => buttonType && getTypeStyle(buttonType)};
    ${({ wide }) => wide && 'width: 100%'};
    ${({ noWrap }) => noWrap && 'white-space: nowrap'};

    &:disabled {
        cursor: not-allowed;
        background-color: ${grey8};
    }
`;
