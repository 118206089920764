import { CustomImg } from 'components/ui/CustomImg';
import {
    LogoInner,
    LogoWrapper,
    SidebarWrapper,
    StyledNavLink,
    TitleWrapper,
    NavList,
    ListItem,
    NestedNavList
} from 'components/grid/Sidebar/styles';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { RoutesType } from 'constants/routes';
import React, { FC, useRef } from 'react';
import { matchPath } from 'react-router';
import { NavLink, useLocation } from 'react-router-dom';
import { NoopType } from 'types';
import { bullzLogo } from 'assets/icons';

interface Props {
    routes: RoutesType;
    isSidebarMini: boolean;
    isSidebarTranslated: boolean;
    closeSidebar: NoopType;
}

export const Sidebar: FC<Props> = ({ routes, closeSidebar, isSidebarMini, isSidebarTranslated }) => {
    const sidebarRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const createLinks = (routes: RoutesType, isNested?: boolean) =>
        routes.map(({ view, state, nest, icon, name, path }) => {
            const isCurrentActivePath = matchPath(location.pathname, {
                path,
                exact: true,
                strict: false
            })?.isExact;

            if (nest && view && state) {
                const isCurrentView = matchPath(location.pathname, {
                    path: view.path,
                    exact: true,
                    strict: false
                })?.isExact;
                if (isCurrentView) {
                    return (
                        <ListItem key={name} isSidebarMini={isSidebarMini} isSidebarTranslated={isSidebarTranslated}>
                            <StyledNavLink to={path} onClick={closeSidebar}>
                                <TitleWrapper active={!isCurrentView}>
                                    <i className={icon} />
                                    <p>{name}</p>
                                </TitleWrapper>
                            </StyledNavLink>
                            <NestedNavList>{createLinks([view], true)}</NestedNavList>
                        </ListItem>
                    );
                }
            }

            return (
                <ListItem
                    key={name}
                    active={isCurrentActivePath}
                    isSidebarMini={isSidebarMini}
                    isSidebarTranslated={isSidebarTranslated}
                >
                    {isNested ? (
                        <a href="#empty" onClick={e => e.preventDefault()}>
                            <MarginWrapper marginLeft="3px">
                                <i className={icon} />
                            </MarginWrapper>
                            <p>{name}</p>
                        </a>
                    ) : (
                        <NavLink to={path} onClick={closeSidebar}>
                            <i className={icon} />
                            <p>{name}</p>
                        </NavLink>
                    )}
                </ListItem>
            );
        });

    return (
        <SidebarWrapper ref={sidebarRef} isSidebarMini={isSidebarMini} isSidebarTranslated={isSidebarTranslated}>
            <LogoWrapper>
                <NavLink to="/" onClick={closeSidebar}>
                    <LogoInner>
                        <CustomImg alt="BULLZ logo" height="22px" src={bullzLogo} width="42px" />
                    </LogoInner>
                </NavLink>
            </LogoWrapper>
            <NavList>{createLinks(routes)}</NavList>
        </SidebarWrapper>
    );
};
