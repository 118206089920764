import {
    bullzIcon,
    instagramIcon,
    tiktokIcon,
    twitterIcon,
    youtubeIcon,
    twitchIcon,
    BullzIcon,
    InstagramIcon,
    TiktokIcon,
    TwitterIcon,
    YoutubeIcon,
    TwitchIcon
} from 'assets/icons';

export enum NetworkName {
    Bullz = 'BULLZ',
    TikTok = 'TikTok',
    Instagram = 'Instagram',
    Twitter = 'Twitter',
    YouTube = 'YouTube',
    Twitch = 'Twitch'
}

export type Network = NetworkName.TikTok | NetworkName.Instagram | NetworkName.Twitter | NetworkName.YouTube;

export const networkImgIcon = {
    [NetworkName.Bullz]: bullzIcon,
    [NetworkName.TikTok]: tiktokIcon,
    [NetworkName.Instagram]: instagramIcon,
    [NetworkName.Twitter]: twitterIcon,
    [NetworkName.YouTube]: youtubeIcon,
    [NetworkName.Twitch]: twitchIcon
};

export const networkIcon = {
    [NetworkName.Bullz]: BullzIcon,
    [NetworkName.Instagram]: InstagramIcon,
    [NetworkName.TikTok]: TiktokIcon,
    [NetworkName.Twitter]: TwitterIcon,
    [NetworkName.YouTube]: YoutubeIcon,
    [NetworkName.Twitch]: TwitchIcon
};
