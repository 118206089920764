import { Row } from 'components/wrappers/flexWrapper';
import { linkHoverColor } from 'constants/styles/colors';
import styled from 'styled-components';

export const LinkWrapper = styled(Row)`
    width: fit-content;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    border-radius: 4px;
    padding: 10px;
    line-height: 21px;

    &:hover {
        background-color: ${linkHoverColor};
    }
`;
