import { CollaborationStatusesUi } from 'constants/defaults';
import { green, grey, primaryColor, red, white } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled, { css } from 'styled-components';

export const CollaborationName = styled.span`
    font-size: 17px;
    line-height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: noWrap;
    color: ${white};

    @media ${device.desktop} {
        max-width: 290px;
    }

    @media ${device.tablet} {
        max-width: 250px;
    }
    @media ${device.mobile} {
        max-width: 300px;
    }

    @media ${device.small} {
        max-width: 200px;
    }
`;

export const Subtitle = styled.span`
    font-size: 13px;
    line-height: 20px;
    color: ${white};
`;

interface CollaborationStatusProps {
    status: BUCM.CollaborationState;
}

export const getStatusColor = (status: BUCM.CollaborationState) => {
    switch (status) {
        case CollaborationStatusesUi.Draft:
            return css`
                color: ${primaryColor};
            `;
        case CollaborationStatusesUi.StartingSoon:
            return css`
                color: ${primaryColor};
            `;
        case CollaborationStatusesUi.Active:
            return css`
                color: ${green};
            `;
        case CollaborationStatusesUi.ActiveClosed:
            return css`
                color: ${green};
            `;
        case CollaborationStatusesUi.Ended:
            return css`
                color: ${red};
            `;
        case CollaborationStatusesUi.Ending:
            return css`
                color: '#ff6a00';
            `;
        case CollaborationStatusesUi.Deleted:
            return css`
                color: ${red};
            `;
        default:
            return css`
                color: ${grey};
            `;
    }
};

export const CollaborationStatus = styled(CollaborationName)<CollaborationStatusProps>`
    text-transform: uppercase;
    ${({ status }) => getStatusColor(status)};
`;

export const StatusIconWrapper = styled.div`
    cursor: pointer;
`;
