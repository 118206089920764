import React, { FC, PropsWithChildren } from 'react';
import { ClassName, HTMLButtonType } from 'types';
import { ReactClick } from 'types/interfaces/react';
import { ButtonProps, StyledButton } from './styles';

export interface Props
    extends ButtonProps,
        ReactClick<HTMLButtonElement>,
        HTMLButtonType,
        PropsWithChildren,
        ClassName {}

export const Button: FC<Props> = ({ buttonType = 'blue', children, ...props }) => (
    <StyledButton buttonType={buttonType} {...props}>
        {children}
    </StyledButton>
);
