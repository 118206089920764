import { device } from 'constants/styles/media';
import styled from 'styled-components';
import { calculateBlockWidth } from 'utils/usefulFunctions';

export const CardTitle = styled.h4`
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 20px;
`;

export const CollaborationsDataWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 16px;

    @media ${device.small} {
        grid-template-columns: repeat(3, ${calculateBlockWidth(3, 16)});
    }
`;
