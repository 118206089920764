import styled from 'styled-components';
import { ToastContainer, toast, CloseButtonProps } from 'react-toastify';
import { blue, white, black, red, green, pink } from 'constants/styles';
import React from 'react';

const CloseButton = ({ closeToast }: Partial<CloseButtonProps>) => (
    <i className="tim-icons icon-simple-remove" onClick={closeToast} />
);

export const Message = styled(ToastContainer).attrs({
    closeButton: CloseButton,
    hideProgressBar: true
})`
    &&&.Toastify__toast-container .Toastify__toast--info {
        background-color: ${blue};
        color: ${white};
    }
    &&&.Toastify__toast-container .Toastify__toast--success {
        background-color: ${green};
        color: ${black};
    }
    &&&.Toastify__toast-container .Toastify__toast--error {
        background-color: ${pink};
        color: ${red};
    }
    &&&.Toastify__toast-container .Toastify__toast-icon {
        display: none;
    }
    &&&.Toastify__toast-container .Toastify__toast-body {
        margin: 0;
        padding: 0;
    }
    &&&.Toastify__toast-container .Toastify__toast {
        padding: 16px 24px;
        display: grid;
        align-items: flex-start;
        grid-template-columns: 1fr auto;
        grid-gap: 40px;
        min-height: 50px;
    }
    &&&.Toastify__toast-container .tim-icons.icon-bell-55 {
        font-size: 18px;
    }
    &&&.Toastify__toast-container .tim-icons.icon-check-2 {
        font-size: 21px;
    }
    &&&.Toastify__toast-container .tim-icons.icon-alert-circle-exc {
        font-size: 21px;
    }
`;

export const StyledContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 18px;
    align-items: flex-start;
`;

export const info = (text: string) =>
    toast.info(
        <StyledContainer>
            <i className="tim-icons icon-bell-55" />
            {text}
        </StyledContainer>
    );

export const success = (text: string) =>
    toast.success(
        <StyledContainer>
            <i className="tim-icons icon-check-2" />
            {text}
        </StyledContainer>
    );

export const error = (text: string) =>
    toast.error(
        <StyledContainer>
            <i className="tim-icons icon-alert-circle-exc" />
            {text}
        </StyledContainer>
    );
