import { WomIcon } from 'assets/icons';
import { Row } from 'components/wrappers/flexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import React from 'react';
import { Sizes } from 'types';
import { Network, networkImgIcon } from './constants';
import { ItemValue, Wrapper } from './styles';

interface RewardBlockProps extends Pick<Sizes, 'width'> {
    value: number;
    networkTitle: string;
}

export const RewardBlock = ({ value, networkTitle, width }: RewardBlockProps) => {
    const iconSrc = networkImgIcon[networkTitle as Network];

    return (
        <Wrapper width={width}>
            <Row alignCenter noWrap>
                {iconSrc ? (
                    <img alt={networkTitle} height="18px" src={iconSrc} />
                ) : (
                    <ItemValue>{networkTitle}</ItemValue>
                )}

                <MarginWrapper marginLeft="18px" marginRight="18px">
                    <ItemValue>{value}</ItemValue>
                </MarginWrapper>
            </Row>
            <WomIcon />
        </Wrapper>
    );
};
