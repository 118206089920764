import { TrashIcon } from 'assets/icons';
import bullzLogo from 'assets/img/bullz_logo_square.png';
import { Loader } from 'components/common/Loader';
import { Empty } from 'components/grid/Empty';
import { Span } from 'components/typography/Span';
import { Table } from 'components/ui/Table';
import { Section } from 'components/wrappers/flexWrapper';
import { errorMessage } from 'constants/defaults';
import { CollaborationEnrollmentStatusesKey } from 'constants/defaults/collaborations';
import { ParticipationEndedReason } from 'constants/defaults/enrollments';
import { HiddenScrollBlock, red, white } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router';
import { collaborationsEffects, collaborationsEvents, collaborationsStores } from 'stores/collaborationEnrollments';
import { modalEvents } from 'stores/kickUserModal';
import { Id } from 'types/interfaces/data';
import { getTimeLeft } from 'utils/formateDate';
import { getParticipationStatus, getPublishingStatus, getSubmissionStatus } from 'utils/parseStatuses';
import { TimeLeftCell } from '../TimeLeftCell';
import { columns, emptyMessage } from './constants';
import { ImageWrapper, TrashWrapper } from './styles';

const { getEnrollments } = collaborationsEffects;
const { enrollments, enrollmentsLoading, errors, enrollmentsLoadMoreLoading } = collaborationsStores;
const { loadMore } = collaborationsEvents;
const { openModal } = modalEvents;

export const EnrollmentTab = () => {
    const { id } = useParams<Id>();
    const { items, hasNext } = useStore(enrollments);
    const responseCollaborationsErrors = useStore(errors);
    const loading = useStore(enrollmentsLoading);
    const loadingMore = useStore(enrollmentsLoadMoreLoading);

    const [loadRef] = useInfiniteScroll({
        loading: loadingMore,
        hasNextPage: !!hasNext,
        onLoadMore: () => loadMore(id),
        disabled: responseCollaborationsErrors !== null,
        rootMargin: '0px'
    });

    useEffect(() => {
        if (id) {
            getEnrollments({ collaborationId: id });
        }
    }, [id]);

    const tableData = useMemo(() => {
        if (!items || items.length === 0) return [];

        return items.map(
            ({
                creatorProfile,
                acceptedSubmissionDeadlineUtc,
                participationState,
                participationEndedReason,
                submissionState,
                submissionEndedReason,
                publishingState,
                publishingNetwork,
                participantId,
                publishingEndedReason,
                id,
                collaborationId
            }) => {
                const isKicked = participationEndedReason === ParticipationEndedReason.KickedOut;
                const isAvailableToKick =
                    !isKicked &&
                    (participationState === CollaborationEnrollmentStatusesKey.None ||
                        participationState === CollaborationEnrollmentStatusesKey.EnrollmentRequested ||
                        participationState === CollaborationEnrollmentStatusesKey.Enrolled);
                const showTimeLeft =
                    acceptedSubmissionDeadlineUtc &&
                    participationState &&
                    participationState < CollaborationEnrollmentStatusesKey.Submitted;

                return {
                    avatar: <ImageWrapper borderRadius="50px" src={creatorProfile?.profileImageUrl || bullzLogo} />,
                    username: (
                        <Span fontSize="14px">{creatorProfile?.userName ? `@${creatorProfile.userName}` : '-'}</Span>
                    ),
                    timeLeft: (
                        <Span fontSize="14px">
                            {showTimeLeft ? <TimeLeftCell {...getTimeLeft(acceptedSubmissionDeadlineUtc)} /> : '-'}
                        </Span>
                    ),
                    participationState: (
                        <Span color={showTimeLeft ? red : white} fontSize="14px">
                            {getParticipationStatus(participationState, participationEndedReason)}
                        </Span>
                    ),
                    submissionState: getSubmissionStatus(submissionState, submissionEndedReason),
                    publishingState: getPublishingStatus(publishingState, publishingEndedReason, publishingNetwork),
                    kickUser: isAvailableToKick && (
                        <TrashWrapper
                            onClick={() => {
                                if (collaborationId && creatorProfile?.userName && participantId) {
                                    openModal({
                                        participantId,
                                        userName: creatorProfile.userName,
                                        collaborationId
                                    });
                                }
                            }}
                        >
                            <TrashIcon />
                        </TrashWrapper>
                    ),
                    id
                };
            }
        );
    }, [items]);

    const emptyDescription = responseCollaborationsErrors !== null ? errorMessage : emptyMessage;

    if (loading) {
        return (
            <Section justifyCenter>
                <Loader />
            </Section>
        );
    }

    if (tableData.length === 0 || responseCollaborationsErrors !== null) {
        return <Empty description={emptyDescription} />;
    }

    return (
        <>
            <Table columns={columns} data={tableData} emptyLayoutMessage={emptyMessage} rowKey={record => record.id} />
            <HiddenScrollBlock ref={loadRef} />
            {loadingMore && <Loader />}
        </>
    );
};
