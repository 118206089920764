import { disableDefaultInputStyleMixin, primaryColor, secondaryColor, transitionTime } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';
import { Sizes } from 'types/interface';

export const InputWrapper = styled.div<Sizes>`
    position: relative;
    width: ${({ width }) => width ?? '100%'};
    max-width: 465px;
    min-width: 250px;
    height: ${({ height }) => height ?? '100%'};
    background-color: inherit;
    display: flex;
    align-items: center;

    @media ${device.mobile} {
        max-width: 100%;
    }
`;

export const Input = styled.input`
    ${disableDefaultInputStyleMixin};
    width: 100%;
    border: 1px solid ${secondaryColor};
    border-radius: 6px;
    background-color: inherit;
    padding: 8px 9px;
    padding-right: 28px;
    color: white;
    transition: border ${transitionTime};
    height: 39px;

    &:focus {
        border: 1px solid ${primaryColor};
    }

    &::placeholder {
        font-family: inherit;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.15);
        opacity: 0.8;
    }

    &::-webkit-search-cancel-button {
        margin-right: 20px;
    }
`;
