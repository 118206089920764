export enum ParticipationEndedReason {
    None = 0,
    SubmittedAndPublished = 200,
    SubmittedOnly = 201,
    SubmissionFailed = 500,
    DeadlineMissed = 501,
    SubmissionReported = 502,
    Leave = 503,
    KickedOut = 504,
    ParticipationRequestRejected = 505
}

export enum ParticipationState {
    None = 0,
    EnrollmentRequested = 5,
    Enrolled = 10,
    Submitted = 20,
    Published = 30,
    Ended = 1000
}
