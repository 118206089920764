import { CustomImg } from 'components/ui/CustomImg';
import { Title, Wrapper } from 'components/grid/Empty/styles';
import React, { FC } from 'react';
import { notFoundImg } from 'assets/icons';

interface Props {
    description: string;
}

export const Empty: FC<Props> = ({ description }) => (
    <Wrapper>
        <CustomImg alt="no found icon" src={notFoundImg} />
        <Title>{description}</Title>
    </Wrapper>
);
