import { WomIcon } from 'assets/icons';
import { Span } from 'components/typography/Span';
import { Wrapper } from 'pages/Organization/OrganizationDescription/styles';
import { Section } from 'components/wrappers/flexWrapper';
import { usersLink } from 'constants/routes';
import { grey1 } from 'constants/styles';
import { copyWebsiteLinkMessage } from 'pages/Organizations/OrganizationCard/constants';
import React, { FC } from 'react';
import { Tag } from 'components/ui/Tag';
import { DescriptionProperty } from 'components/common/DescriptionProperty';
import { DescriptionContent } from 'components/common/DescriptionContent';

export const OrganizationDescription: FC<BUCM.OrganizationResponse> = ({
    organizationId,
    walletId,
    title,
    adminIds,
    websiteLink,
    womInEscrowValue
}) => (
    <Wrapper>
        <Section gap="16px" marginBottom="16px">
            {title && <DescriptionProperty subtitle={title} title="Organization name" />}

            {organizationId && (
                <DescriptionProperty
                    copyable
                    subtitle={organizationId}
                    successText="Organization ID is successfully copied"
                    title="Organization ID"
                />
            )}

            <DescriptionProperty
                copyable
                subtitle={websiteLink || '-'}
                successText={copyWebsiteLinkMessage}
                title="Website"
            />

            {walletId && (
                <DescriptionProperty
                    copyable
                    subtitle={walletId}
                    successText="Wallet ID is successfully copied"
                    title="Wallet ID"
                />
            )}

            <DescriptionProperty
                icon={<WomIcon />}
                subtitle={`${womInEscrowValue}` || '0'}
                successText={copyWebsiteLinkMessage}
                title="Wom in Escrow"
            />
        </Section>

        <DescriptionContent title="Admins">
            {adminIds?.length ? (
                adminIds?.map(id => (
                    <Tag key={id} link={`${usersLink}/${id}`}>
                        {id}
                    </Tag>
                ))
            ) : (
                <Span color={grey1}>No content</Span>
            )}
        </DescriptionContent>
    </Wrapper>
);
