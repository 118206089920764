import { Column } from 'components/wrappers/flexWrapper';
import { grey, white } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled(Column)`
    align-items: flex-end;
    gap: 3px;
`;

export const Title = styled.h4`
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
    color: ${grey};
    font-weight: 400;
`;

export const Count = styled.span`
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: ${white};
`;
