import { bullzLogo, ThreeDotsIcon } from 'assets/icons';
import { CustomImg } from 'components/ui/CustomImg';
import { DefaultHamburger } from 'components/grid/Navbar/DefaultHamburger';
import { MobileHamburger } from 'components/grid/Navbar/MobileHamburger';
import {
    Arrow,
    DropdownToggle,
    DropdownWrapper,
    Label,
    LeftSideNavBarWrapper,
    NavbarWrapper,
    StyledHorizontalLine,
    UserInfo,
    VersionInfo,
    StyledButton
} from 'components/grid/Navbar/styles';
import { Row, Section } from 'components/wrappers/flexWrapper';
import { RelativeWrapper } from 'components/wrappers/RelativeWrapper';
import { newRoutesArray } from 'constants/routes';
import { appVersion, environment, nameProject } from 'constants/services';
import { lg } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { userEvents, userStores } from 'stores/user';
import { HandleMiniToggle, IsSidebarOpened } from 'types/interfaces/global';
import { NoopType } from 'types/types';
import { getActiveRouteName } from 'utils/usefulFunctions';

interface Props extends HandleMiniToggle, IsSidebarOpened {
    toggleSidebar: NoopType;
}

export const Navbar: FC<Props> = ({ handleMiniToggle, isSidebarOpened, toggleSidebar }) => {
    const { user } = useStore<BUCM.UserJwtTokenResponse>(userStores.user);
    const [dropdownIsVisible, setDropdownIsVisible] = useState(false);
    const isMobile = useMediaQuery({ query: `(max-width: ${lg})` });
    const activeRouteName = getActiveRouteName(newRoutesArray);

    const toggleVisibility = () => {
        setDropdownIsVisible(!dropdownIsVisible);
    };

    const logout = () => {
        setDropdownIsVisible(!dropdownIsVisible);
        userEvents.logout();
    };

    const onVersionsButtonClick = () => {
        setDropdownIsVisible(!dropdownIsVisible);
        window.ivwt?.show && window.ivwt.show();
    };

    return (
        <NavbarWrapper>
            <LeftSideNavBarWrapper>
                <DefaultHamburger handleMiniToggle={handleMiniToggle} />
                <MobileHamburger isSidebarOpened={isSidebarOpened} toggleSidebar={toggleSidebar} />
                <UserInfo>{`${activeRouteName} ${user?.email ? ` (${user?.email})` : ''}`}</UserInfo>
            </LeftSideNavBarWrapper>
            <RelativeWrapper width="auto">
                <DropdownToggle onClick={() => toggleVisibility()}>
                    {!isMobile ? (
                        <Section alignCenter justifyAround noWrap>
                            <Row alignCenter marginRight="10px" width="42px">
                                <CustomImg alt="Logo" src={bullzLogo} />
                            </Row>
                            <Row alignCenter>
                                <Arrow />
                            </Row>
                        </Section>
                    ) : (
                        <ThreeDotsIcon />
                    )}
                </DropdownToggle>

                <DropdownWrapper visible={dropdownIsVisible}>
                    <Label>User</Label>
                    <StyledButton onClick={logout}>Log out</StyledButton>
                    <StyledHorizontalLine />
                    <Label>About</Label>
                    <StyledButton onClick={onVersionsButtonClick}>What’s new</StyledButton>
                    <VersionInfo>
                        Version: {appVersion} {nameProject}: {environment}
                    </VersionInfo>
                </DropdownWrapper>
            </RelativeWrapper>
        </NavbarWrapper>
    );
};
