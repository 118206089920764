import { CopyButton } from 'components/common/CopyButton';
import { Section } from 'components/wrappers/flexWrapper';
import React from 'react';
import { FlexBasis, FlexGrow, Sizes } from 'types';
import { Copyable, MaxSizes, MinSizes, Success, Title } from 'types/interface';
import { BlockRowSubtitle, BlockRowTitle, BlockRowWrapper, ScrollableContent } from './styles';
import { useMediaQuery } from 'react-responsive';
import { lg } from 'constants/styles';
import { getEllipsisAddress } from 'utils/usefulFunctions';

interface PropertyRowBlockProps
    extends Title,
        Partial<Success>,
        Pick<Sizes, 'width'>,
        Copyable,
        MaxSizes,
        MinSizes,
        FlexBasis,
        FlexGrow {
    isShort?: boolean;
}
export const PropertyRowBlock = ({
    title,
    subtitle,
    successText,
    copyable,
    width,
    maxWidth,
    minWidth,
    flexBasis,
    flexGrow,
    isShort
}: PropertyRowBlockProps) => {
    const isMobile = useMediaQuery({ query: `(max-width: ${lg})` });

    return (
        <ScrollableContent flexGrow={flexGrow} isMobile={isMobile}>
            <BlockRowWrapper flexBasis={flexBasis} maxWidth={maxWidth} minWidth={minWidth} width={width}>
                <BlockRowTitle>{title}</BlockRowTitle>
                {copyable ? (
                    <Section alignCenter noWrap>
                        <BlockRowSubtitle marginRight="15px">
                            {isShort && isMobile && subtitle ? getEllipsisAddress(subtitle as string, 6) : subtitle}
                        </BlockRowSubtitle>
                        <CopyButton subject={subtitle} successText={successText} />
                    </Section>
                ) : (
                    <Section>
                        <BlockRowSubtitle>{subtitle}</BlockRowSubtitle>
                    </Section>
                )}
            </BlockRowWrapper>
        </ScrollableContent>
    );
};
