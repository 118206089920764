/** Libs */
import React, { FC } from 'react';

/** Components, assets */
import { StatusesWrapper } from './StatusesWrapper';
import { noneStatus, awaitingStatus, pendingStatus, endedStatus } from 'assets/icons';

interface Props {
    status: BUCM.PublishingState;
}

const getIcon = (status: BUCM.PublishingState): string => {
    switch (status) {
        case 0:
            return noneStatus;
        case 10:
            return awaitingStatus;
        case 20:
            return pendingStatus;
        case 30:
            return pendingStatus;
        case 1000:
            return endedStatus; // not used; Ended reasons are used
        default:
            return noneStatus;
    }
};

const getPopoverText = (status: BUCM.PublishingState): string => {
    switch (status) {
        case 0:
            return '-';
        case 10:
            return 'Video under review';
        case 20:
            return 'Waiting for share';
        case 30:
            return 'Validating share';
        case 1000:
            return 'Published'; // not used; Ended reasons are used
        default:
            return '-';
    }
};

export const PublishingStatuses: FC<Props> = ({ status }) => (
    <StatusesWrapper icon={getIcon(status)} popoverText={getPopoverText(status)} />
);
