import { flexStart, grey } from 'constants/styles';
import styled from 'styled-components';

export const TagsWrapper = styled.div`
    ${flexStart};
    max-width: 100%;
    height: 34px;
    overflow-y: hidden;
    overflow-x: auto;

    ::-webkit-scrollbar {
        height: 4px;
        background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background-color: rgba(160, 160, 160, 0.3);
        border-radius: 10px;
    }

    scrollbar-color: rgba(160, 160, 160, 0.3) transparent;
    scrollbar-width: auto;
`;

export const Title = styled.h5`
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 12px;
`;

export const Span = styled.span`
    font-size: 13px;
    line-height: 20px;
    color: ${grey};
`;
