/** Libs */
import React, { FC } from 'react';

/** Components, assets */
import { StatusesWrapper } from './StatusesWrapper';
import {
    noneStatus,
    publishedBullzStatus,
    curationFailedStatus,
    reportedStatus,
    deadlineMissedStatus
} from 'assets/icons';

interface Props {
    status: BUCM.SubmissionEndedReason;
}

const getIcon = (status: BUCM.SubmissionEndedReason): string => {
    switch (status) {
        case 0:
            return noneStatus;
        case 200:
            return publishedBullzStatus;
        case 500:
            return curationFailedStatus;
        case 501:
            return reportedStatus;
        case 503:
            return deadlineMissedStatus;
        default:
            return noneStatus;
    }
};

const getPopoverText = (status: BUCM.SubmissionEndedReason): string => {
    switch (status) {
        case 0:
            return '-';
        case 200:
            return 'Video published';
        case 500:
            return 'Video failed review';
        case 501:
            return 'Video reported';
        case 503:
            return 'Deadline missed. Video not submitted';
        default:
            return '-';
    }
};

export const SubmissionEndedStatuses: FC<Props> = ({ status }) => (
    <StatusesWrapper icon={getIcon(status)} popoverText={getPopoverText(status)} />
);
