import Tooltip from 'rc-tooltip';
import { Avatar } from 'components/common/Avatar';
import { PropertyBlock } from 'components/common/PropertyBlock';
import { Tag } from 'components/ui/Tag';
import { CardWrapper } from 'components/wrappers/CardWrapper/styles';
import { Column, Section } from 'components/wrappers/flexWrapper';
import { GridWrapper } from 'components/wrappers/GridWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { getFormattedDate } from 'utils/formateDate';
import { copyRemoteIdMessage, copyUserIdMessage, formatDate, getRole } from './constants';
import { TagsWrapper, UserEmail, Username } from './styles';
import { usersLink } from 'constants/routes';
import { Button } from 'components/ui/Button';
import { useMediaQuery } from 'react-responsive';
import { xs } from 'constants/styles';
import { getAge } from 'utils/getAge';
import { GenderState } from 'constants/defaults/users';

export const UserCard: FC<BUCM.UserResponse> = ({
    username,
    userId,
    remoteUserId,
    roles,
    email,
    utcCreated,
    isAccountVerified,
    gender,
    dateOfBirth,
    profileImageUrl
}) => {
    const history = useHistory();
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    const age = dateOfBirth && getAge(new Date(dateOfBirth)).toString();

    const moreInfoHandleClick = () => history.push(`${usersLink}/${userId}`);

    return (
        <CardWrapper>
            <Section alignCenter marginBottom="24px">
                <MarginWrapper marginRight="15px">
                    <Avatar isVerified={isAccountVerified && !!remoteUserId} src={profileImageUrl || ''} />
                </MarginWrapper>

                <Column>
                    <Tooltip overlay="Username" placement="top">
                        <Username>{username ? `@${username}` : 'No name'}</Username>
                    </Tooltip>

                    <MarginWrapper marginTop="5px">
                        <Tooltip overlay="Email" placement="top">
                            <UserEmail>{email || 'No email'}</UserEmail>
                        </Tooltip>
                    </MarginWrapper>
                </Column>
            </Section>

            <GridWrapper columns={2} gap={16} marginBottom="16px">
                <PropertyBlock subtitle={GenderState[gender ? gender : 0]} title="Gender" />
                <PropertyBlock subtitle={age || '-'} title="Age" />
            </GridWrapper>

            <GridWrapper columns={!!remoteUserId ? 2 : 1} marginBottom="16px">
                <PropertyBlock
                    isIdType
                    copyable={!!userId}
                    subtitle={userId || '-'}
                    successText={copyUserIdMessage}
                    title="ID"
                />

                {!!remoteUserId && (
                    <PropertyBlock
                        isIdType
                        copyable={!!remoteUserId}
                        subtitle={remoteUserId || '-'}
                        successText={copyRemoteIdMessage}
                        title="Remote User ID"
                    />
                )}
            </GridWrapper>

            <PropertyBlock
                marginBottom="16px"
                subtitle={utcCreated ? getFormattedDate(utcCreated, formatDate) : '-'}
                title="Created Account"
                width="100%"
            />

            <TagsWrapper>
                {roles?.length ? (
                    roles?.map(role => (
                        <div key={role}>
                            <Tag>{getRole(role)}</Tag>
                        </div>
                    ))
                ) : (
                    <span>No roles</span>
                )}
            </TagsWrapper>
            <Section alignCenter justifyCenter marginTop="20px">
                <Button wide={isMobile} onClick={moreInfoHandleClick}>
                    More Info
                </Button>
            </Section>
        </CardWrapper>
    );
};
