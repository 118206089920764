import styled, { css } from 'styled-components';
import { blue, grey6 } from 'constants/styles/colors';
import { disableDefaultCheckboxStyleMixin, flexCenter } from 'constants/styles/mixins';
import { CheckboxProps } from './index';

interface LabelProps {
    wide?: boolean;
    padding?: string;
}

export const Label = styled.label<LabelProps>`
    display: flex;
    align-items: center;
    cursor: pointer;
`;

export const HiddenCheckbox = styled.input`
    ${disableDefaultCheckboxStyleMixin};
`;

export const StyledCheckbox = styled.div<CheckboxProps>`
    min-width: 18px;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    ${flexCenter};
    background-color: ${({ checked }) => (checked ? blue : 'transparent')};
    border: 2px solid ${({ checked }) => (checked ? 'transparent' : grey6)};
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
    z-index: 10;

    ${({ checked }) =>
        !checked &&
        css`
            &:hover {
                border-color: transparent;
                background-color: ${grey6};
            }
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            border-color: transparent;
            background-color: ${grey6};
            cursor: not-allowed;
        `};
`;
