import { FullComponentLoader } from 'components/common/FullComponentLoader';
import { useStore } from 'effector-react';
import { Grid, Title, Wrapper } from 'pages/Dashboard/Socials/styles';
import { SocialSwitcher } from 'pages/Dashboard/SocialSwitcher';
import React, { FC, useEffect } from 'react';
import { externalNetworksEffects, externalNetworksStores } from 'stores/externalNetworks';
import { getNetworkIcon, networkIsBullz } from './constants';

const { getExternalNetworks, toggleExternalNetwork } = externalNetworksEffects;
const { externalNetworksWithStatus } = externalNetworksStores;

export const Socials: FC = () => {
    const networks = useStore(externalNetworksWithStatus);
    const loading = useStore(getExternalNetworks.pending);

    useEffect(() => {
        getExternalNetworks();
    }, []);

    const onChange = (networkName: string, isChecked: boolean) => () => {
        toggleExternalNetwork({ networkName, isChecked });
    };

    return (
        <Wrapper>
            <Title>SOCIAL NETWORKS</Title>
            <Grid>
                {networks.map(network => (
                    <SocialSwitcher
                        key={network.id}
                        checked={network.enabled}
                        hasSwitcher={!networkIsBullz(network)}
                        icon={getNetworkIcon(network.title)}
                        id={network.title}
                        title={network.title}
                        onChange={onChange(network.title, !network.enabled)}
                    />
                ))}
            </Grid>
            {loading && <FullComponentLoader />}
        </Wrapper>
    );
};
