import { defaultLimit, defaultPage, defaultSort } from 'constants/defaults';

export const errorMessage = 'Error with loading data';

export const defaultOrganizationsValues = {
    pageIndex: defaultPage,
    limit: defaultLimit,
    returnQueryCount: true,
    sort: defaultSort
};

export const defaultCollaborationsParams: Paths.Collaborations.Get.QueryParameters = {
    limit: 20,
    pageIndex: 0,
    filter: undefined
};

export const defaultEnrollmentsParams: Paths.Collaborations$CollaborationIdParticipations.Get.QueryParameters = {
    limit: 20,
    pageIndex: 0,
    sort: undefined
};
