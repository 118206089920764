import { Row, Section } from 'components/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const FilterWrapper = styled(Section)`
    align-items: center;
    justify-content: space-between;

    @media ${device.tablet} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const SearchWrapper = styled.div`
    width: 460px;

    @media ${device.tablet} {
        width: 362px;
        margin-bottom: 22px;
    }

    @media ${device.medium} {
        margin-bottom: 18px;
    }

    @media ${device.small} {
        width: 100%;
    }
`;

export const ButtonWrapper = styled.div`
    margin-left: 12px;
    margin-right: 24px;

    @media ${device.tablet} {
        margin-right: 12px;
    }

    @media (max-width: 320px) {
        margin-right: 0;
        margin-left: 0;
    }
`;

export const ButtonsWrapper = styled(Row)`
    @media (max-width: 320px) {
        width: 100%;
        justify-content: space-between;
    }
`;
