import { TableHeadInterface } from 'types';

export const emptyMessage = 'There are no enrollments yet';

export const columns: TableHeadInterface[] = [
    {
        title: 'AVATAR',
        dataIndex: 'avatar',
        key: 'avatar'
    },
    {
        title: 'USER',
        dataIndex: 'username',
        key: 'username'
    },
    {
        title: 'TIME LEFT',
        dataIndex: 'timeLeft',
        key: 'timeLeft'
    },
    {
        title: 'USER PARTICIPATION STATUS',
        dataIndex: 'participationState',
        key: 'participationState'
    },
    {
        title: 'SUBMISSION TO BULLZ',
        dataIndex: 'submissionState',
        key: 'submissionState'
    },
    {
        title: 'PUBLISH TO SOCIAL NETWORKS',
        dataIndex: 'publishingState',
        key: 'publishingState'
    },
    {
        title: '',
        dataIndex: 'kickUser',
        key: 'kickUser'
    }
];
