import { Column, Row } from 'components/wrappers/flexWrapper';
import { blue, flexStart } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${flexStart};
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    min-height: 100vh;
    overflow-y: scroll;
`;

export const ModalContainer = styled.div`
    padding: 100px 128px;
    width: 100%;

    @media ${device.tablet} {
        padding: 50px 16px;
    }
`;

export const ModalHeader = styled(Row)`
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
`;

export const ModalContent = styled(Row)`
    min-height: 500px;
    justify-content: center;
    align-items: center;
    padding-bottom: 48px;
`;

export const VideoContainer = styled(Column)`
    align-items: center;

    &:not(:last-of-type) {
        margin-right: 40px;
    }
`;

export const VideoWrapper = styled.div`
    width: 239px;
    height: 496px;
`;

export const IconWrapper = styled(Row)`
    align-items: center;
    margin-bottom: 18px;
`;

export const LinkWrapper = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
    margin-left: 8px;
    color: ${blue};
`;
