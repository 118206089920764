import { HiddenInput, Label, Wrapper } from 'components/form/Switch/styles';
import React, { FC } from 'react';
import { NoopType } from 'types';

interface Props {
    id: string;
    checked: boolean;
    disabled?: boolean;
    onChange: NoopType;
}

export const Switch: FC<Props> = ({ checked, onChange, id, disabled = false }) => (
    <Wrapper>
        <HiddenInput checked={checked} id={id} type="checkbox" onChange={!disabled ? onChange : undefined} />
        <Label disabled={disabled} htmlFor={id} />
    </Wrapper>
);
