import { SubmissionGrid } from 'components/common/SubmissionGrid/styles';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { Empty } from 'components/grid/Empty';
import { errorMessage } from 'constants/defaults';
import { HiddenScrollBlock } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useParams } from 'react-router';
import { submissionsEffects, submissionsEvents, submissionsStores } from 'stores/collaborationSubmissions';
import { externalNetworksEffects, externalNetworksStores } from 'stores/externalNetworks';
import { Id } from 'types/interfaces/data';
import { SubmissionCard } from '../SubmissionCard';
import { SubmissionsLoading } from '../SubmissionsLoading';
import { emptyMessage } from './constants';

const { getSubmissions } = submissionsEffects;
const { submissions, submissionsLoading, errors } = submissionsStores;
const { loadMore } = submissionsEvents;
const { externalNetworks } = externalNetworksStores;
const { getExternalNetworks } = externalNetworksEffects;

interface Props {
    isApproveVideo?: boolean;
}

export const SubmissionsTab: FC<Props> = ({ isApproveVideo }) => {
    const { id } = useParams<Id>();
    const { items, hasNext } = useStore(submissions);
    const { isLoading, isLoaded } = useStore(submissionsLoading);
    const responseErrors = useStore(errors);
    const extNetworks = useStore(externalNetworks);

    const videos = useMemo(
        () => items?.filter(item => item.mediaAssetUri && item.mediaAssetThumbnailUrl) || [],
        [items]
    );

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: !!hasNext,
        onLoadMore: () => loadMore(id),
        disabled: responseErrors !== null || extNetworks.length === 0,
        rootMargin: '0px'
    });

    useEffect(() => {
        if (id) {
            getExternalNetworks();
        }
    }, [id]);

    useEffect(() => {
        if (id && extNetworks.length > 0) {
            getSubmissions(id);
        }
    }, [id, extNetworks]);

    if (!isLoaded && isLoading) {
        return (
            <SubmissionGrid>
                <SubmissionsLoading />
            </SubmissionGrid>
        );
    }

    if (!hasNext && videos.length === 0) {
        return <Empty description={emptyMessage} />;
    }

    if (responseErrors !== null) {
        return <Empty description={errorMessage} />;
    }

    return (
        <MarginWrapper>
            {videos.length > 0 && (
                <SubmissionGrid>
                    {videos.map(video => (
                        <SubmissionCard
                            key={video.id}
                            canOpenModal
                            hasFeedback={video.hasFeedback}
                            isApproveVideo={isApproveVideo}
                            mediaAssetState={video.mediaAssetState}
                            video={video}
                        />
                    ))}

                    {isLoading && <SubmissionsLoading />}
                </SubmissionGrid>
            )}

            <HiddenScrollBlock ref={loadRef} />
        </MarginWrapper>
    );
};
