import { defaultEnrollmentsParams, errorMessage } from 'constants/defaults';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { API } from 'services';
import { filterApproveUser } from 'utils/filterApproveUser';

// Events
const loadMore = createEvent<string>();
const setErrors = createEvent<BUCM.ErrorResponse | string | null>();
const updateEnrollmentsParams = createEvent<Paths.Collaborations$CollaborationIdParticipations.Get.QueryParameters>();
const setCollaborationsErrors = createEvent<BUCM.ErrorResponse | string | null>();
const resetCollaborationsErrors = createEvent();

// Effects
const getApproveUserFx = createEffect({
    handler: async ({ collaborationId }: Paths.Collaborations$CollaborationId.Patch.PathParameters) => {
        try {
            const { isSuccess, data, error } = await API.collaborations.getEnrollments(
                collaborationId,
                defaultEnrollmentsParams
            );
            if (isSuccess) {
                return data;
            }

            setCollaborationsErrors(error || errorMessage);
            return {};
        } catch (error) {
            setCollaborationsErrors(errorMessage);
            return {};
        }
    }
});

interface LoadMoreEnrollmentsParams {
    collaborationId: string;
    params: BUCM.ParticipationsResponse;
}

const loadMoreApproveUser = createEffect({
    handler: async ({ collaborationId, params }: LoadMoreEnrollmentsParams) => {
        try {
            const { isSuccess, data, error } = await API.collaborations.getEnrollments(collaborationId, {
                ...defaultEnrollmentsParams,
                pageIndex: params.pageIndex !== undefined ? params.pageIndex + 1 : defaultEnrollmentsParams.pageIndex
            });

            if (isSuccess) {
                resetCollaborationsErrors();
                return data;
            }

            setCollaborationsErrors(error || errorMessage);
            return {};
        } catch (error) {
            setCollaborationsErrors(errorMessage);
            return {};
        }
    }
});

//Stores
const $approveUserLoading = createStore<boolean>(false).on(getApproveUserFx.pending, (_, payload) => payload);

const enrollmentsParams = createStore<Paths.Collaborations$CollaborationIdParticipations.Get.QueryParameters>(
    defaultEnrollmentsParams
).on(updateEnrollmentsParams, (state, newState) => ({
    ...state,
    ...newState
}));

const $approveUserLoadMoreLoading = createStore<boolean>(false).on(
    loadMoreApproveUser.pending,
    (_, payload) => payload
);

const $approveUser = createStore<BUCM.ParticipationsResponse>({})
    .on(getApproveUserFx.doneData, (_, newState) => newState && filterApproveUser(newState))
    .on(loadMoreApproveUser.doneData, ({ items }, newState) => ({
        ...newState,
        items: [...(items || []), ...((newState && filterApproveUser(newState).items) || [])]
    }));

const $errors = createStore<BUCM.ErrorResponse | string | null>(null).on(setErrors, (_, newState) => newState);

sample({
    source: [$approveUser, enrollmentsParams],
    clock: loadMore,
    fn: ([enrollments, enrollmentsParams], collaborationId) => ({
        collaborationId,
        state: enrollments,
        params: enrollmentsParams
    }),
    target: loadMoreApproveUser
});

// Exports
export const collaborationApproveUserEvents = {
    loadMore
};

export const collaborationApproveUserEffects = {
    getApproveUserFx
};

export const collaborationApproveUserStores = {
    $approveUser,
    $approveUserLoading,
    $errors,
    $approveUserLoadMoreLoading
};
