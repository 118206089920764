import { MainLayout } from 'components/layouts/MainLayout';
import { CollaborationsCard } from 'pages/Dashboard/CollaborationsCard';
import { OrganizationsCard } from 'pages/Dashboard/OrganizationsCard';
import { Grid } from 'pages/Dashboard/styles';
import React from 'react';
import { Socials } from './Socials';

export const Dashboard = () => (
    <MainLayout>
        <Grid>
            <OrganizationsCard />
            <Socials />
            <CollaborationsCard />
        </Grid>
    </MainLayout>
);
