import { Span } from 'components/typography/Span';
import { CollaborationStatusesUi } from 'constants/defaults/collaborations';
import { green, grey2, primaryColor, red, white } from 'constants/styles';
import styled, { css } from 'styled-components';

interface ImageWrapperProps {
    src?: string;
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
    width: 42px;
    height: 42px;
    border-radius: 6px;
    overflow: hidden;
    background: ${({ src }) => (src ? `url("${src}")` : grey2)};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
`;

export const getStatusSpanStyle = (status: BUCM.CollaborationState) => {
    switch (status) {
        case CollaborationStatusesUi.Draft:
            return css`
                color: ${primaryColor};
            `;
        case CollaborationStatusesUi.Active:
            return css`
                color: ${green};
            `;
        case CollaborationStatusesUi.ActiveClosed:
            return css`
                color: ${green};
            `;
        case CollaborationStatusesUi.Ended:
            return css`
                color: ${red};
            `;
        case CollaborationStatusesUi.Deleted:
            return css`
                color: ${red};
            `;
        default:
            return css`
                color: ${white};
            `;
    }
};

interface StatusSpanProps {
    status: BUCM.CollaborationState;
}

export const StatusSpan = styled(Span)<StatusSpanProps>`
    font-size: 14px;
    text-transform: uppercase;
    ${({ status }) => status && getStatusSpanStyle(status)};
`;
