import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';
import { Round, Sizes, ZIndex } from 'types/interfaces/styles';
import { clickableWrapperDiameter } from './constants';

interface Props extends Round, Sizes, ZIndex {}

export const ClickableWrapper = styled.button<Props>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};
    ${({ round }) => round && 'border-radius: 50%;'};
    width: ${({ width }) => width || clickableWrapperDiameter};
    height: ${({ height }) => height || clickableWrapperDiameter};
    ${({ disabled }) => disabled && 'cursor: default;'};
    z-index: ${({ zIndex }) => (zIndex ? zIndex : 3)};
`;

export const DivClickableWrapper = styled.div<Sizes>`
    ${({ width }) => width && `width: ${width}`};
`;
