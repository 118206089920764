import { defaultLimit, defaultPage } from './common';

export enum UserRole {
    Administrator = 'Administrator',
    OrganizationAdministrator = 'OrganizationAdministrator',
    OrganizationMember = 'OrganizationMember',
    OrganizationViewer = 'OrganizationViewer',
    RemoteUser = 'RemoteUser'
}

export const userRoleLabels = {
    [UserRole.Administrator]: 'Administrator',
    [UserRole.OrganizationAdministrator]: 'Organization Administrator',
    [UserRole.OrganizationMember]: 'Organization Member',
    [UserRole.OrganizationViewer]: 'Organization Viewer',
    [UserRole.RemoteUser]: 'Remote User'
};

export const assignedUserRolesValues = [
    { value: UserRole.Administrator, label: userRoleLabels[UserRole.Administrator] },
    { value: UserRole.OrganizationAdministrator, label: userRoleLabels[UserRole.OrganizationAdministrator] },
    { value: UserRole.OrganizationMember, label: userRoleLabels[UserRole.OrganizationMember] },
    { value: UserRole.OrganizationViewer, label: userRoleLabels[UserRole.OrganizationViewer] },
    { value: UserRole.RemoteUser, label: userRoleLabels[UserRole.RemoteUser] }
];

export const rolesFilterList = [{ value: 'All', label: 'All' }, ...assignedUserRolesValues];

export const filterValue = (value?: string | null) => {
    if (!value) {
        return rolesFilterList[0];
    } else {
        return assignedUserRolesValues.find(item => item.value === value);
    }
};

export const defaultUsersValues = {
    pageIndex: defaultPage,
    limit: defaultLimit,
    returnQueryCount: true
};

export const defaultUserValues = {
    pageIndex: 0,
    limit: 1,
    returnQueryCount: true
};

export const usersNotFoundMessage = 'Users not found';
export const userNotFoundMessage = 'User not found';

export enum GenderState {
    NotGiven = 0,
    Male = 1,
    Female = 2,
    NonBinary = 3
}
