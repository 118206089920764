import { FlagIcon, FullIcon, PauseIcon, PlayIcon } from 'assets/icons';
import { ExternalNetworkKey } from 'constants/defaults';
import { grey, red, transparent, white } from 'constants/styles';
import { useStore } from 'effector-react';
import Hls from 'hls.js';
import { NetworkIcon } from 'pages/Collaboration/SubmissionCard/NetworkIcon';
import React, { FC, useEffect, useRef, useState } from 'react';
import { externalNetworksStores } from 'stores/externalNetworks';
import { videoModalEvents } from 'stores/videoModal';
import { submissionsEvents } from 'stores/collaborationSubmissions';
import { Badge, FlagWrapper, FullWrapper, NetworkTitle, NetworkWrapper, PlayWrapper, Video, Wrapper } from './styles';
import { Span } from 'components/typography/Span';
import { MEDIA_ASSET_STATE } from 'constants/defaults/statuses';

const { externalNetworks } = externalNetworksStores;
const { openVideoModal } = videoModalEvents;
const { openFeedbackModal } = submissionsEvents;

export interface SubmissionCardProps {
    isPlayable?: boolean;
    canOpenModal?: boolean;
    video: BUCM.MediaAssetResponse;
    rounded?: boolean;
    isApproveVideo?: boolean;
    mediaAssetState?: BUCM.MediaAssetState;
    hasFeedback?: boolean;
}

export const SubmissionCard: FC<SubmissionCardProps> = ({
    isPlayable = true,
    canOpenModal = true,
    rounded = true,
    video,
    isApproveVideo,
    mediaAssetState,
    hasFeedback
}) => {
    const networks = useStore(externalNetworks);
    const videoRef = useRef<HTMLVideoElement>(null);

    const [isPlaying, setIsPlaying] = useState<boolean>(false);
    const [isControlShown, setIsControlShown] = useState<boolean>(false);

    const {
        id,
        mediaAssetUri: src,
        networkId,
        mediaAssetThumbnailUrl: image,
        hasFeedback: isReported,
        postUri
    } = video;
    const networkTitle = networks.find((network: BUCM.ExternalNetworkResponse) => network.id === networkId)?.title;
    const hlsIsSupported = Hls.isSupported();
    const reportedFlagProps = {
        fill: isReported ? red : transparent,
        stroke: isReported ? red : white
    };

    const videoState = hasFeedback
        ? 'Reported'
        : mediaAssetState === MEDIA_ASSET_STATE.Valid
        ? 'Approved'
        : mediaAssetState === MEDIA_ASSET_STATE.Invalid
        ? 'Denied'
        : 'Pending Approval';

    useEffect(() => {
        if (isPlaying && hlsIsSupported && src && videoRef.current) {
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(videoRef.current);
            hls.on(Hls.Events.MANIFEST_PARSED, () => {
                videoRef?.current?.play();
            });
        }
    }, [src, isPlaying, hlsIsSupported]);

    const onOpenFull = () => {
        openVideoModal({ video });
    };

    const toggleVideo = () => {
        setIsPlaying((prev: boolean) => !prev);
    };

    const openReportModal = () => {
        id && openFeedbackModal(id);
    };

    return (
        <Wrapper
            image={!isPlaying && image ? image : ''}
            rounded={rounded}
            onMouseEnter={isPlayable ? () => setIsControlShown(true) : undefined}
            onMouseLeave={isPlayable ? () => setIsControlShown(false) : undefined}
        >
            {networkTitle && (
                <a href={postUri || ''} rel="noopener noreferrer" target="_blank">
                    <NetworkWrapper>
                        <NetworkIcon networkTitle={networkTitle} />
                        {networkTitle.toLowerCase() !== ExternalNetworkKey.Bullz && (
                            <NetworkTitle>{networkTitle}</NetworkTitle>
                        )}
                    </NetworkWrapper>
                </a>
            )}

            {isReported && (
                <FlagWrapper onClick={() => openReportModal()}>
                    <FlagIcon {...reportedFlagProps} />
                </FlagWrapper>
            )}

            {isApproveVideo && (
                <Badge>
                    <Span color={grey}>{videoState}</Span>
                </Badge>
            )}

            {!isPlaying && canOpenModal && (
                <FullWrapper onClick={onOpenFull}>
                    <FullIcon />
                </FullWrapper>
            )}
            {((!isPlaying && isPlayable) || isControlShown) && (
                <PlayWrapper onClick={toggleVideo}>{isPlaying ? <PauseIcon /> : <PlayIcon />}</PlayWrapper>
            )}
            {isPlaying && <Video ref={videoRef} autoPlay controls controlsList="nodownload" />}
        </Wrapper>
    );
};
