import { defaultSidebarSettings } from 'constants/defaults';
import { createEvent, createStore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { ActiveColor, IsDarkMode, IsSidebarMini } from 'types';

export interface SidebarSettings extends ActiveColor, IsSidebarMini, IsDarkMode {}

const sidebarSettingsStorage = connectLocalStorage('sidebarSettings').onError(err => console.log(err));

const setSidebarSettings = createEvent<Partial<SidebarSettings>>();

const sidebarSettings = createStore<SidebarSettings>(sidebarSettingsStorage.init(defaultSidebarSettings)).on(
    setSidebarSettings,
    (state, newState) => ({ ...state, ...newState })
);

sidebarSettings.watch(sidebarSettingsStorage);

export const settingsStores = { sidebarSettings };
export const settingsEvents = { setSidebarSettings };
