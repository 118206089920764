import Tooltip from 'rc-tooltip';
import React, { FC } from 'react';
import { StatusesWrapperBlock } from './styles';

interface Props {
    popoverText: string;
    icon: string;
}

export const StatusesWrapper: FC<Props> = ({ popoverText, icon }) => (
    <StatusesWrapperBlock>
        <Tooltip overlay={popoverText} placement="top">
            <img alt="status-icon" height={24} src={icon} width={24} />
        </Tooltip>
    </StatusesWrapperBlock>
);
