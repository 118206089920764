import { Row } from 'components/wrappers/flexWrapper';
import { black, white } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
    background-color: ${black};
    border-radius: 8px;
    padding: 18px;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
`;

export const LeftSide = styled(Row)`
    align-items: center;
    gap: 15px;
    font-size: 14px;
    line-height: 21px;
    color: ${white};
`;
