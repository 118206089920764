import { CustomImg } from 'components/ui/CustomImg';
import { success } from 'components/modals/Message';
import React from 'react';
import { Success } from 'types/interface';
import { triggerCopy } from 'utils/usefulFunctions';
import { StyledButton } from './styles';
import { copyButton } from 'assets/icons';

export interface CopyButtonProps extends Partial<Success> {
    subject?: string;
}

export const CopyButton = ({ successText, subject }: CopyButtonProps) => {
    const triggerButtonCopy = () => {
        if (subject) {
            triggerCopy(subject);
            if (successText) {
                success(successText);
            }
        }
    };

    return (
        <StyledButton disabled={!subject} onClick={triggerButtonCopy}>
            <CustomImg alt="copy" height="20px" src={copyButton} width="20px" />
        </StyledButton>
    );
};
