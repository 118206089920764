import { Button } from 'components/ui/Button';
import { SearchInput } from 'components/form/SearchInput';
import { InfoTitle } from 'components/typography/InfoTitle';
import { Column } from 'components/wrappers/flexWrapper';
import {
    CollaborationStatusFilter,
    collaborationStatusValues,
    defaultValues,
    filterList,
    statusList
} from 'constants/defaults/collaborations';
import { useStore } from 'effector-react';
import { getAllStatesCount } from 'pages/Collaborations/CollaborationsFilterLayout/constants';
import {
    FilterTitle,
    ResetButtonWrapper,
    SearchSelectWrapper,
    SelectWrapper
} from 'pages/Collaborations/CollaborationsFilterLayout/styles';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { collaborationsEffects, collaborationsEvents, collaborationsStores } from 'stores/collaborations';
import { Select } from 'components/ui/Select';

const { updateParams, getByDefaultParams } = collaborationsEvents;
const { getCollaborationsCount } = collaborationsEffects;
const { collaborationsCount } = collaborationsStores;

interface Props {
    children: ReactNode;
}

export const CollaborationsFilterLayout: FC<Props> = ({ children }) => {
    const collaborationStatesCount = useStore(collaborationsCount);
    const [status, setStatus] = useState(filterList[0].value);
    const [count, setCount] = useState(getAllStatesCount(collaborationStatesCount));
    const [searchId, setSearchId] = useState('');

    const selectValue = collaborationStatusValues.includes(status as CollaborationStatusFilter)
        ? filterList.find(({ value }) => value === status)
        : filterList[0];

    const onResetClick = () => {
        getByDefaultParams();
        setStatus(filterList[0].value);
        setSearchId('');
    };

    const onIdSearch = async (collaborationId: string) => {
        setStatus(filterList[0].value);
        setSearchId(collaborationId);
        updateParams({ ...defaultValues, collaborationId });
    };

    const onFilterChange = (option: Paths.Collaborations.Get.Parameters.Filter) => {
        setSearchId('');
        setStatus(option.value);
        const filterValue = option.value === 'all' ? defaultValues.filter : option.value;
        updateParams({ ...defaultValues, filter: filterValue });
    };

    useEffect(() => {
        getCollaborationsCount();
    }, []);

    useEffect(() => {
        if (status === 'all') {
            setCount(getAllStatesCount(collaborationStatesCount));
        } else {
            const collaborationStatus = statusList.find(({ filterValue }) => filterValue === status)?.status || 0;
            let count = 0;
            if (collaborationStatesCount && collaborationStatesCount.length > 0) {
                if (Array.isArray(collaborationStatus)) {
                    count = collaborationStatus.reduce((total, currentStatus) => {
                        const newCount =
                            collaborationStatesCount?.find(({ status }) => status === currentStatus)?.totalCount || 0;
                        return total + newCount;
                    }, 0);
                } else {
                    count =
                        collaborationStatesCount?.find(({ status }) => status === collaborationStatus)?.totalCount || 0;
                }
            }

            setCount(count);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collaborationStatesCount, status]);

    return (
        <Column height="100%">
            <SearchSelectWrapper>
                <SelectWrapper>
                    <FilterTitle>Filter by status</FilterTitle>
                    <Select name="sorts" options={filterList} value={selectValue} onChange={onFilterChange} />
                </SelectWrapper>

                <SearchInput defaultValue={searchId} placeholder="Search by Collaboration ID" onSearch={onIdSearch} />

                <ResetButtonWrapper>
                    <Button onClick={onResetClick}>Reset</Button>
                </ResetButtonWrapper>
            </SearchSelectWrapper>

            <InfoTitle title="Total: ">{count}</InfoTitle>

            {children}
        </Column>
    );
};
