import { errorMessage } from 'constants/defaults';
import { defaultLoadingInfo, defaultSubmissionsParams, ExternalNetworkKey } from 'constants/defaults/collaborations';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import { API } from 'services';
import { externalNetworksStores } from 'stores/externalNetworks';
import { getNetworkIdByName } from 'utils/externalNetworks';

const { externalNetworks } = externalNetworksStores;

// Events

const loadMore = createEvent<string>();
const setErrors = createEvent<BUCM.ErrorResponse | string | null>();
const setReport = createEvent<string>();

interface SubmissionsParams {
    collaborationId: string;
    externalNetworks: BUCM.ExternalNetworkResponse[];
}

// Effects

const getSharedSubmissionsEffect = createEffect(async ({ externalNetworks, collaborationId }: SubmissionsParams) => {
    try {
        const networkId = getNetworkIdByName(externalNetworks, ExternalNetworkKey.Bullz);
        if (!networkId) return;

        const { isSuccess, data } = await API.mediaAssets.getSubmissions(collaborationId, {
            ...defaultSubmissionsParams,
            IgnoreNetworkId: networkId
        });

        if (isSuccess) {
            setErrors(null);
            return data;
        }

        setErrors(errorMessage);
        return;
    } catch (error) {
        setErrors(errorMessage);
        console.error(error);
    }
});

const getSharedSubmissions = attach({
    source: externalNetworks,
    mapParams: (collaborationId: string, externalNetworks) => ({ collaborationId, externalNetworks }),
    effect: getSharedSubmissionsEffect
});

interface LoadMoreSubmissionsParams {
    collaborationId: string;
    state: BUCM.MediaAssetsResponse;
}

const loadMoreSubmissionsEffect = createEffect(
    async (
        externalNetworksStore: BUCM.ExternalNetworkResponse[],
        { collaborationId, state }: LoadMoreSubmissionsParams
    ) => {
        try {
            const networkId = getNetworkIdByName(externalNetworksStore, ExternalNetworkKey.Bullz);
            if (!networkId) return;

            const { isSuccess, data } = await API.mediaAssets.getSubmissions(collaborationId, {
                ...defaultSubmissionsParams,
                IgnoreNetworkId: networkId,
                pageIndex: state.pageIndex !== undefined ? state.pageIndex + 1 : defaultSubmissionsParams.pageIndex
            });

            if (isSuccess) {
                setErrors(null);
                return data;
            }

            setErrors(errorMessage);
            return;
        } catch (error) {
            setErrors(errorMessage);
            console.error(error);
        }
    }
);

const loadMoreSubmissions = attach({
    source: externalNetworks,
    effect: loadMoreSubmissionsEffect
});

// Stores

const submissions = createStore<BUCM.MediaAssetsResponse>({})
    .on(getSharedSubmissions.doneData, (_, newState) => newState)
    .on(loadMoreSubmissions.doneData, ({ items }, newState) => ({
        ...newState,
        items: [...(items || []), ...(newState?.items || [])]
    }))
    .on(setReport, (state, submissionId) => ({
        ...state,
        items: state.items?.map(item => {
            if (item.id === submissionId) {
                item.hasFeedback = true;
            }
            return item;
        })
    }));

sample({
    source: submissions,
    clock: loadMore,
    fn: (submissions, collaborationId) => ({ collaborationId, state: submissions }),
    target: loadMoreSubmissions
});

const submissionsLoading = createStore(defaultLoadingInfo)
    .on(getSharedSubmissions.done, state => ({
        ...state,
        isLoaded: true
    }))
    .on([getSharedSubmissions.pending, loadMoreSubmissions.pending], (state, result) => ({
        ...state,
        isLoading: result
    }));

const errors = createStore<BUCM.ErrorResponse | string | null>(null).on(setErrors, (_, newState) => newState);

// Exports

const sharedSubmissionsEvents = {
    loadMore,
    setReport
};

const sharedSubmissionsEffects = {
    getSharedSubmissions
};

const sharedSubmissionsStores = {
    submissions,
    submissionsLoading,
    errors
};

export { sharedSubmissionsStores, sharedSubmissionsEffects, sharedSubmissionsEvents };
