import { blue, grey2, secondaryColor, white } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    height: 22px;
`;

interface Props {
    disabled?: boolean;
}

export const Label = styled.label<Props>`
    line-height: 22px;
    position: relative;
    height: 22px;
    width: 45px;

    ${({ disabled }) => disabled && `opacity: 0.5`};

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        height: 22px;
        width: 45px;
        pointer-events: none;
        content: '';
        background-color: ${grey2};
        box-shadow: none;
        transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        pointer-events: all;
        border-radius: 0.625rem;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }

    &:after {
        position: absolute;
        top: 2px;
        left: 2px;
        display: block;
        width: 18px;
        height: 18px;
        content: '';
        background: 50%/50% 50% no-repeat;
        background-color: ${secondaryColor};
        transition: all 0.25s ease-out;
        border-radius: 0.625rem;
        cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
`;

export const HiddenInput = styled.input`
    display: none;

    &:checked {
        ~ ${Label}:before {
            color: ${white};
            border-color: ${blue};
            background-color: ${blue};
            box-shadow: none;
        }
        ~ ${Label}:after {
            background-color: ${white};
            transform: translateX(23px);
        }
    }
`;
