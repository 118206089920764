import { Loader } from 'components/common/Loader';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { RemoteUserDescription } from 'pages/User/RemoteUserDescription';
import { UserDescription } from 'pages/User/UserDescription';
import { Section } from 'components/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/grid/Empty';
import { errorLoadingMessage } from 'constants/defaults/common';
import { userNotFoundMessage } from 'constants/defaults/users';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { singleUserEffects, singleUserEvents, singleUserStores } from 'stores/singleUser';
import { Id } from 'types/interfaces/data';
import { Title } from './styles';

const { getUsersById } = singleUserEffects;
const { userInfo, errors } = singleUserStores;
const { resetSingleUser } = singleUserEvents;

export const User = () => {
    const { id } = useParams<Id>();
    const isLoading = useStore(getUsersById.pending);
    const user = useStore(userInfo);
    const responseErrors = useStore(errors);

    const message = responseErrors === null ? userNotFoundMessage : errorLoadingMessage;

    useEffect(() => {
        if (id) {
            getUsersById(id);
        }

        return () => resetSingleUser();
    }, [id]);

    return (
        <MainLayout>
            <Section>
                <Breadcrumb />
            </Section>

            <Section>
                <Title>User info</Title>
            </Section>

            {isLoading ? (
                <Section alignCenter justifyCenter height="280px">
                    <Loader />
                </Section>
            ) : (
                <Section>
                    {user ? (
                        user.remoteUserId ? (
                            <RemoteUserDescription {...user} />
                        ) : (
                            <UserDescription {...user} />
                        )
                    ) : (
                        <Section justifyCenter marginTop="15px">
                            <Empty description={message} />
                        </Section>
                    )}
                </Section>
            )}
        </MainLayout>
    );
};
