import { Button } from 'components/ui/Button';
import { Checkbox } from 'components/ui/Checkbox';
import { ModalWrapper } from 'components/wrappers/ModalWrapper';
import { useStore } from 'effector-react';
import React from 'react';
import { kickUserModal, modalEvents } from 'stores/kickUserModal';
import { singleCollaborationStores } from 'stores/singleCollaboration';
import { Buttons, Description, Title } from './styles';

enum KickoutReasonType {
    General = 1,
    Language = 2
}

const { singleCollaborationData } = singleCollaborationStores;
const { closeModal, kickUserFromCollab, setReason } = modalEvents;

export const KickUserModal = () => {
    const { visible, userName, kickoutReason } = useStore(kickUserModal);
    const collaborationData = useStore(singleCollaborationData);

    const onGeneralReasonChange = () => {
        if (kickoutReason === KickoutReasonType.General) {
            setReason(null);
        } else {
            setReason(KickoutReasonType.General);
        }
    };

    const onLangReasonChange = () => {
        if (kickoutReason === KickoutReasonType.Language) {
            setReason(null);
        } else {
            setReason(KickoutReasonType.Language);
        }
    };

    const handleRemove = () => kickUserFromCollab();

    return (
        <ModalWrapper visible={visible} width="400px" onClose={closeModal}>
            <Title>
                Remove {userName} from the {collaborationData?.name} collaboration.
            </Title>
            <Description>Choose a reason to remove a from a collaboration:</Description>
            <div>
                <Checkbox checked={kickoutReason === 2} onChange={onLangReasonChange}>
                    User's language doesn't match collaboration language
                </Checkbox>
                <Checkbox checked={kickoutReason === 1} onChange={onGeneralReasonChange}>
                    General
                </Checkbox>
            </div>
            <Buttons>
                <Button onClick={() => closeModal()}>Cancel</Button>
                <Button buttonType="red" disabled={!kickoutReason} onClick={handleRemove}>
                    Remove
                </Button>
            </Buttons>
        </ModalWrapper>
    );
};
