import { WindowLoader } from 'components/common/WindowLoader';
import { CardCatalogGrid } from 'components/common/CatalogGrid/styles';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/grid/Empty';
import { HiddenScrollBlock } from 'constants/styles';
import { useStore } from 'effector-react';
import { organizationNotFoundMessage } from 'pages/Organizations/constants';
import { OrganizationCard } from 'pages/Organizations/OrganizationCard';
import { OrganizationsFilter } from 'pages/Organizations/OrganizationsFilter';
import { Content } from 'pages/Organizations/styles';
import React, { useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { organizationsEffects, organizationsEvents, organizationsStores } from 'stores/organizations';

const { organizations, errors, hasNext } = organizationsStores;
const { getOrganizations, organizationsLoadMore } = organizationsEffects;
const { loadMore, getOrganizationsWithDefaultParams } = organizationsEvents;

export const Organizations = () => {
    const { items, totalRecords } = useStore(organizations);
    const hasNextPage = useStore(hasNext);
    const isLoading = useStore(getOrganizations.pending);
    const isMoreLoading = useStore(organizationsLoadMore.pending);
    const responseErrors = useStore(errors);

    const [loadRef] = useInfiniteScroll({
        loading: isLoading || isMoreLoading,
        hasNextPage,
        onLoadMore: () => loadMore(),
        disabled: responseErrors !== null,
        rootMargin: '0px'
    });

    useEffect(() => {
        getOrganizationsWithDefaultParams();
    }, []);

    return (
        <MainLayout>
            <OrganizationsFilter totalRecords={totalRecords} />
            <Content alignCenter noWrap height="100%" justifyCenter={!items?.length} marginTop="15px" width="100%">
                {(isLoading || isMoreLoading) && <WindowLoader />}
                {items?.length ? (
                    <>
                        <CardCatalogGrid>
                            {items.map(item => (
                                <OrganizationCard {...item} key={item.organizationId} />
                            ))}
                        </CardCatalogGrid>
                        <HiddenScrollBlock ref={loadRef} />
                    </>
                ) : (
                    <Empty description={organizationNotFoundMessage} />
                )}
            </Content>
        </MainLayout>
    );
};
