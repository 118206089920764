import { Column } from 'components/wrappers/flexWrapper/styles';
import { ModalWrapper } from 'components/wrappers/ModalWrapper';
import { black } from 'constants/styles';
import { device } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const StatusInfoInner = styled(Column)`
    ${flexCenter};
    width: 100%;
`;

export const Title = styled.h4`
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
    color: ${black};
`;

export const Text = styled.p`
    max-width: 230px;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: ${black};
`;

export const StyledModalWrapper = styled(ModalWrapper)`
    padding-left: 42px;
    padding-right: 42px;

    @media ${device.laptop} {
        padding-left: 86px;
        padding-right: 86px;
    }
`;

export const TextWrapper = styled.div`
    margin-bottom: 24px;
`;

export const TitleWrapper = styled.div`
    margin-bottom: 8px;
`;
