import { WomIcon } from 'assets/icons';
import { PropertyBlock } from 'components/common/PropertyBlock';
import { CardWrapper } from 'components/wrappers/CardWrapper/styles';
import { GridWrapper } from 'components/wrappers/GridWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { collaborationCountStatusesUI } from 'constants/defaults';
import { organizationsLink } from 'constants/routes';
import {
    copyOrganizationIdMessage,
    copyWalletIdMessage,
    copyWebsiteLinkMessage
} from 'pages/Organizations/OrganizationCard/constants';
import { CardTitle, CollaborationsDataWrapper } from 'pages/Organizations/OrganizationCard/styles';
import { ScrollableTags } from 'pages/Organizations/ScrollableTags';
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import { useMediaQuery } from 'react-responsive';
import { xs } from 'constants/styles';
import { Button } from 'components/ui/Button';
import { Section } from 'components/wrappers/flexWrapper';

export const OrganizationCard: FC<BUCM.OrganizationResponse> = ({
    organizationId,
    title,
    walletId,
    adminIds,
    websiteLink,
    womInEscrowValue,
    collaborationStates
}) => {
    const history = useHistory();
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    const moreInfoHandleClick = () => history.push(`${organizationsLink}/${organizationId}`);

    const hasCollaborations = collaborationStates && Object.values(collaborationStates).some(val => val > 0);

    return (
        <CardWrapper>
            <CardTitle>{title}</CardTitle>
            <GridWrapper columns={2} marginBottom="16px">
                <PropertyBlock
                    copyable
                    isIdType
                    subtitle={organizationId}
                    successText={copyOrganizationIdMessage}
                    title="Organization ID"
                />
                <PropertyBlock
                    copyable
                    isIdType
                    subtitle={walletId}
                    successText={copyWalletIdMessage}
                    title="Wallet ID"
                />
            </GridWrapper>
            <GridWrapper columns={2} marginBottom="16px">
                <PropertyBlock
                    copyable
                    subtitle={websiteLink || '-'}
                    successText={copyWebsiteLinkMessage}
                    title="Website"
                />
                <PropertyBlock icon={<WomIcon />} subtitle={`${womInEscrowValue}` || '0'} title="Wom in Escrow" />
            </GridWrapper>

            {hasCollaborations ? (
                <CollaborationsDataWrapper>
                    {collaborationCountStatusesUI.map(({ status, title }) => {
                        let count = 0;
                        if (!!collaborationStates) {
                            if (Array.isArray(status)) {
                                count = status.reduce(
                                    (total, currentStatus) => total + collaborationStates[currentStatus],
                                    0
                                );
                            } else {
                                count = collaborationStates[status];
                            }
                        }

                        return count ? <PropertyBlock key={title} subtitle={`${count}`} title={title} /> : null;
                    })}
                </CollaborationsDataWrapper>
            ) : null}

            <MarginWrapper marginBottom="20px">
                <ScrollableTags data={adminIds} title="Admins" />
            </MarginWrapper>

            <Section alignCenter justifyCenter marginTop="30px">
                <Button wide={isMobile} onClick={moreInfoHandleClick}>
                    More Info
                </Button>
            </Section>
        </CardWrapper>
    );
};
