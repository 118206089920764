import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';
import { KickUserModalProps } from 'types/modals';
import { collaborationsEffects } from './collaborationEnrollments';

const { getEnrollments } = collaborationsEffects;

const initialState: KickUserModalProps = {
    visible: false,
    userName: '',
    participantId: null,
    collaborationId: null
};

const openModal = createEvent<KickUserModalProps>();
const closeModal = createEvent();
const setReason = createEvent<BUCM.KickOutReason | null>();

export const kickUserModal = createStore<KickUserModalProps>(initialState)
    .on(openModal, (state, newState) => ({ ...state, ...newState, visible: true }))
    .on(closeModal, () => initialState)
    .on(setReason, (state, payload) => ({ ...state, kickoutReason: payload }));

const kickUserFromCollab = createEffect({
    handler: async () => {
        try {
            const { participantId, collaborationId, kickoutReason } = kickUserModal.getState();
            if (participantId && collaborationId && kickoutReason) {
                const { isSuccess } = await API.collaborations.kickUserFromCollaboration({
                    participantId,
                    collaborationId,
                    data: {
                        kickOutReason: kickoutReason
                    }
                });
                if (isSuccess) {
                    closeModal();
                    getEnrollments({
                        collaborationId
                    });
                }
            }
        } catch (error) {
            console.error(error);
            closeModal();
        }
    }
});

export const modalEvents = {
    openModal,
    closeModal,
    kickUserFromCollab,
    setReason
};
