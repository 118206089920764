import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { grey2 } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled(ContentWrapper)`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    padding: 15px 20px;
    background-color: ${grey2};

    @media ${device.small} {
        margin-bottom: 22px;
    }
`;
