import { white } from 'constants/styles';
import styled from 'styled-components';

export const Breadcrumbs = styled.ol`
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 16px;
    list-style: none;
`;

interface Props {
    active?: boolean;
}

export const BreadcrumbItem = styled.li<Props>`
    ${({ active }) => active && `color: ${white}`};

    &:not(:first-of-type) {
        padding-left: 8px;

        &:before {
            padding-right: 8px;
            color: ${white};
            content: '/';
        }
    }
`;
