import { SearchInput } from 'components/form/SearchInput';
import { SearchSelectWrapper, SelectWrapper } from 'components/form/SearchSelectInput/styles';
import { Select } from 'components/ui/Select';
import React, { useEffect, useState } from 'react';

interface Options {
    value: string;
    label: string;
}

interface Items {
    option: Options;
    onSearch: (value: string) => void;
}

interface Props {
    items: Items[];
    defaultSearchText?: string;
    onSearch?: (searchText: string) => void;
}

export const SearchSelectInput = ({ items, defaultSearchText = '', onSearch }: Props) => {
    const [searchText, setSearchText] = useState(defaultSearchText);
    const [selectorValue, setSelectorValue] = useState(items[0].option);
    const placeholder = `Search by ${selectorValue.label}`;
    const options = items.map(it => it.option);

    const onChange = (value: Options | null) => {
        if (value) setSelectorValue(value);
    };

    const onSearchSubmit = (searchText: string) => {
        const item = items.find(it => it.option.value === selectorValue.value);
        if (item) item.onSearch(searchText);
        onSearch?.(searchText);
    };

    useEffect(() => {
        setSearchText(defaultSearchText);
    }, [defaultSearchText]);

    return (
        <SearchSelectWrapper>
            <SearchInput defaultValue={searchText} placeholder={placeholder} onSearch={onSearchSubmit} />
            <SelectWrapper flexShrink="0">
                <Select options={options} value={selectorValue} onChange={onChange} />
            </SelectWrapper>
        </SearchSelectWrapper>
    );
};
