import { createEvent, createStore } from 'effector';

interface Props {
    visible?: boolean;
    video: BUCM.MediaAssetResponse | null;
}

const initialVideoModal: Props = {
    visible: false,
    video: null
};

const openVideoModal = createEvent<Props>();
const closeVideoModal = createEvent();

export const videoModal = createStore<Props>(initialVideoModal)
    .on(openVideoModal, (state, newState) => ({ ...state, ...newState, visible: true }))
    .on(closeVideoModal, () => initialVideoModal);

export const videoModalEvents = {
    openVideoModal,
    closeVideoModal
};
