import axios from './axios';

export const getSubmissions = (
    collaborationId: string,
    data: Paths.Collaborations$CollaborationIdMediaAssets.Get.QueryParameters
) =>
    axios<BUCM.MediaAssetsResponseApiResponse>({
        url: `/collaborations/${collaborationId}/media-assets`,
        method: 'get',
        params: data
    });

export const getFeedback = (assetId: string) =>
    axios<BUCM.QueryMediaAssetFeedbacksResponseApiResponse>({
        url: `/media-assets/${assetId}/feedback`,
        method: 'get'
    });

export const acceptFeedback = (assetId: string, feedbackId: string, data: BUCM.UpdateMediaAssetFeedbackRequest) =>
    axios<BUCM.StringApiResponse>({
        url: `/media-assets/${assetId}/feedback/${feedbackId}`,
        method: 'put',
        data
    });
