import { FlexGrow } from 'components/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const SearchSelectWrapper = styled.div`
    width: 100%;
    max-width: 730px;
    ${flexCenter};
    gap: 15px;

    @media ${device.mobile} {
        max-width: 100%;
        flex-wrap: wrap;
    }
`;

export const SelectWrapper = styled(FlexGrow)`
    width: 100%;
    max-width: 250px;

    @media ${device.mobile} {
        max-width: 100%;
    }
`;
