import { dashboardLink } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import { userStores } from 'stores/user';

export const PublicRoute: FC<RouteProps> = props => {
    const { hasAccess } = useStore(userStores.auth);

    if (!hasAccess) {
        return <Route {...props} />;
    } else return <Redirect to={dashboardLink} />;
};
