import { black } from 'constants/styles';
import styled from 'styled-components';

export const Title = styled.h2`
    margin: 0 0 24px 0;
    color: ${black};
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
`;

export const Description = styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${black};
    margin-bottom: 8px;
`;

export const Buttons = styled.div`
    margin-top: 37px;
    display: flex;
    justify-content: center;

    & > button {
        margin-right: 24px;

        &:last-child {
            margin-right: 0;
        }
    }
`;
