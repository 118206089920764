import { Avatar } from 'components/common/Avatar';
import { DescriptionProperty } from 'components/common/DescriptionProperty';
import { DescriptionContent } from 'components/common/DescriptionContent';
import {
    formatDate,
    successfullyCopiedEmail,
    successfullyCopiedRemoteUserId,
    successfullyCopiedUserId,
    successfullyCopiedUserName
} from 'pages/User/UserDescription/constants';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Column, Section } from 'components/wrappers/flexWrapper';
import { grey2 } from 'constants/styles/colors';
import { UserDescriptionWrapper } from 'pages/User/styles';
import { getRole } from 'pages/Users/UserCard/constants';
import React, { FC } from 'react';
import { getFormattedDate } from 'utils/formateDate';
import { Tag } from 'components/ui/Tag';
import { GenderState } from 'constants/defaults/users';
import { getAge } from 'utils/getAge';

export const RemoteUserDescription: FC<BUCM.UserResponse> = ({
    userId,
    remoteUserId,
    username,
    utcCreated,
    email,
    roles,
    isAccountVerified,
    profileImageUrl,
    gender,
    dateOfBirth
}) => {
    const age = dateOfBirth && getAge(new Date(dateOfBirth)).toString();

    return (
        <ContentWrapper backgroundColor={grey2} marginBottom="30px" padding="15px 20px" width="100%">
            <UserDescriptionWrapper>
                <Column>
                    <Avatar isVerified={isAccountVerified} src={profileImageUrl || ''} />
                </Column>

                <Column width="100%">
                    <Section gap="16px">
                        <DescriptionProperty
                            copyable={!!username}
                            subtitle={username ? `@${username}` : '-'}
                            successText={successfullyCopiedUserName}
                            title="Username"
                        />

                        <DescriptionProperty subtitle={GenderState[gender ? gender : 0]} title="Gender" />

                        <DescriptionProperty subtitle={age || '-'} title="Age" />

                        <DescriptionProperty
                            copyable={!!userId}
                            subtitle={userId || '-'}
                            successText={successfullyCopiedUserId}
                            title="ID"
                        />

                        <DescriptionProperty
                            subtitle={utcCreated ? getFormattedDate(utcCreated, formatDate) : '-'}
                            title="Created Account"
                        />

                        <DescriptionProperty
                            copyable={!!email}
                            subtitle={email || '-'}
                            successText={successfullyCopiedEmail}
                            title="Email"
                        />

                        <DescriptionProperty
                            copyable={!!remoteUserId}
                            subtitle={remoteUserId || '-'}
                            successText={successfullyCopiedRemoteUserId}
                            title="Remote User ID"
                        />
                    </Section>

                    {roles && roles.length > 0 && (
                        <Section marginTop="24px">
                            <DescriptionContent title="ROLES">
                                {roles.map(role => (
                                    <Tag key={role}>{getRole(role)}</Tag>
                                ))}
                            </DescriptionContent>
                        </Section>
                    )}
                </Column>
            </UserDescriptionWrapper>
        </ContentWrapper>
    );
};
