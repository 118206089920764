export const padding = '10px';
export const borderWidth = '1px';
export const borderRadius = '4px';
export const opacity = 0.8;
export const disabledOpacity = 0.5;
export const textInfoFontSize = '14px';
export const textInfoLineHeight = '16px';

export const featureHeight = '40px';
export const hashtagsSliderHeight = '30px';

export const xxs = '460px';
export const xs = '480px';
export const sm = '576px';
export const md = '768px';
export const lg = '992px';
export const xl = '1200px';
export const xxl = '1600px';

export const xxs_1 = '459px';
export const xs_1 = '479px';
export const sm_1 = '575px';
export const md_1 = '767px';
export const lg_1 = '991px';
export const xl_1 = '1199px';
export const xxl_1 = '1599px';

export const scrollBarWidth = '4px';
