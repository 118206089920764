import { createEvent, createStore } from 'effector';
import { StatusModalProps } from 'types/modals';

const initialStatusModal: StatusModalProps = {
    visible: false,
    icon: undefined,
    title: '',
    text: '',
    cancelText: 'Ok'
};

const openStatusModal = createEvent<StatusModalProps>();
const closeStatusModal = createEvent();

const statusModal = createStore<StatusModalProps>(initialStatusModal)
    .on(openStatusModal, (state, newState) => ({ ...state, ...newState, visible: true }))
    .on(closeStatusModal, () => initialStatusModal);

export const modalEvents = {
    openStatusModal,
    closeStatusModal
};

export const modalStores = {
    statusModal
};
