import { Empty } from 'components/grid/Empty';
import { DefaultRecordType } from 'rc-table/lib/interface';
import { TableProps } from 'rc-table/lib/Table';
import React from 'react';
import { EmptyWrapper, StyledTable } from './styles';

interface Props extends TableProps<DefaultRecordType> {
    emptyLayoutMessage: string;
    rowPointer?: boolean;
}

export const Table = ({ columns, data, emptyLayoutMessage, rowPointer = false, ...props }: Props) => (
    <StyledTable
        columns={columns}
        data={data}
        emptyText={
            <EmptyWrapper>
                <Empty description={emptyLayoutMessage} />
            </EmptyWrapper>
        }
        isEmpty={!data || data?.length === 0}
        rowPointer={rowPointer}
        {...props}
    />
);
