import { bullzLogo, instagramIcon, tiktokIcon, twitchIcon, twitterIcon, youtubeIcon } from 'assets/icons';
import { CustomImg } from 'components/ui/CustomImg';
import { ExternalNetworkKey } from 'constants/defaults';
import React, { FC } from 'react';

interface IconProps {
    networkTitle: string;
}

export const NetworkIcon: FC<IconProps> = ({ networkTitle }) => {
    switch (networkTitle?.toLowerCase()) {
        case ExternalNetworkKey.Bullz:
            return <CustomImg alt="Bullz" height="18px" src={bullzLogo} width="34px" />;
        case ExternalNetworkKey.Instagram:
            return <CustomImg alt="Instagram" height="14px" src={instagramIcon} width="14px" />;
        case ExternalNetworkKey.Twitter:
            return <CustomImg alt="Twitter" height="14px" src={twitterIcon} width="14px" />;
        case ExternalNetworkKey.Tiktok:
            return <CustomImg alt="Tiktok" height="14px" src={tiktokIcon} width="14px" />;
        case ExternalNetworkKey.YouTube:
            return <CustomImg alt="YouTube" height="14px" src={youtubeIcon} width="fit-content" />;
        case ExternalNetworkKey.Twitch:
            return <CustomImg alt="Twitch" height="14px" src={twitchIcon} width="14px" />;
        default:
            return null;
    }
};
