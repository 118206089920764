import React, { ChangeEvent, FC, PropsWithChildren } from 'react';
import { Span } from 'components/typography/Span';
import { black } from 'constants/styles';
import { Disabled, Name } from 'types/interfaces/form';
import { HiddenCheckbox, Label, StyledCheckbox } from './styles';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { CheckIcon } from 'assets/icons';

export interface CheckboxProps extends Disabled, PropsWithChildren, Partial<Name> {
    checked?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: FC<CheckboxProps> = ({ checked, disabled, onChange, children, name }) => (
    <Label>
        <StyledCheckbox checked={checked} disabled={disabled}>
            {checked && !disabled && <CheckIcon />}
        </StyledCheckbox>
        <HiddenCheckbox checked={checked} name={name} type="checkbox" onChange={onChange} />
        <MarginWrapper marginLeft="8px">
            <Span color={black} fontSize="14px">
                {children}
            </Span>
        </MarginWrapper>
    </Label>
);
