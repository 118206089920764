import {
    bullzIcon,
    emptyUserLogo,
    instagramIcon,
    tiktokIcon,
    twitchIcon,
    twitterIcon,
    youtubeIcon
} from 'assets/icons';
import { CustomImg } from 'components/ui/CustomImg';
import { Column, Row } from 'components/wrappers/flexWrapper';
import { ExternalNetworkKey } from 'constants/defaults';
import { getWebPlayerUserPageUrl } from 'constants/global';
import { md } from 'constants/styles';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { externalNetworksStores } from 'stores/externalNetworks';
import { userExternalNetworksEffects, userExternalNetworksStores } from 'stores/userExternalNetwork';
import { formatNumberMask } from 'utils/formatNumberMask';
import { Delimiter, Followers, Networks, Span, StyledImage, UserInfo } from './styles';

interface IconProps {
    network: string | null;
}

const Icon: FC<IconProps> = ({ network }) => {
    switch (network) {
        case ExternalNetworkKey.Bullz:
            return <CustomImg alt="Bullz" height="14px" src={bullzIcon} width="14px" />;
        case ExternalNetworkKey.Instagram:
            return <CustomImg alt="Instagram" height="14px" src={instagramIcon} width="14px" />;
        case ExternalNetworkKey.Twitter:
            return <CustomImg alt="Twitter" height="14px" src={twitterIcon} width="14px" />;
        case ExternalNetworkKey.Tiktok:
            return <CustomImg alt="Tiktok" height="14px" src={tiktokIcon} width="14px" />;
        case ExternalNetworkKey.YouTube:
            return <CustomImg alt="YouTube" height="14px" src={youtubeIcon} width="fit-content" />;
        case ExternalNetworkKey.Twitch:
            return <CustomImg alt="Twitch" height="14px" src={twitchIcon} width="14px" />;
        default:
            return null;
    }
};

interface Props {
    video: BUCM.MediaAssetResponse;
}

export const CreatorInfo: FC<Props> = ({ video }) => {
    const userNetworks = useStore(userExternalNetworksStores.$userExternalNetworks);
    const externalNetworks = useStore(externalNetworksStores.externalNetworks);
    const isTablet = useMediaQuery({ query: `(max-width: ${md})` });
    const { creatorProfile } = video;
    const webPlayerUserPageLink = creatorProfile?.remoteCreatorId
        ? getWebPlayerUserPageUrl(creatorProfile?.remoteCreatorId)
        : undefined;

    useEffect(() => {
        if (video?.creatorId) userExternalNetworksEffects.getUserExternalNetworks(video?.creatorId);
    }, [video]);

    const findNetwork = (networkId: string | null | undefined) =>
        externalNetworks.find(network => network.id === networkId)?.title?.toLowerCase() || null;

    return (
        <Column>
            <UserInfo>
                <StyledImage
                    alt="Profile picture"
                    height="36px"
                    src={creatorProfile?.profileImageUrl || emptyUserLogo}
                    width="36px"
                />
                <Span>
                    <a href={webPlayerUserPageLink} rel="noopener noreferrer" target="_blank">
                        {creatorProfile?.userName && `@${creatorProfile.userName}`}
                    </a>
                </Span>
            </UserInfo>
            {!isTablet && (
                <>
                    <Delimiter />
                    <Networks>
                        {userNetworks?.map(network => (
                            <Row key={network.networkId}>
                                <Icon network={findNetwork(network.networkId)} />
                                <Followers>{`${formatNumberMask(
                                    network?.engagement?.followersCount || 0
                                )} followers`}</Followers>
                            </Row>
                        ))}
                    </Networks>
                </>
            )}
        </Column>
    );
};
