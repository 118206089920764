import { Column } from 'components/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const SearchSelectWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 30px;
    padding-top: 15px;

    @media ${device.mobile} {
        flex-direction: column;
        gap: 15px;
    }
`;

export const FilterSelectWrapper = styled(Column)`
    width: 100%;
    max-width: 280px;

    @media ${device.mobile} {
        width: 100%;
        max-width: 100%;
    }
`;

export const FilterTitle = styled.h5`
    margin-bottom: 15px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
`;
