/** Libs */
import React, { FC } from 'react';

/** Components, assets */
import { StatusesWrapper } from './StatusesWrapper';
import { noneStatus, pendingStatus, awaitingStatus, endedStatus } from 'assets/icons';

interface Props {
    status: BUCM.SubmissionState;
}

const getIcon = (status: BUCM.SubmissionState): string => {
    switch (status) {
        case 0:
            return noneStatus;
        case 10:
            return awaitingStatus;
        case 20:
            return pendingStatus;
        case 1000:
            return endedStatus; // not used; Ended reasons are used
        default:
            return noneStatus;
    }
};

const getPopoverText = (status: BUCM.SubmissionState): string => {
    switch (status) {
        case 0:
            return '-';
        case 10:
            return 'Waiting for video submission';
        case 20:
            return 'Video under review';
        case 1000:
            return 'Video published'; // not used; Ended reasons are used
        default:
            return '-';
    }
};

export const SubmissionStatuses: FC<Props> = ({ status }) => (
    <StatusesWrapper icon={getIcon(status)} popoverText={getPopoverText(status)} />
);
