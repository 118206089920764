import { CopyButton, CopyButtonProps } from 'components/common/CopyButton';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Href } from 'types';
import { LinkWrapper } from './styles';

interface Props extends Href, CopyButtonProps {
    children: ReactNode;
}

export const InternalCopyLink: FC<Props> = ({ successText, subject, href, children }) => (
    <LinkWrapper>
        <Link to={href}>{children}</Link>
        <MarginWrapper marginLeft="16px">
            <CopyButton subject={subject} successText={successText} />
        </MarginWrapper>
    </LinkWrapper>
);
