import { createEffect, createEvent, createStore } from 'effector';
import { API } from 'services';

// Events
const setActiveTab = createEvent<number>();
const clearSingleCollaborationData = createEvent();

type CollaborationResponse = BUCM.CollaborationResponse | null;

const getCollaborationById = createEffect({
    handler: async ({ collaborationId }: Paths.Collaborations$CollaborationId.Patch.PathParameters) => {
        try {
            const { isSuccess, data } = await API.collaborations.getCollaborationById({
                collaborationId
            });

            if (isSuccess) {
                return data;
            }

            return null;
        } catch {
            return null;
        }
    }
});

// Stores
const tab = createStore(0).on(setActiveTab, (_, id) => id);

const singleCollaborationData = createStore<CollaborationResponse>({})
    .on(getCollaborationById.doneData, (_, newState) => newState)
    .reset(clearSingleCollaborationData);

// Exports

const singleCollaborationEvents = {
    setActiveTab,
    clearSingleCollaborationData
};

const singleCollaborationStores = {
    tab,
    setActiveTab,
    clearSingleCollaborationData,
    singleCollaborationData
};

const singleCollaborationEffects = {
    getCollaborationById
};

export { singleCollaborationStores, singleCollaborationEffects, singleCollaborationEvents };
