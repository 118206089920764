import { transitionTime } from 'constants/styles';
import { errorColor, grey6, primaryColor, secondaryColor, white } from 'constants/styles/colors';
import styled, { css } from 'styled-components';
import { Disabled, WithError } from 'types/interfaces/form';
import { MarginBottom } from 'types/interfaces/styles';

export const InputWrapper = styled.div<MarginBottom>`
    position: relative;
    width: 100%;
    max-width: 100%;
    margin-bottom: ${({ marginBottom }) => marginBottom || '22px'};
`;

export const StyledLabel = styled.label`
    color: ${white};
    font-size: 13px;
    line-height: 20px;
    font-family: 'Poppins', sans-serif;
`;

export const Message = styled.span<Pick<WithError, 'error'>>`
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.075px;
    color: ${({ error }) => (error ? errorColor : grey6)};
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
`;

interface StyledInputProps extends Pick<WithError, 'error'>, Disabled {
    isPasswordInput?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
    width: 100%;
    padding: 8px;
    font-size: 14px;
    line-height: 21px;
    margin-top: 12px;
    color: rgba(255, 255, 255, 0.8);
    border: 1px solid ${({ error }) => (error ? errorColor : secondaryColor)};
    border-radius: 6px;
    background: transparent;
    outline: none;
    appearance: none;

    &::placeholder {
        color: rgba(255, 255, 255, 0.3);
    }

    &:focus-visible {
        border-color: ${primaryColor};
    }

    ${({ disabled }) =>
        disabled &&
        css`
            cursor: not-allowed;
        `};
`;

export const IconWrapper = styled.div`
    width: 30px;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${grey6};
    transition: color ${transitionTime};
    cursor: pointer;

    &:hover {
        color: ${white};
    }
`;

export const InputPrefix = styled.div<StyledInputProps>`
    display: inline-flex;
    height: 100%;
    padding-bottom: 10px;
    padding-right: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    border-bottom: 2px solid ${({ error }) => (error ? errorColor : grey6)};
`;
