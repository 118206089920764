import { BreadcrumbItem, Breadcrumbs } from 'components/grid/Breadcrumb/styles';
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils/usefulFunctions';

export const Breadcrumb = () => {
    const location = useLocation();
    const locationsArray = location.pathname.replace('/', '').split('/');

    return (
        <Breadcrumbs>
            {locationsArray.map((location, index) =>
                index === locationsArray.length - 1 ? (
                    <BreadcrumbItem key={location} active>
                        {capitalizeFirstLetter(location)}
                    </BreadcrumbItem>
                ) : (
                    <BreadcrumbItem key={location}>
                        <NavLink to={'/' + locationsArray.slice(0, index - 1).join('/')}>
                            {capitalizeFirstLetter(location)}
                        </NavLink>
                    </BreadcrumbItem>
                )
            )}
        </Breadcrumbs>
    );
};
