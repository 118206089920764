export const baseURL = process.env.REACT_APP_API_URL as string;
export const appVersion = process.env.REACT_APP_VERSION as string;

export const isDev = process.env.REACT_APP_API_URL === 'https://bcm-dev.bullz.io/';

export const bullzWepPlayerLinkDev = 'https://bullz.player.dev-stage.su/';
export const bullzWepPlayerLinkClientProd = 'https://player.bullz.com/';
const userPageUrl = isDev ? bullzWepPlayerLinkDev : bullzWepPlayerLinkClientProd;

export const getWebPlayerUserPageUrl = (userId: string) => `${userPageUrl}profile/${userId}`;

export const environment = isDev ? 'DEV' : 'PROD';

export const administratorTypeName = 'administrator';
export const storageName = 'bucmasUser';
export const bufferStorageName = 'bucmasBuffer';
export const formName = 'basic';

export const boundaryNumber = 10000;
export const quantityNumbersAfterComma = 1;
export const numbersAfterDotWom = 3;

export const noop = () => {};

export const dateFormat = 'dd-MM-yyyy';

export const dateAndTimeFormat = 'dd-MM-y (HH:mm:s)';

export const debounceTime = 500;
