import { Row } from 'components/wrappers/flexWrapper';
import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
    ${flexCenter};
    height: 200px;
`;

export const Title = styled.h4`
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
    margin-bottom: 15px;
`;

export const NetworkIconWrapper = styled(Row)`
    :not(:first-child) {
        margin-left: 12px;
    }
`;
