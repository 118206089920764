import * as collaborations from './collaborations';
import * as externalNetworks from './externalNetworks';
import * as mediaAssets from './mediaAssets';
import * as organizations from './organizations';
import * as user from './user';
import * as users from './users';
import * as userExternalNetwork from './userExternalNetwork';

export const API = {
    user,
    organizations,
    collaborations,
    externalNetworks,
    mediaAssets,
    users,
    userExternalNetwork
};
