import { defaultEnrollmentsParams, errorMessage } from 'constants/defaults';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { API } from 'services';

// Events

const loadMore = createEvent<string>();
const setErrors = createEvent<BUCM.ErrorResponse | string | null>();
const updateEnrollmentsParams = createEvent<Paths.Collaborations$CollaborationIdParticipations.Get.QueryParameters>();
const setCollaborationsErrors = createEvent<BUCM.ErrorResponse | string | null>();
const resetCollaborationsErrors = createEvent();

// Effects

const getEnrollments = createEffect({
    handler: async ({ collaborationId }: Paths.Collaborations$CollaborationId.Patch.PathParameters) => {
        try {
            const { isSuccess, data, error } = await API.collaborations.getEnrollments(
                collaborationId,
                defaultEnrollmentsParams
            );
            if (isSuccess) {
                return data;
            }

            setCollaborationsErrors(error || errorMessage);
            return {};
        } catch (error) {
            setCollaborationsErrors(errorMessage);
            return {};
        }
    }
});

interface LoadMoreEnrollmentsParams {
    collaborationId: string;
    params: BUCM.ParticipationsResponse;
}

const loadMoreEnrollments = createEffect({
    handler: async ({ collaborationId, params }: LoadMoreEnrollmentsParams) => {
        try {
            const { isSuccess, data, error } = await API.collaborations.getEnrollments(collaborationId, {
                ...defaultEnrollmentsParams,
                pageIndex: params.pageIndex !== undefined ? params.pageIndex + 1 : defaultEnrollmentsParams.pageIndex
            });

            if (isSuccess) {
                resetCollaborationsErrors();
                return data;
            }

            setCollaborationsErrors(error || errorMessage);
            return {};
        } catch (error) {
            setCollaborationsErrors(errorMessage);
            return {};
        }
    }
});

//Stores

const enrollmentsLoading = createStore<boolean>(false).on(getEnrollments.pending, (_, payload) => payload);

const enrollmentsParams = createStore<Paths.Collaborations$CollaborationIdParticipations.Get.QueryParameters>(
    defaultEnrollmentsParams
).on(updateEnrollmentsParams, (state, newState) => ({
    ...state,
    ...newState
}));

const enrollmentsLoadMoreLoading = createStore<boolean>(false).on(loadMoreEnrollments.pending, (_, payload) => payload);

const enrollments = createStore<BUCM.ParticipationsResponse>({})
    .on(getEnrollments.doneData, (_, newState) => newState)
    .on(loadMoreEnrollments.doneData, ({ items }, newState) => ({
        ...newState,
        items: [...(items || []), ...(newState?.items || [])]
    }));

const errors = createStore<BUCM.ErrorResponse | string | null>(null).on(setErrors, (_, newState) => newState);

sample({
    source: [enrollments, enrollmentsParams],
    clock: loadMore,
    fn: ([enrollments, enrollmentsParams], collaborationId) => ({
        collaborationId,
        state: enrollments,
        params: enrollmentsParams
    }),
    target: loadMoreEnrollments
});

// Exports

const collaborationsEvents = {
    loadMore
};

const collaborationsEffects = {
    getEnrollments
};

const collaborationsStores = {
    enrollments,
    enrollmentsLoading,
    errors,
    enrollmentsLoadMoreLoading
};

export { collaborationsStores, collaborationsEffects, collaborationsEvents };
