import { Row } from 'components/wrappers/flexWrapper';
import { grey2 } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
    justify-content: space-between;
    align-items: flex-end;
    padding: 30px 24px 37px;
    background-color: ${grey2};
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    @media ${device.tablet} {
        padding: 15px 12px 18px;
    }
`;
