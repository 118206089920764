import {
    PARTICIPATION_ENDED_REASONS,
    PARTICIPATION_STATES,
    PUBLISHING_ENDDED_REASONS,
    PUBLISHING_STATES,
    SUBMISSION_ENDDED_REASONS,
    SUBMISSION_STATES
} from 'constants/defaults/statuses';

export const getParticipationStatus = (
    state?: BUCM.ParticipationState,
    endedReason?: BUCM.ParticipationEndedReason
) => {
    if (!state) return '-';

    if (PARTICIPATION_STATES(state)) {
        if (state === 1000) return PARTICIPATION_ENDED_REASONS(endedReason || 0);
        return PARTICIPATION_STATES(state);
    }
    return '-';
};

export const getSubmissionStatus = (state?: BUCM.SubmissionState, endedReason?: BUCM.SubmissionEndedReason) => {
    if (!state) return '-';

    if (SUBMISSION_STATES(state)) {
        if (state === 1000) return SUBMISSION_ENDDED_REASONS(endedReason || 0);
        return SUBMISSION_STATES(state);
    }
    return '-';
};

export const getPublishingStatus = (
    state?: BUCM.PublishingState,
    endedReason?: BUCM.PublishingEndedReason,
    publishingNetwork?: BUCM.ExternalNetworkResponse
) => {
    if (!state) return '-';

    if (PUBLISHING_STATES(state)) {
        if (state === 1000) return PUBLISHING_ENDDED_REASONS(endedReason || 0, publishingNetwork);
        return PUBLISHING_STATES(state);
    }
    return '-';
};

export const getCollaborationPopoverText = (collabStatus: BUCM.CollaborationState) => {
    switch (collabStatus) {
        case 0:
            return 'None';
        case 10:
            return 'The Collaboration is not yet Published'; // not used
        case 20:
            return 'Users can enroll in the Collaboration, submit videos, and share to specified social platforms';
        case 30:
            return 'Collaboration is not opened for new participants. Waiting for final submissions';
        case 1000:
            return 'The Collaboration is ended';
        default:
            return collabStatus;
    }
};
