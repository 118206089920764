import 'assets/css/nucleo-icons.css';
import 'react-toastify/dist/ReactToastify.css';
import 'rc-tooltip/assets/bootstrap.css';
import {
    authLink,
    collaborationLink,
    collaborationsLink,
    dashboardLink,
    organizationLink,
    organizationsLink,
    userLink,
    usersLink
} from 'constants/routes';
import { GlobalStyle } from 'constants/styles';
import { Login } from 'pages/Auth';
import { Collaboration } from 'pages/Collaboration';
import { Collaborations } from 'pages/Collaborations';
import { Dashboard } from 'pages/Dashboard';
import { Organization } from 'pages/Organization';
import { Organizations } from 'pages/Organizations';
import { User } from 'pages/User';
import { Users } from 'pages/Users';
import React from 'react';
import { Redirect, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { PrivateRoute } from 'routes/PrivateRoute';
import { PublicRoute } from 'routes/PublicRoute';
import history from './browserHistory';
import { Message } from 'components/modals/Message';
import { StatusInfoModal } from 'components/modals/StatusInfoModal';

const App = () => (
    <>
        <GlobalStyle />
        <Message />
        <Router history={history}>
            <Switch>
                <PublicRoute exact component={Login} path={authLink} />
                <PrivateRoute exact component={Dashboard} path={dashboardLink} />
                <PrivateRoute exact component={Organizations} path={organizationsLink} />
                <PrivateRoute exact component={Organization} path={organizationLink} />
                <PrivateRoute exact component={Users} path={usersLink} />
                <PrivateRoute exact component={User} path={userLink} />
                <PrivateRoute exact component={Collaborations} path={collaborationsLink} />
                <PrivateRoute exact component={Collaboration} path={collaborationLink} />
                <Redirect to={authLink} />
            </Switch>
        </Router>
        <StatusInfoModal />
    </>
);

export default App;
