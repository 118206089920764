import { createGlobalStyle } from 'styled-components';
import { black, grey5, primaryColor, white } from './colors';
import { scrollBarWidth } from './sizes';

export const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;
    color: ${white};
    font-family: 'Poppins', sans-serif;
  }

  *, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  ::selection {
    background: ${primaryColor};
    color: ${white};
  }

   ::-webkit-scrollbar-track
   {
    background-color: transparent;
   }

   ::-webkit-scrollbar
   {
    width: ${scrollBarWidth};
    height: ${scrollBarWidth};
    background-color: transparent;
   }

   ::-webkit-scrollbar-thumb
   {
    background: ${grey5};
    border-radius: 2px;
   }

  #root {
    background-color: ${black};
    width: 100%;
    min-height: 100vh;
  }
`;
