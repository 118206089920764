import { WindowLoader } from 'components/common/WindowLoader';
import { CardCatalogGrid } from 'components/common/CatalogGrid/styles';
import { Column } from 'components/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/grid/Empty';
import { HiddenScrollBlock } from 'constants/styles/common';
import { useStore } from 'effector-react';
import { CollaborationCard } from 'pages/Collaborations/CollaborationCard';
import { CollaborationsFilterLayout } from 'pages/Collaborations/CollaborationsFilterLayout';
import React, { useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { collaborationsEffects, collaborationsEvents, collaborationsStores } from 'stores/collaborations';

const { getByDefaultParams, loadMore } = collaborationsEvents;
const { collaborations, errors } = collaborationsStores;

const { getCollaborations, collaborationsLoadMore } = collaborationsEffects;

export const Collaborations = () => {
    const isLoading = useStore(getCollaborations.pending);
    const isMoreLoading = useStore(collaborationsLoadMore.pending);
    const { items, hasNext } = useStore(collaborations);

    const responseErrors = useStore(errors);

    const [loadRef] = useInfiniteScroll({
        loading: isLoading || isMoreLoading,
        hasNextPage: hasNext || false,
        onLoadMore: () => loadMore(),
        disabled: responseErrors !== null,
        rootMargin: '0px'
    });

    useEffect(() => {
        getByDefaultParams();
    }, []);

    return (
        <MainLayout>
            <CollaborationsFilterLayout>
                <Column alignCenter noWrap height="100%" justifyCenter={!items?.length} marginTop="15px" width="100%">
                    {(isLoading || isMoreLoading) && <WindowLoader />}
                    {!!items && items?.length ? (
                        <>
                            <CardCatalogGrid>
                                {items?.map(item => (
                                    <CollaborationCard key={item.id} {...item} />
                                ))}
                            </CardCatalogGrid>

                            <HiddenScrollBlock ref={loadRef} />
                        </>
                    ) : (
                        <Empty description="Collaborations not found" />
                    )}
                </Column>
            </CollaborationsFilterLayout>
        </MainLayout>
    );
};
