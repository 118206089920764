import { grey, transitionTime, white } from 'constants/styles';
import { device } from 'constants/styles/media';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const TabsExtra = styled.div``;
export const TabsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 100;
`;

export const TabsButtons = styled.div`
    display: flex;
    overflow: auto hidden;
    padding-bottom: 10px;
    margin-right: 16px;

    &::-webkit-scrollbar {
        height: 0px;
    }
`;

export const TabsPanelWrapper = styled.div`
    width: 100%;
`;

interface TabsButtonProps {
    isActive?: boolean;
}

export const TabsButton = styled.button<TabsButtonProps>`
    ${disableDefaultButtonStyleMixin};
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    white-space: nowrap;
    color: ${white};
    ${({ isActive }) => !isActive && `color: ${grey}`};
    transition: color ${transitionTime};

    &:hover {
        color: ${white};
        opacity: 0.8;
    }

    &:not(:last-child) {
        margin-right: 24px;

        @media ${device.laptop} {
            margin-right: 16px;
        }
    }
`;
