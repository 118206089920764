import { grey2, white } from 'constants/styles/colors';
import { device } from 'constants/styles/media';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Active } from 'types';

interface Props {
    isSidebarTranslated: boolean;
    isSidebarMini: boolean;
}

export const SidebarWrapper = styled.div<Props>`
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 82px;
    margin-left: 20px;
    border-radius: 5px;
    z-index: 900;
    height: 100%;
    width: 230px;
    overflow-x: hidden;
    overflow-y: auto;
    background: ${grey2};
    box-shadow: 0 2px 22px 0 rgb(0 0 0 / 20%), 0 2px 30px 0 rgb(0 0 0 / 35%);
    ${({ isSidebarMini, isSidebarTranslated }) => !isSidebarMini && !isSidebarTranslated && 'width: 80px'};

    transition-property: top, bottom, width;
    transition-duration: 0.2s, 0.2s, 0.35s;
    transition-timing-function: linear, linear, ease;

    &:hover {
        ${({ isSidebarMini, isSidebarTranslated }) =>
            !isSidebarMini &&
            !isSidebarTranslated &&
            css`
                width: 260px;
                p {
                    transform: translateZ(0);
                    opacity: 1;
                }
            `};
    }

    &:before {
        content: '';
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid ${grey2};
        top: -5px;
        left: 40px;
        transform: translate(-50%);
    }

    @media ${device.laptop} {
        width: 260px;
        transform: translate3d(-260px, 0, 0);
        margin-top: 0px;
        margin-left: 0px;
        border-radius: 0px;
        transition: 0.5s transform cubic-bezier(0.685, 0.0473, 0.346, 1);

        ${({ isSidebarTranslated }) =>
            isSidebarTranslated &&
            css`
                transform: translateZ(0);
            `};
    }
`;

export const LogoWrapper = styled.div`
    margin: 30px 15px 35px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

export const LogoInner = styled.div`
    padding-left: 4px;
`;

export const TitleWrapper = styled.div<Active>`
    opacity: ${({ active }) => !active && 0.4};
`;

export const StyledNavLink = styled(NavLink)<Active>`
    ::before {
        opacity: ${({ active }) => !active && 0};
    }
`;

export const NavList = styled.ul`
    margin-top: 20px;
`;

export const NestedNavList = styled.ul`
    margin-top: 5px;
`;

interface ListItemProps extends Active {
    isSidebarMini: boolean;
    isSidebarTranslated: boolean;
}

export const ListItem = styled.li<ListItemProps>`
    a {
        margin: 10px 15px 0;
        border-radius: 30px;
        color: ${white};
        display: block;
        text-decoration: none;
        position: relative;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 0.62475rem;
        padding: 10px 8px;
        line-height: 1.625rem;
        transition: all 0.3s ease 0s;

        ${({ active }) =>
            active &&
            css`
                &:before {
                    content: ' ';
                    position: absolute;
                    height: 6px;
                    width: 6px;
                    top: 22px;
                    left: -4px;
                    background: ${white};
                    border-radius: 50%;
                }
            `}
    }

    i {
        font-size: 20px;
        float: left;
        margin-right: 12px;
        line-height: 30px;
        width: 34px;
        text-align: center;
        color: hsla(0, 0%, 100%, 0.8);
        position: relative;
    }

    p {
        margin: 0;
        line-height: 30px;
        position: relative;
        display: block;
        height: auto;
        white-space: nowrap;

        ${({ isSidebarMini }) =>
            !isSidebarMini &&
            css`
                opacity: 0;
                transform: translate3d(-25px, 0, 0);
            `}

        @media ${device.laptop} {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
`;
