import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { storageName } from 'constants/global';
import { baseURL } from 'constants/services';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { refreshAccessToken } from 'services/user';
import { userEvents, userStores } from 'stores/user';
import { hasNotValidToken } from 'utils/usefulFunctions';

const bucmasAxiosInstance = axios.create();

bucmasAxiosInstance.defaults.baseURL = baseURL;
bucmasAxiosInstance.defaults.method = 'POST';

bucmasAxiosInstance.interceptors.response.use(
    config => config.data,
    config => {
        const status = config.response.status;
        if (status === 401) {
            userEvents.logout();
        }

        return Promise.reject(config.response.data);
    }
);

const axiosRequest = async <T = void>(config: AxiosRequestConfig, withToken = true) => {
    const lsUserData = localStorage.getItem(storageName) || '';

    config.headers = withToken
        ? {
              Authorization: `Bearer ${userStores.user.getState().token}`
          }
        : {};

    if (!!lsUserData && withToken) {
        const user: BUCM.UserJwtTokenResponse = JSON.parse(lsUserData);

        if (user.token && user.refreshToken) {
            const decodeToken = jwt_decode<JwtPayload>(user.token);

            if (decodeToken.exp && hasNotValidToken(decodeToken.exp)) {
                try {
                    const { data, status } = await refreshAccessToken({
                        refreshToken: user.refreshToken,
                        token: user.token
                    });

                    if (status === 200 && data.isSuccess && data.data) {
                        userEvents.setUser(data.data);

                        if (config.headers) {
                            config.headers.Authorization = `Bearer ${data.data.token}`;
                        }
                    } else {
                        userEvents.logout();
                        return Promise.reject();
                    }
                } catch (e) {
                    userEvents.logout();
                    return Promise.reject(e);
                }
            }
        }
    }

    const request: AxiosPromise<T> = bucmasAxiosInstance({
        ...config
    });

    return request as any as Promise<T>;
};

export default axiosRequest;
