import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const CardCatalogGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(385px, 1fr));
    width: 100%;
    gap: 30px;

    @media ${device.small} {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
`;
