import { ImgWrapperProps } from 'components/ui/CustomImg/types';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const Img = styled.img`
    max-width: 100%;
    max-height: 100%;
    background-size: auto;
`;

export const ImgWrapper = styled.div<ImgWrapperProps>`
    ${flexCenter};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : '100%')};
    ${({ center }) => (center ? 'margin: auto;' : '')};
    ${({ pointer }) => (pointer ? 'cursor: pointer;' : '')};
`;
