import { TitleWrapper } from 'components/typography/InfoTitle/styles';
import React, { FC, ReactNode } from 'react';
import { Title as TitleProps } from 'types';

interface Props extends TitleProps {
    children: ReactNode;
}

export const InfoTitle: FC<Props> = ({ title = 'title', children }) => (
    <TitleWrapper>{`${title} ${children}`}</TitleWrapper>
);
