import { createEffect, createStore } from 'effector';
import { API } from 'services';
import { initializeToggleStore } from 'stores/initialize/initialize.toggle.store';
const [loadingOrganizations, updateOrganizationsLoading] = initializeToggleStore();
const [loadingCollaborations, updateCollaborationsLoading] = initializeToggleStore();

const getOrganizationsCount = createEffect({
    handler: async () => {
        try {
            updateOrganizationsLoading();
            const { data } = await API.organizations.getOrganizationsCount();

            return data;
        } catch {
            return 0;
        } finally {
            updateOrganizationsLoading();
        }
    }
});

const getCollaborationsCount = createEffect({
    handler: async () => {
        try {
            updateCollaborationsLoading();
            const { data } = await API.collaborations.getCollaborationsCount();

            return data?.collaborationStates;
        } catch {
            return null;
        } finally {
            updateCollaborationsLoading();
        }
    }
});

const organizationsCount = createStore<number>(0).on(getOrganizationsCount.doneData, (_, newState) => newState);
const collaborationsCount = createStore<BUCM.GetCollaborationStateCountDto[] | null>(null).on(
    getCollaborationsCount.doneData,
    (_, newState) => newState
);

const dashboardEffects = { getOrganizationsCount, getCollaborationsCount };
const dashboardStores = { organizationsCount, collaborationsCount, loadingOrganizations, loadingCollaborations };

export { dashboardEffects, dashboardStores };
