import { AxiosResponse, default as defaultAxios } from 'axios';
import { baseURL } from 'constants/services';
import axios from './axios';

export const authenticateUser = (data: BUCM.OrganizationUserAuthChallengeRequest) =>
    axios<BUCM.UserJwtTokenResponseWrapped>(
        {
            url: '/organization/users/authenticate',
            data
        },
        false
    );

export const refreshAccessToken = async ({
    refreshToken,
    token
}: Pick<BUCM.UserJwtTokenResponse, 'token' | 'refreshToken'>): Promise<
    AxiosResponse<BUCM.UserJwtTokenResponseApiResponse>
> =>
    await defaultAxios({
        method: 'post',
        url: `${baseURL}organization/users/token-refresh`,
        data: { refreshToken },
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
