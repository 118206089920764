import { defaultLimit, defaultPage } from 'constants/defaults/common';

// CollaborationStatus for filters
export enum CollaborationStatusFilter {
    Draft = 0,
    StartingSoon = 1,
    Active = 2,
    Finalizing = 3,
    Ending = 4,
    Ended = 5,
    Deleted = 6
}

//CollaborationStatus Array

export const collaborationStatusValues = [
    CollaborationStatusFilter.Draft,
    CollaborationStatusFilter.StartingSoon,
    CollaborationStatusFilter.Active,
    CollaborationStatusFilter.Finalizing,
    CollaborationStatusFilter.Ending,
    CollaborationStatusFilter.Ended,
    CollaborationStatusFilter.Deleted
];

// CollaborationStatus for UI
export enum CollaborationStatusesUi {
    None = 0,
    Draft = 10,
    StartingSoon = 15,
    Active = 20,
    ActiveClosed = 24,
    Finalizing = 28,
    Ending = 30,
    Deleted = 40,
    Ended = 1000
}

// Count Statuses UI
export enum CollaborationCountStatuses {
    Draft = 'draft',
    StartingSoon = 'published',
    ActiveOpen = 'activeOpen',
    ActiveClosed = 'activeClosed',
    AwaitingSubmissions = 'awaitingSubmissions',
    Deleted = 'deleted',
    Ending = 'ending',
    Ended = 'ended'
}

export interface CountStatusesUI {
    status: CollaborationCountStatuses | CollaborationCountStatuses[];
    title: string;
}

export const collaborationCountStatusesUI: CountStatusesUI[] = [
    { status: CollaborationCountStatuses.Draft, title: 'DRAFT' },
    { status: CollaborationCountStatuses.StartingSoon, title: 'STARTING SOON' },
    { status: [CollaborationCountStatuses.ActiveOpen, CollaborationCountStatuses.ActiveClosed], title: 'ACTIVE' },
    { status: CollaborationCountStatuses.AwaitingSubmissions, title: 'FINALIZING' },
    { status: CollaborationCountStatuses.Ending, title: 'ENDING' },
    { status: CollaborationCountStatuses.Ended, title: 'ENDED' },
    { status: CollaborationCountStatuses.Deleted, title: 'DELETED' }
];

export enum CollaborationEnrollmentStatusesUi {
    Enrolled = 10,
    Submitted = 20,
    Published = 30,
    Ended = 1000
}

// Statuses
export const collaborationStatuses: Record<BUCM.CollaborationState, string> = {
    0: 'None',
    10: 'Draft',
    15: 'Starting soon',
    20: 'Active',
    24: 'Active',
    28: 'Finalizing',
    30: 'Ending',
    40: 'Deleted',
    1000: 'Ended'
};

export const collaborationEnrollmentStatuses: Record<BUCM.ParticipationState, string> = {
    0: 'None',
    5: 'Enrollment Requested',
    10: 'Enrolled',
    20: 'Submitted',
    30: 'Published',
    1000: 'Ended'
};

export enum CollaborationEnrollmentStatusesKey {
    None = 0,
    EnrollmentRequested = 5,
    Enrolled = 10,
    Submitted = 20,
    Published = 30,
    Ended = 1000
}

export interface Option {
    value: CollaborationStatusFilter | string;
    label: string;
}

// Collaborations filters list
export const filterList: Option[] = [
    { value: 'all', label: 'All' },
    { value: CollaborationStatusFilter.Draft, label: collaborationStatuses[CollaborationStatusesUi.Draft] },
    {
        value: CollaborationStatusFilter.StartingSoon,
        label: collaborationStatuses[CollaborationStatusesUi.StartingSoon]
    },
    { value: CollaborationStatusFilter.Active, label: collaborationStatuses[CollaborationStatusesUi.Active] },
    { value: CollaborationStatusFilter.Finalizing, label: collaborationStatuses[CollaborationStatusesUi.Finalizing] },
    { value: CollaborationStatusFilter.Ending, label: collaborationStatuses[CollaborationStatusesUi.Ending] },
    { value: CollaborationStatusFilter.Ended, label: collaborationStatuses[CollaborationStatusesUi.Ended] },
    { value: CollaborationStatusFilter.Deleted, label: collaborationStatuses[CollaborationStatusesUi.Deleted] }
];

// Collaborations status list

export interface StatusList {
    filterValue: CollaborationStatusFilter;
    status: CollaborationStatusesUi | CollaborationStatusesUi[];
}
export const statusList: StatusList[] = [
    { filterValue: CollaborationStatusFilter.Draft, status: CollaborationStatusesUi.Draft },
    { filterValue: CollaborationStatusFilter.StartingSoon, status: CollaborationStatusesUi.StartingSoon },
    {
        filterValue: CollaborationStatusFilter.Active,
        status: [CollaborationStatusesUi.Active, CollaborationStatusesUi.ActiveClosed]
    },
    { filterValue: CollaborationStatusFilter.Ending, status: CollaborationStatusesUi.Ending },
    { filterValue: CollaborationStatusFilter.Ended, status: CollaborationStatusesUi.Ended },
    { filterValue: CollaborationStatusFilter.Deleted, status: CollaborationStatusesUi.Deleted }
];

// default loading data
export const defaultLoadingInfo = {
    isLoading: false,
    isLoaded: false
};

// External networks keys
export enum ExternalNetworkKey {
    Bullz = 'bullz',
    Tiktok = 'tiktok',
    Instagram = 'instagram',
    Twitter = 'twitter',
    YouTube = 'youtube',
    Twitch = 'twitch'
}

// default params for submissions request
export const defaultSubmissionsParams: Paths.Collaborations$CollaborationIdAssets.Get.QueryParameters = {
    limit: 20,
    pageIndex: 0
};

export const defaultValues = {
    pageIndex: defaultPage,
    limit: defaultLimit,
    filter: undefined,
    collaborationId: undefined
};

// Submission report types
export enum SubmissionReportType {
    None = 0,
    RequirementViolation = 1,
    Copyright = 2,
    QualityGuideline = 3,
    Inappropriate = 4,
    Other = 5
}

export const feedbackReportTypes = {
    [SubmissionReportType.None]: 'None',
    [SubmissionReportType.RequirementViolation]: 'Does not follow the requirements',
    [SubmissionReportType.Copyright]: 'Copyright infringement',
    [SubmissionReportType.QualityGuideline]: 'Poor quality',
    [SubmissionReportType.Inappropriate]: 'Inappropriate',
    [SubmissionReportType.Other]: 'Other'
};
