import { CloseButton } from 'components/common/CloseButton';
import {
    IconWrapper,
    LinkWrapper,
    ModalContainer,
    ModalContent,
    ModalHeader,
    ModalWrapper,
    VideoContainer,
    VideoWrapper
} from 'components/modals/VideoModal/styles';
import { VideoIcon } from 'components/modals/VideoModal/VideoIcon';
import { useStore } from 'effector-react';
import { useNonScrolledBackground } from 'hooks/useNonScrolledBackground';
import { SubmissionCard } from 'pages/Collaboration/SubmissionCard';
import React, { FC } from 'react';
import { externalNetworksStores } from 'stores/externalNetworks';
import { videoModal, videoModalEvents } from 'stores/videoModal';
import { findBullzNetwork } from 'utils/findBullzNetwork';
import { CreatorInfo } from './CreatorInfo';

const { closeVideoModal } = videoModalEvents;
const { externalNetworks } = externalNetworksStores;

export const VideoModal: FC = () => {
    const { visible, video } = useStore(videoModal);
    const extNetworks = useStore(externalNetworks);

    useNonScrolledBackground(visible);

    if (!visible || !video) return null;
    const { postUri, networkId, id } = video;

    const onCloseModal = () => {
        closeVideoModal();
    };

    const setIsBullzVideo = (videoNetworkId?: string) => {
        const bullzNetwork = findBullzNetwork(extNetworks);
        return bullzNetwork?.id === videoNetworkId;
    };

    const isBullzVideo = setIsBullzVideo(networkId);

    return (
        <ModalWrapper>
            <ModalContainer>
                <ModalHeader>
                    {video && <CreatorInfo video={video} />}
                    <CloseButton onClick={onCloseModal} />
                </ModalHeader>
                <ModalContent>
                    <VideoContainer key={id}>
                        <IconWrapper>
                            <VideoIcon externalNetworks={extNetworks} networkId={networkId} />
                            <LinkWrapper>
                                {!isBullzVideo && postUri && (
                                    <a href={postUri} rel="noopener noreferrer" target="_blank">
                                        Link
                                    </a>
                                )}
                            </LinkWrapper>
                        </IconWrapper>
                        <VideoWrapper>
                            <SubmissionCard
                                canOpenModal={false}
                                // NOTE: prop isPlayable is made for non Bullz social network videos which can't be played on the site for now
                                // in future such videos maybe will be playable, but for now we render only poster of the video
                                isPlayable={isBullzVideo}
                                rounded={false}
                                video={video}
                            />
                        </VideoWrapper>
                    </VideoContainer>
                </ModalContent>
            </ModalContainer>
        </ModalWrapper>
    );
};
