import { scrollbar, white } from 'constants/styles';
import styled from 'styled-components';

interface Props {
    isMobile?: boolean;
}

export const ScrollableWrapper = styled.div<Props>`
    overflow-x: scroll;
    width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
    ${scrollbar};
`;

export const Span = styled.span`
    white-space: pre;
    color: ${white};
`;
