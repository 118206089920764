import { dateFormat } from 'constants/global';
import { differenceInSeconds, format, intervalToDuration } from 'date-fns';

export const getDoubleDigitValue = (value?: number) => {
    if (!value) return '00';
    return value < 10 ? `0${value}` : `${value}`;
};

/**
 * Get difference between incoming date and current date
 * @param date
 * @returns
 */
export const getTimeLeft = (date?: string | null) => {
    if (!date) return '';
    if (!Date.parse(date)) return '';

    let startTime = new Date(date);
    startTime = new Date(startTime.getTime() + startTime.getTimezoneOffset() * 60000);

    const differenceSeconds = differenceInSeconds(startTime, Date.now());
    if (differenceSeconds <= 0)
        return {
            expired: true,
            value: '00 d 00 hr 00 mins'
        };

    const { days, hours, minutes } = intervalToDuration({
        start: startTime,
        end: Date.now()
    });

    return {
        expired: false,
        value: `${getDoubleDigitValue(days)} d ${getDoubleDigitValue(hours)} hr ${getDoubleDigitValue(minutes)} mins`
    };
};

export const getFormattedDate = (date?: string | null, formatType?: string) => {
    if (!date || !Date.parse(date)) return '';

    return format(Date.parse(date), formatType || dateFormat);
};
