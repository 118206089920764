import { flexCenter } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled.div`
    ${flexCenter};
    flex-direction: column;
`;

export const Title = styled.h3`
    margin-top: 24px;
    margin-bottom: 30px;
`;
