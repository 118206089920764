import { ellipsisMixin, primaryColor, textInfoFontSize } from 'constants/styles';
import styled from 'styled-components';
import { Color, NoWrap, Sizes } from 'types';

interface Props extends Color, NoWrap, Sizes {
    noBreak?: boolean;
    fontSize?: string;
}

export const Title = styled.span<Props>`
    ${({ width }) => (width ? `width: ${width};` : ``)};
    color: ${({ color }) => (color ? color : primaryColor)};
    ${({ noWrap }) => (noWrap ? ellipsisMixin : '')};
    ${({ noBreak }) => (noBreak ? 'white-space: nowrap;' : '')};
    font-size: ${({ fontSize }) => fontSize || textInfoFontSize};
    margin-right: 8px;
`;
