import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    ${flexCenter};
    z-index: 99;
`;
