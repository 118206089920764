import axios from './axios';

export const getOrganizations = (data: BUCM.QueryOrganizationRequest) =>
    axios<BUCM.OrganizationsResponseWrapped>({
        url: '/organizations/query',
        data
    });

export const getOrganizationById = ({ organizationId }: BUCM.GetOrganizationRequest) =>
    axios<BUCM.OrganizationResponseWrapped>({
        url: `/organizations/${organizationId}`,
        method: 'get'
    });

export const getOrganizationsCount = () =>
    axios<BUCM.Int32ApiResponse>({
        url: `admin/organization/count`,
        method: 'get'
    });
