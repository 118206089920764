import { TableHeadInterface } from 'types';

export const emptyMessage = 'No data';

export const columns: TableHeadInterface[] = [
    {
        title: 'IMAGE',
        dataIndex: 'image',
        key: 'image'
    },
    {
        title: 'NAME',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'STATUS',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: 'ID COLLABORATION',
        dataIndex: 'collaborationId',
        key: 'collaborationId'
    },
    {
        title: 'UTC START',
        dataIndex: 'utcStart',
        key: 'utcStart'
    },
    {
        title: 'UTC END',
        dataIndex: 'utcEnd',
        key: 'utcEnd'
    },
    {
        title: 'BUDGET ACCEPTED',
        dataIndex: 'budgetAccepted',
        key: 'budgetAccepted'
    }
];
