import { grey2, grey4 } from 'constants/styles';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const skeletonProps = {
    count: 1,
    height: '40px',
    baseColor: grey2,
    highlightColor: grey4,
    style: {
        borderRadius: '16px'
    }
};

export const SubmissionsLoading = () => (
    <>
        {Array(5)
            .fill(0)
            .map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Skeleton key={index} {...skeletonProps} height="250px" />
            ))}
    </>
);
