import { blue } from 'constants/styles';
import Select from 'react-select';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`
    width: 100%;

    .select__menu {
        top: 105%;
        max-width: 94%;
        width: 94%;
        right: 3%;
        border-radius: 12px;
        border: 0;
        box-shadow: 1px 2px 7px 1px rgba(0, 0, 0, 0.125);
        transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, opacity 0.3s ease 0s, height 0s linear 0.35s;
        background-color: $white;
        box-sizing: border-box;
        max-height: 200px;
        position: absolute;
        z-index: 2;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 10px;
        margin-bottom: unset;
        margin-top: unset;
        &:before {
            border-bottom: 11px solid #f1eae0;
            top: -11px;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            content: '';
            display: inline-block;
            position: absolute;
            right: 15px;
        }

        &:after {
            top: -10px;
            border-bottom: 11px solid #fffcf5;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            content: '';
            display: inline-block;
            position: absolute;
            right: 15px;
        }
    }
    .select__menu-list {
        max-height: 198px;
        overflow-y: auto;
        padding-bottom: unset;
        padding-top: unset;
        position: relative;
    }
    .select__option {
        font-size: 0.8571em;
        padding-top: 0.6rem;
        padding-bottom: 0.6rem;
        margin-top: 5px;
        -webkit-transition: all 150ms linear;
        -moz-transition: all 150ms linear;
        -o-transition: all 150ms linear;
        -ms-transition: all 150ms linear;
        transition: all 150ms linear;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        display: block;
        outline: none;
        color: #292b2c;
    }
    .select__menu {
        .select__option {
            &,
            &:active {
                background-color: transparent;
            }
            &.select__option--is-selected {
                background-color: #dddddd;
            }
            &:hover {
                background-color: #f3f3f3;
            }
            &.select__option--is-disabled {
                &,
                &:active {
                    color: #cccccc;
                    cursor: default;
                }
            }
        }
    }
    .select__control {
        border-radius: 4px;
        height: calc(2.25rem + 2px);
        box-sizing: border-box;
        border-collapse: separate;
        display: table;
        width: 100%;
        &,
        &:hover {
            border: 1px solid #c4c4c4;
            box-shadow: none;
            background-color: transparent;
        }
        &.select__control--is-focused {
            .select__dropdown-indicator {
                &:after {
                    transform: rotate(180deg);
                    top: -4px;
                }
            }
            &,
            &:hover {
                border: 1px solid ${blue};
                box-shadow: none;
                border-color: ${blue};
            }
        }
        .select__value-container--has-value {
            .select__multi-value {
                display: inline-flex;
                border-radius: 4px;
                background-color: transparent;
                & + div:not(.select__multi-value) {
                    display: inline-flex;
                }
                .select__multi-value__label {
                    font-size: 0.9em;
                }
            }
            .select__single-value {
                top: 48%;
                color: ${blue};
            }
        }
        .select__placeholder {
            color: #9a9a9a;
            line-height: normal;
            font-size: 0.8571em;
            top: 48%;
        }
        .select__value-container,
        .select__indicators {
            display: table-cell;
        }
        .select__indicators {
            width: 60px;
            padding-right: 10px;
            align-items: flex-end;
            vertical-align: middle;
            text-align: right;
            .select__indicator-separator {
                display: none;
            }
            .select__indicator {
                padding: 0;
                display: inline-block;
            }
            .select__dropdown-indicator {
                &:after {
                    transition: all 150ms ease 0s;
                    border-color: #999 transparent transparent;
                    border-style: solid;
                    border-width: 5px 5px 2.5px;
                    display: inline-block;
                    height: 0;
                    width: 0;
                    position: relative;
                    content: '';
                }
                &:hover:after {
                    border-top-color: #666666;
                }
                & > * {
                    display: none;
                }
            }
            .select__clear-indicator {
                &:before {
                    content: '\D7';
                    display: inline-block;
                    font-size: 18px;
                    line-height: 1;
                    top: 2px;
                    position: relative;
                    margin-right: 3px;
                }
                & > * {
                    display: none;
                }
            }
        }
    }
    .select__menu-list {
        margin: 10px 0;
        max-height: 180px;
    }
`;
