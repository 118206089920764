import { CopyButton } from 'components/common/CopyButton';
import { BlockSubTitle, BlockTitle, BlockWrapper } from 'components/common/PropertyBlock/styles';
import { Section } from 'components/wrappers/flexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import React from 'react';
import { BackgroundColor, Sizes } from 'types';
import { Copyable, MarginBottom, Success, Title } from 'types/interface';

interface PropertyBlockProps
    extends Title,
        Partial<Success>,
        Copyable,
        Pick<Sizes, 'width'>,
        BackgroundColor,
        MarginBottom {
    subtitleColor?: string;
    isIdType?: boolean;
    icon?: JSX.Element;
}

export const PropertyBlock = ({
    icon,
    isIdType = false,
    title,
    subtitle,
    successText,
    copyable,
    width,
    marginBottom,
    subtitleColor
}: PropertyBlockProps) => (
    <BlockWrapper marginBottom={marginBottom} width={width}>
        <BlockTitle>{title}</BlockTitle>

        <Section alignCenter justifyBetween noWrap marginTop="4px">
            <BlockSubTitle color={subtitleColor} isIdType={isIdType}>
                {subtitle}
            </BlockSubTitle>

            {icon}

            {copyable && !!subtitle && (
                <MarginWrapper marginLeft="16px">
                    <CopyButton subject={subtitle} successText={successText} />
                </MarginWrapper>
            )}
        </Section>
    </BlockWrapper>
);
