import { Loader } from 'components/common/Loader';
import { Breadcrumb } from 'components/grid/Breadcrumb';
import { OrganizationDescription } from 'pages/Organization/OrganizationDescription';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/grid/Empty';
import { collaborationCountStatusesUI } from 'constants/defaults/collaborations';
import { xs } from 'constants/styles';
import { useStore } from 'effector-react';
import { OrganizationCollaborations } from 'pages/Organization/OrganizationCollaborations';
import {
    BlockSubTitle,
    BlockTitle,
    BlockWrapper,
    CollaborationsStatesWrapper,
    LoaderWrapper,
    Title
} from 'pages/Organization/styles';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router';
import { organizationEffects, organizationEvents, organizationStores } from 'stores/singleOrganization';
import { Id } from 'types/interfaces/data';
import { organizationNotFoundMessage } from '../Organizations/constants';

const { getOrganization, getCollaborationsByOrganizationId } = organizationEffects;
const { resetOrganization } = organizationEvents;
const { organization } = organizationStores;

export const Organization = () => {
    const { id } = useParams<Id>();
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });
    const loading = useStore(getOrganization.pending);
    const organizationData = useStore(organization);
    const collaborationStates = organizationData?.collaborationStates || {};
    const hasCollaborations = Object.values(collaborationStates).some(val => val > 0);

    useEffect(() => {
        if (id) {
            getOrganization({ organizationId: id });
        }

        return () => resetOrganization();
    }, [id]);

    useEffect(() => {
        if (organizationData && id) {
            getCollaborationsByOrganizationId({ organizationId: id });
        }
    }, [id, organizationData]);

    return (
        <MainLayout>
            <MarginWrapper marginTop={isMobile ? '15px' : '0'}>
                <Breadcrumb />
            </MarginWrapper>
            <Title>Organization info</Title>

            {loading ? (
                <LoaderWrapper>
                    <Loader />
                </LoaderWrapper>
            ) : organizationData ? (
                <OrganizationDescription {...organizationData} />
            ) : (
                <MarginWrapper marginTop="48px">
                    <Empty description={organizationNotFoundMessage} />
                </MarginWrapper>
            )}

            {hasCollaborations ? (
                <>
                    <Title>Collaborations</Title>
                    <CollaborationsStatesWrapper>
                        {collaborationCountStatusesUI.map(({ status, title }) => {
                            let count = 0;
                            if (!!collaborationStates) {
                                if (Array.isArray(status)) {
                                    count = status.reduce(
                                        (total, currentStatus) => total + collaborationStates[currentStatus],
                                        0
                                    );
                                } else {
                                    count = collaborationStates[status];
                                }
                            }

                            return count ? (
                                <BlockWrapper key={title}>
                                    <MarginWrapper marginRight="16px">
                                        <BlockTitle>{title}</BlockTitle>
                                    </MarginWrapper>
                                    <BlockSubTitle>{count}</BlockSubTitle>
                                </BlockWrapper>
                            ) : null;
                        })}
                    </CollaborationsStatesWrapper>
                </>
            ) : null}

            {organizationData && <OrganizationCollaborations />}
        </MainLayout>
    );
};
