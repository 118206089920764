import { socialBullz, socialInstagram, socialTiktok, socialTwitter, socialYoutube, twitchIcon } from 'assets/icons';
import { CustomImg } from 'components/ui/CustomImg';
import { ExternalNetworkKey } from 'constants/defaults/collaborations';

import React from 'react';

export const VideoIcon = ({
    networkId,
    externalNetworks
}: {
    networkId?: string;
    externalNetworks: BUCM.ExternalNetworkResponse[];
}) => {
    const currentExternalNetwork = externalNetworks.find(
        (network: BUCM.ExternalNetworkResponse) => network.id === networkId
    );
    if (currentExternalNetwork) {
        switch (currentExternalNetwork.title?.toLowerCase()) {
            case ExternalNetworkKey.Bullz:
                return <CustomImg alt="Bullz" height="19px" src={socialBullz} width="51px" />;
            case ExternalNetworkKey.Instagram:
                return <CustomImg alt="Instagram" height="22px" src={socialInstagram} width="22px" />;
            case ExternalNetworkKey.Twitter:
                return <CustomImg alt="Twitter" height="22px" src={socialTwitter} width="22px" />;
            case ExternalNetworkKey.Tiktok:
                return <CustomImg alt="Tiktok" height="22px" src={socialTiktok} width="22px" />;
            case ExternalNetworkKey.YouTube:
                return <CustomImg alt="YouTube" height="22px" src={socialYoutube} width="fit-content" />;
            case ExternalNetworkKey.Twitch:
                return <CustomImg alt="Twitch" height="22px" src={twitchIcon} width="22px" />;
            default:
                return null;
        }
    }
    return null;
};
