import * as Yup from 'yup';
import { FormikErrors } from 'formik';

export const invalidEmailMessage = 'Incorrect email';
export const requiredFieldMessage = 'This field is required';
export const passwordMinimum = 8;
export const passwordLengthMessage = (min: number) => 'The password must be at least ' + min + ' characters long';
export const oneCapitalCharRequiredMessage = 'At least one capital letter required';
export const atLeastOneNumberRequiredMessage = 'At least one number required';
export const oneCapitalCharRequiredRegExp = new RegExp(/[A-Z]/);
export const atLeastOneNumberRequiredRegExp = new RegExp(/[0-9]/);

export const yupEmail = Yup.string().email(invalidEmailMessage).required(requiredFieldMessage);

export const yupPassword = Yup.string()
    .required(requiredFieldMessage)
    .min(passwordMinimum, passwordLengthMessage(passwordMinimum))
    .matches(oneCapitalCharRequiredRegExp, oneCapitalCharRequiredMessage)
    .matches(atLeastOneNumberRequiredRegExp, atLeastOneNumberRequiredMessage);

export interface FormValues {
    email: string;
    password: string;
}

export const schema = Yup.object().shape({
    email: yupEmail,
    password: yupPassword //yupPasswordNoHint
});

const validateEmail = async (email: string) => {
    try {
        await Yup.reach(schema, 'email').validate(email);
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message;
    }
};

const validatePassword = async (password: string) => {
    try {
        await Yup.reach(schema, 'password').validate(password);
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return err.message;
    }
};

export const validateLogInForm = async ({ email, password }: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    const emailErrors = await validateEmail(email);
    if (emailErrors) {
        errors.email = emailErrors;
    }

    const passwordErrors = await validatePassword(password);
    if (passwordErrors) {
        errors.password = passwordErrors;
    }

    return errors;
};
