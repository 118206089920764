import { grey } from 'constants/styles';
import styled from 'styled-components';

export const TitleWrapper = styled.h3`
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: ${grey};
    margin-bottom: 16px;
    margin-top: 24px;
`;
