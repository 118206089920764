import { Loader } from 'components/common/Loader';
import { CardCatalogGrid } from 'components/common/CatalogGrid/styles';
import { Column, Section } from 'components/wrappers/flexWrapper';
import { MainLayout } from 'components/layouts/MainLayout';
import { Empty } from 'components/grid/Empty';
import { usersNotFoundMessage } from 'constants/defaults/users';
import { HiddenScrollBlock } from 'constants/styles';
import { useStore } from 'effector-react';
import { UserCard } from 'pages/Users/UserCard';
import { UsersFilterLayout } from 'pages/Users/UsersFilterLayout';
import React, { useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { usersEffects, usersEvents, usersStores } from 'stores/users';

const { getByDefaultParams, loadMore, resetUsers } = usersEvents;
const { getUsers, usersLoadMore } = usersEffects;
const { users, hasNext, errors } = usersStores;

export const Users = () => {
    const isLoading = useStore(getUsers.pending);
    const isMoreLoading = useStore(usersLoadMore.pending);
    const { items, totalRecords } = useStore(users);
    const isHasNext = useStore(hasNext);
    const responseErrors = useStore(errors);

    const [loadRef] = useInfiniteScroll({
        loading: isLoading,
        hasNextPage: isHasNext,
        onLoadMore: () => loadMore(),
        disabled: responseErrors !== null,
        rootMargin: '0px'
    });

    useEffect(() => {
        getByDefaultParams();

        return () => resetUsers();
    }, []);

    return (
        <MainLayout>
            <UsersFilterLayout totalRecords={totalRecords}>
                <Column alignCenter height="100%" justifyCenter={!items?.length} marginTop="15px">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            {items?.length ? (
                                <>
                                    <CardCatalogGrid>
                                        {items.map(item => (
                                            <UserCard key={item.userId} {...item} />
                                        ))}
                                    </CardCatalogGrid>
                                    {isMoreLoading && (
                                        <Section justifyCenter>
                                            <Loader />
                                        </Section>
                                    )}
                                    <HiddenScrollBlock ref={loadRef} />
                                </>
                            ) : (
                                <Empty description={usersNotFoundMessage} />
                            )}
                        </>
                    )}
                </Column>
            </UsersFilterLayout>
        </MainLayout>
    );
};
