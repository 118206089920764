/** Libs */
import {
    collaborationStoppedStatus,
    noneStatus,
    notPublishedStatus,
    postNotVerified,
    publishedInstagramStatus,
    publishedTiktokStatus,
    publishedTwitchStatus,
    publishedTwitterStatus,
    publishedYoutubeStatus
} from 'assets/icons';
import React, { FC } from 'react';
/** Components, assets */
import { StatusesWrapper } from './StatusesWrapper';

interface Props {
    status: BUCM.PublishingEndedReason;
    publishingNetwork?: BUCM.ExternalNetworkResponse;
}

const getExternalNetworkIcon = (publishingNetwork?: BUCM.ExternalNetworkResponse): string => {
    if (!publishingNetwork || !publishingNetwork.title) return noneStatus;

    switch (publishingNetwork.title.toLowerCase().trim()) {
        case 'instagram':
            return publishedInstagramStatus;
        case 'twitter':
            return publishedTwitterStatus;
        case 'tiktok':
            return publishedTiktokStatus;
        case 'youtube':
            return publishedYoutubeStatus;
        case 'twitch':
            return publishedTwitchStatus;
        default:
            return noneStatus;
    }
};

const getExternalNetworkPopoverText = (publishingNetwork?: BUCM.ExternalNetworkResponse): string => {
    if (!publishingNetwork || !publishingNetwork.title) return noneStatus;

    switch (publishingNetwork.title.toLowerCase().trim()) {
        case 'instagram':
            return 'Published to Instagram';
        case 'twitter':
            return 'Published to Twitter';
        case 'tiktok':
            return 'Published to Tiktok';
        case 'youtube':
            return 'Published to YouTube';
        default:
            return '-';
    }
};

const getIcon = (status: BUCM.PublishingEndedReason, publishingNetwork?: BUCM.ExternalNetworkResponse): string => {
    switch (status) {
        case 0:
            return noneStatus;
        case 200: // will be different
            return getExternalNetworkIcon(publishingNetwork);
        case 500:
            return notPublishedStatus;
        case 501:
            return collaborationStoppedStatus;
        case 503:
            return postNotVerified;
        default:
            return noneStatus;
    }
};

const getPopoverText = (
    status: BUCM.PublishingEndedReason,
    publishingNetwork?: BUCM.ExternalNetworkResponse
): string => {
    switch (status) {
        case 0:
            return '-';
        case 200:
            return getExternalNetworkPopoverText(publishingNetwork);
        case 500:
            return 'Video not shared';
        case 501:
            return 'Collaboration ended';
        case 503:
            return 'Shared post not verified';
        case 504:
            return 'Media rejected';
        default:
            return '-';
    }
};
export const ParticipationEndedStatuses: FC<Props> = ({ status, publishingNetwork }) => (
    <StatusesWrapper
        icon={getIcon(status, publishingNetwork)}
        popoverText={getPopoverText(status, publishingNetwork)}
    />
);
