import Tooltip from 'rc-tooltip';
import { HamburgerWrapper } from 'components/grid/Navbar/DefaultHamburger/styles';
import { ClickableWrapper } from 'components/wrappers/ClickableWrapper';
import React, { FC } from 'react';
import { HandleMiniToggle } from 'types/interfaces/global';

export const DefaultHamburger: FC<HandleMiniToggle> = ({ handleMiniToggle }) => (
    <HamburgerWrapper>
        <Tooltip overlay="Sidebar toggle" placement="right">
            <ClickableWrapper onClick={handleMiniToggle}>
                <i className="tim-icons icon-bullet-list-67" />
            </ClickableWrapper>
        </Tooltip>
    </HamburgerWrapper>
);
