import { Row } from 'components/wrappers/flexWrapper';
import { black, ellipsisMixin, white } from 'constants/styles';
import styled from 'styled-components';
import { Sizes } from 'types';

export const Wrapper = styled(Row)<Pick<Sizes, 'width'>>`
    ${({ width }) => `width: ${width}`};
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding: 16px;
    border-radius: 8px;
    background-color: ${black};
`;

export const ItemValue = styled.span`
    font-size: 14px;
    line-height: 24px;
    color: ${white};
    ${ellipsisMixin};
`;
