import { Column, Section } from 'components/wrappers/flexWrapper';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const SearchSelectWrapper = styled(Section)`
    padding-top: 15px;
    align-items: flex-end;
    gap: 30px;

    @media ${device.mobile} {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 16px;
    }
`;

export const SelectWrapper = styled(Column)`
    width: 280px;
    flex-wrap: nowrap;
    gap: 16px;

    @media ${device.mobile} {
        width: 100%;
    }
`;

export const ResetButtonWrapper = styled.div`
    @media ${device.mobile} {
        order: -1;
        margin-left: auto;
    }
`;

export const FilterTitle = styled.h5`
    margin-bottom: 15px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
`;
