import { Row } from 'components/wrappers/flexWrapper';
import { black, darkGrey3, white } from 'constants/styles';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    padding: 18px;
    background-color: ${black};
    border-radius: 8px;
`;

export const CountWrapper = styled.span`
    margin-left: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${white};
`;

export const NetworkInfo = styled(Row)`
    align-items: center;
    flex-wrap: noWrap;
    margin-right: 15px;
    padding: 9px 12px;
    background: ${darkGrey3};
    border-radius: 4px;
`;

export const NetworkSubtitle = styled.span`
    font-size: 14px;
    line-height: 21px;
    color: ${white};
    max-width: 60%;
`;
