import React, { FC, PropsWithChildren } from 'react';
import { StyledTag, StyledLink } from './styles';

interface Props extends PropsWithChildren {
    link?: string;
}

export const Tag: FC<Props> = ({ children, link }) => (
    <StyledTag>{link ? <StyledLink to={link}>{children}</StyledLink> : children}</StyledTag>
);
