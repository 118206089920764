import React, { FC, ReactNode } from 'react';
import { ArrowDownIcon, ArrowUpIcon } from 'assets/icons';
import { Column } from 'components/wrappers/flexWrapper';
import { ReactClick } from 'types/interfaces/react';
import { StyledButton } from './styles';
import { white, grey } from 'constants/styles';
import { OrderType } from 'types';
import { SortState } from './constants';

interface Props extends ReactClick<HTMLButtonElement> {
    children: ReactNode;
    sortState: OrderType;
}

export const SortButton: FC<Props> = ({ onClick, children, sortState = 'no' }) => {
    const upArrowColor = sortState === SortState.Asc ? white : grey;
    const downArrowColor = sortState === SortState.Desc ? white : grey;

    return (
        <StyledButton onClick={onClick}>
            <Column justifyBetween height="14px" marginRight="8px">
                <ArrowUpIcon color={upArrowColor} />
                <ArrowDownIcon color={downArrowColor} />
            </Column>
            {children}
        </StyledButton>
    );
};
