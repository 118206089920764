import { SearchInput } from 'components/form/SearchInput';
import { Span } from 'components/typography/Span';
import { Column, Section } from 'components/wrappers/flexWrapper';
import { Button } from 'components/ui/Button';
import { filterList, Option } from 'constants/defaults/collaborations';
import { grey } from 'constants/styles';
import { useStore } from 'effector-react';
import { CollaborationsTable } from 'pages/Organization/CollaborationsTable';
import { Title } from 'pages/Organization/styles';
import { ResetButtonWrapper } from 'pages/Users/UsersFilterLayout/styles';
import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Select } from 'components/ui/Select';
import { organizationEvents, organizationStores } from 'stores/singleOrganization';
import { Id } from 'types/interfaces/data';
import { FilterSelectWrapper, FilterTitle, SearchSelectWrapper } from './styles';

const { collaborations, collaborationsParams } = organizationStores;
const { setCollaborationsParams } = organizationEvents;

export const OrganizationCollaborations = () => {
    const { id } = useParams<Id>();
    const { itemsCount } = useStore(collaborations);
    const { filter, collaborationId } = useStore(collaborationsParams);
    const [searchId, setSearchId] = useState('');

    const activeFilter = useMemo(() => filterList.find(item => item.value === filter), [filter]) || filterList[0];
    const title = collaborationId ? `Collaboration by id: ${collaborationId}` : 'All collaborations';

    const getCollaborationsByDefaultParams = useCallback(() => {
        setCollaborationsParams({
            organizationId: id
        });
    }, [id]);

    const onChange = (option: Option | null) => {
        if (!option) return;

        setSearchId('');

        if (option.value === 'all') {
            getCollaborationsByDefaultParams();
        } else {
            setCollaborationsParams({
                organizationId: id,
                filter: option.value
            });
        }
    };

    const onSearchSubmit = (collaborationId: string) => {
        if (collaborationId) {
            setSearchId(collaborationId);

            setCollaborationsParams({
                organizationId: id,
                collaborationId
            });
        } else {
            getCollaborationsByDefaultParams();
        }
    };

    const onResetClick = () => {
        getCollaborationsByDefaultParams();
        setSearchId('');
    };

    return (
        <>
            <Title>{title}</Title>

            <SearchSelectWrapper>
                <FilterSelectWrapper width="280px">
                    <FilterTitle>Filter by status</FilterTitle>
                    <Select options={filterList} value={activeFilter} onChange={onChange} />
                </FilterSelectWrapper>

                <Section maxWidth="465px">
                    <SearchInput
                        defaultValue={searchId}
                        placeholder="Search by Collaboration ID"
                        onSearch={onSearchSubmit}
                    />
                </Section>

                <ResetButtonWrapper>
                    <Button onClick={onResetClick}>Reset</Button>
                </ResetButtonWrapper>
            </SearchSelectWrapper>

            <Section marginTop="30px">
                <Span color={grey} fontSize="13px">
                    Total: {itemsCount}
                </Span>
            </Section>

            <Column alignCenter marginTop="30px" width="100%">
                <CollaborationsTable />
            </Column>
        </>
    );
};
