import { Column } from 'components/wrappers/flexWrapper';
import { grey2, white } from 'constants/styles';
import { device } from 'constants/styles/media';
import styled from 'styled-components';

export const FormWrapper = styled(Column)`
    align-items: center;
    width: 570px;
    background-color: ${grey2};
    padding: 50px 102px 40px;
    border-radius: 12px;

    @media ${device.tablet} {
        width: calc(100% - 30px);
        padding-left: 32px;
        padding-right: 32px;
        margin-left: 15px;
        margin-right: 15px;
    }

    @media ${device.small} {
        padding-left: 16px;
        padding-right: 16px;
    }
`;

export const FormTitle = styled.h2`
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 40px;
    color: ${white};
    font-family: 'Poppins', sans-serif;
`;
