import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { absoluteCenterAlignmentMixin, white } from 'constants/styles';
import styled from 'styled-components';
import { device } from 'constants/styles/media';

export const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(38, 38, 38, 0.7);
    padding: 220px 80px 100px;
    z-index: 1000;
    overflow: auto;
`;

export const ModalContentWrapper = styled(ContentWrapper)`
    ${absoluteCenterAlignmentMixin};
    position: fixed;
    display: flex;
    flex-direction: column;
    min-width: 440px;
    max-height: 630px;
    padding: 24px 50px;
    background: ${white};
    overflow: 'auto';
    z-index: 1001;

    @media ${device.mobile} {
        padding-left: 40px;
        padding-right: 40px;
        min-width: 290px;
    }
`;
