import { disableDefaultButtonStyleMixin, flexCenter } from 'constants/styles';
import styled from 'styled-components';
import { BackgroundColor, Color } from 'types/interfaces/styles';

interface Props extends BackgroundColor, Color {}

export const StyledButton = styled.button<Props>`
    ${disableDefaultButtonStyleMixin};
    ${flexCenter};

    width: 30px;
    height: 30px;
    border-radius: 4px;
    color: ${({ color }) => color ?? 'rgba(255, 255, 255, 0.25)'};
    transition: color 0.3s, background-color 0.3s;

    :hover {
        color: ${({ color }) => color ?? 'rgba(255, 255, 255, 0.8)'};
        background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
    }
`;
