import { WomIcon } from 'assets/icons';
import { Avatar } from 'components/common/Avatar';
import { PropertyBlock } from 'components/common/PropertyBlock';
import { CardWrapper } from 'components/wrappers/CardWrapper/styles';
import { Column, Row, Section } from 'components/wrappers/flexWrapper';
import { GridWrapper } from 'components/wrappers/GridWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { collaborationStatuses } from 'constants/defaults/collaborations';
import { dateAndTimeFormat } from 'constants/global';
import { collaborationsLink } from 'constants/routes';
import { primaryColor, xs } from 'constants/styles';
import {
    copyCollaborationIDMessage,
    copyOrganizationIDMessage
} from 'pages/Collaborations/CollaborationCard/constants';
import { CollaborationName, CollaborationStatus, Subtitle } from 'pages/Collaborations/CollaborationCard/styles';
import React from 'react';
import { useHistory } from 'react-router';
import { getFormattedDate } from 'utils/formateDate';
import { RewardBlock } from './RewardBlock';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'components/ui/Button';
import { ApproveStatusCollaboration } from 'components/common/ApproveStatusCollaboration';

export const CollaborationCard = ({
    id,
    organizationId,
    name,
    utcStart,
    utcEnd,
    budgetAccepted,
    coverImageUrl,
    networks,
    participationLimits,
    state,
    autoApproveParticipants,
    autoApproveMedia
}: BUCM.CollaborationResponse) => {
    const history = useHistory();
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    const moreInfoHandleClick = () => history.push(`${collaborationsLink}/${id}`);
    const startDate = utcStart ? getFormattedDate(utcStart, dateAndTimeFormat) : '-';
    const endDate = utcEnd ? getFormattedDate(utcEnd, dateAndTimeFormat) : '-';

    return (
        <CardWrapper>
            <Section justifyBetween noWrap>
                <Row alignCenter noWrap marginBottom="24px">
                    <Row marginRight="15px">
                        <Avatar borderRadius="6px" src={coverImageUrl || ''} />
                    </Row>

                    <Column justifyBetween height="60px">
                        <CollaborationName>{name}</CollaborationName>

                        <MarginWrapper>
                            <CollaborationStatus status={state || 0}>
                                {collaborationStatuses[state || 0]}
                            </CollaborationStatus>
                        </MarginWrapper>
                    </Column>
                </Row>

                <ApproveStatusCollaboration
                    autoApproveMedia={autoApproveMedia}
                    autoApproveParticipants={autoApproveParticipants}
                />
            </Section>

            <GridWrapper columns={2} marginBottom="16px">
                <PropertyBlock
                    copyable
                    isIdType
                    subtitle={id}
                    successText={copyCollaborationIDMessage}
                    title="Collaboration ID"
                />
                <PropertyBlock
                    copyable
                    isIdType
                    subtitle={organizationId}
                    successText={copyOrganizationIDMessage}
                    title="Organization ID"
                />
            </GridWrapper>

            <GridWrapper columns={2} marginBottom="24px">
                <PropertyBlock subtitle={startDate} title="Start date" />
                <PropertyBlock subtitle={endDate} title="End date" />
            </GridWrapper>

            {!!networks?.length && (
                <Column marginBottom="16px" width="100%">
                    <MarginWrapper marginBottom="10px">
                        <Subtitle>Reward for sharing</Subtitle>
                    </MarginWrapper>

                    <GridWrapper columns={2}>
                        {networks?.map(({ networkId, rewardOnSubmitted, networkTitle }) => (
                            <RewardBlock
                                key={networkId}
                                networkTitle={networkTitle || ''}
                                value={rewardOnSubmitted || 0}
                            />
                        ))}
                    </GridWrapper>
                </Column>
            )}

            <GridWrapper columns={2}>
                <PropertyBlock
                    icon={<WomIcon />}
                    subtitle={budgetAccepted?.toString() || '0'}
                    subtitleColor={primaryColor}
                    title="Budget Accepted"
                />
                <PropertyBlock
                    subtitle={participationLimits?.submitted?.toString() || ''}
                    subtitleColor={primaryColor}
                    title="Participation Limits"
                />
            </GridWrapper>

            <Section alignCenter justifyCenter marginTop="30px">
                <Button wide={isMobile} onClick={moreInfoHandleClick}>
                    More Info
                </Button>
            </Section>
        </CardWrapper>
    );
};
