import { BullzLogo } from 'assets/icons';
import { Form } from 'components/form/Form';
import { Input } from 'components/form/Input';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { AuthLayout } from 'components/layouts/AuthLayout';
import { Button } from 'components/ui/Button';
import { Formik } from 'formik';
import { logInInitialProps, title } from 'pages/Auth/constants';
import React, { FC } from 'react';
import { userEffects } from 'stores/user';
import { xs } from 'constants/styles';
import { FormTitle, FormWrapper } from './styles';
import { useMediaQuery } from 'react-responsive';

export const Login: FC = () => {
    const onFinish = (values: BUCM.OrganizationUserAuthChallengeRequest) => userEffects.loadToken(values);
    const isMobile = useMediaQuery({ query: `(max-width: ${xs})` });

    return (
        <AuthLayout>
            <FormWrapper>
                <MarginWrapper marginBottom="12px">
                    <BullzLogo height="48px" width="100px" />
                </MarginWrapper>
                <FormTitle>{title}</FormTitle>
                <Formik {...logInInitialProps} onSubmit={onFinish}>
                    {({ handleSubmit, isValid, dirty }) => (
                        <Form onSubmit={handleSubmit}>
                            <Input label="Email address" name="email" placeholder="Enter your email address" />

                            <Input
                                label="Password"
                                marginBottom="40px"
                                name="password"
                                placeholder="Enter your password"
                                type="password"
                            />
                            <Button disabled={!isValid || !dirty} type="submit" wide={isMobile}>
                                Log In
                            </Button>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        </AuthLayout>
    );
};
